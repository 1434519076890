import { Directive, Input } from '@angular/core';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[limit-to]',
	// tslint:disable-next-line:use-host-property-decorator
	host: {
		'(keypress)': '_onKeypress($event)',
	}
})
export class LimitToDirective {
	// tslint:disable-next-line:no-input-rename
	@Input('limit-to') limitTo;
	_onKeypress(e) {
		const limit = +this.limitTo;
		if (e.target.value.length === limit) { e.preventDefault(); }
	}

}
