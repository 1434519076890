<div *ngIf="!isNewFormOpen">
	<div *ngIf="successMessage || errorMessage">
		<ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">
			<span [innerHTML]="successMessage" [(megaAutoDismissAlerts)]="successMessage"></span>
		</ngb-alert>
		<ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">
			<span [innerHTML]="errorMessage" [(megaAutoDismissAlerts)]="errorMessage"></span>
		</ngb-alert>
	</div>

	<div>
		<div class="list-group list-group-flush">
			<div class="list-group-item px-0" *ngFor="let card of creditCardList">
				<div class="row">
					<div class="col-1 pr-0">
						<div class="custom-control custom-radio mt-3">
							<input type="radio" id="radio-{{card.LAST_FOUR_DIGITS}}" name="ccradio" class="custom-control-input" [value]="card.LAST_FOUR_DIGITS" [(ngModel)]="selectedCard" (click)="SetSelectedCard(card.LAST_FOUR_DIGITS)">
							<label class="custom-control-label" for="radio-{{card.LAST_FOUR_DIGITS}}"></label>
						</div>
					</div>
					<div class="col-8 text-truncate">
						<h5 class="mb-1">{{ card.FIRST_NAME + ' ' + card.LAST_NAME }}</h5>
						<small class="text-spacing">{{ card.TITLE }} </small>						
						<a href="mailto:a@a.com" *ngIf="card.VERIF_STATUS!='3'" class="text-primary mg-contact-with-icon">
							- <i class="fa fa-check-square-o"></i>  Verificar
						</a>
					</div>
					<div class="col-3 text-right">						
						<div class="mt-2 text-right d-inline-block">
							<button type="button" class="btn btn-outline-primary btn-sm" (click)="EditCard(editModal, card.LAST_FOUR_DIGITS)">
								<i class="fa fa-pencil"></i>
							</button>
						</div>						
						<div class="mt-2 text-right d-inline-block">
							<button type="button" class="btn btn-outline-danger btn-sm" (click)="DeleteCard(deleteModal, card.LAST_FOUR_DIGITS)">
								<i class="fa fa-trash"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="list-group-item px-0">
				<div class="row">
					<div class="col-1 pr-0">
						<div class="custom-control custom-radio mt-3">
							<input type="radio" id="newccradio" name="ccradio" class="custom-control-input" value="new" (click)="SetSelectedCard('new')" [(ngModel)]="selectedCard">
							<label class="custom-control-label" for="newccradio"></label>
						</div>
					</div>
					<div class="col-11">
						<h5 class="mt-3">Agregar Nueva Tarjeta</h5>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row  mt-4" *ngIf="source === 'topups'">
		<div class="col-12">
			<div class="custom-control custom-checkbox">
				<input type="checkbox" class="custom-control-input" id="termCc" [(ngModel)]="termConditions">
				<label class="custom-control-label" for="termCc">
					Acepto Los T&eacute;rminos y Condiciones y entiendo que una vez recargados estos num&eacute;ros
					<b>no se aceptan devoluciones</b>.
				</label>
			</div>
		</div>
	</div>

	<div class="row mt-4" *ngIf="source !== 'checkout' || selectedCard == 'new'">
		<div class="col-12">
			<button type="button" class="btn btn-primary text-white btn-block" (click)="Pay($event)">
				{{ selectedCard == 'new' ? 'Siguiente' : 'Pagar' }}
			</button>
		</div>
	</div>

	<ng-template #deleteModal let-c="close" let-d="dismiss">
		<div class="modal-header bg-primary text-white">
			<h4 class="modal-title">Confirmaci&oacute;n</h4>
		</div>
		<div class="modal-body">
			<p>¿Desea eliminar la tarjeta de cr&eacute;dito {{ deleteCard }} ?</p>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-outline-dark px-5" (click)="c('cancel')">No</button>
			<button type="button" class="btn btn-primary text-white px-5" (click)="c('delete')">S&iacute;</button>
		</div>
	</ng-template>

	<ng-template #editModal let-c="close" let-d="dismiss">
		<div class="modal-header bg-primary text-white">
			<h4 class="modal-title">Edit Card {{editCard}}</h4>
			<button type="button" class="close text-white" aria-label="Close" (click)="onCancelEditCreditCard($event)">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<mega-create-credit-card [openModal]="openEditModal" [isEdit]="true" [editLast4]="editCard" (onCancel)="onCancelEditCreditCard($event)"></mega-create-credit-card>
		</div>
	</ng-template>
</div>

<mega-create-credit-card [source]="source" [openModal]="openCreateModal" (onSave)="SaveNewCreditCard($event)" (onCancel)="onCancelCreateCreditCard($event)"></mega-create-credit-card>
