import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthUserService } from './auth-user.service';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../models/user.model';
import { ResultModel } from '../../models/result.model';
import { CreditCardResponse } from '../../models/responses/credit-card.response';
import { CreditCardModel } from '../../models/credit-card.model';
import { DeleteCreditCardResponse } from '../../models/responses/delete-credit-card.response';
import { UpdateCreditCardModel } from '../../models/update-credit-card.model';

@Injectable()
export class CreditCardService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private authUserService: AuthUserService
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetCreditCards(): Promise<ResultModel<CreditCardResponse>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);

				return this.httpClient
					.get<ResultModel<CreditCardResponse>>(`${this.baseUrl}api/account/get_billing_details`, { params: params })
					.toPromise();
			});
	}

	CheckIfCreditCardExits(number: string): Promise<ResultModel<boolean>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('number', number);

				return this.httpClient
					.get<ResultModel<boolean>>(`${this.baseUrl}api/billing/check_if_exist`, { params: params })
					.toPromise();
			});
	}

	GetCreditCardInfo(number: string): Promise<ResultModel<CreditCardModel>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('last4', number);

				return this.httpClient
					.get<ResultModel<CreditCardModel>>(`${this.baseUrl}api/billing/get_cc`, { params: params })
					.toPromise();
			});
	}

	DeleteCreditCard(number: string): Promise<ResultModel<DeleteCreditCardResponse>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('profile', number);

				return this.httpClient
					.get<ResultModel<DeleteCreditCardResponse>>(`${this.baseUrl}api/billing/delete_cc`, { params: params })
					.toPromise();
			});
	}

	UpdateCreditCardDetails(card: UpdateCreditCardModel): Promise<ResultModel<any>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		const params = new URLSearchParams();

		return Promise.resolve()
			.then(e => {
				params.set('SERNO', user.serno);
				Object.keys(card).forEach(key => {
					params.set(key, card[key].toString());
				});

				return this.httpClient
					.post<ResultModel<any>>(`${this.baseUrl}api/billing/edit_cc_info`, params)
					.toPromise();
			});
	}
}
