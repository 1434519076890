import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
	selector: 'mega-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
	carouselOne: NguCarouselConfig;
	data: string[] = [
		'assets/images/homepage/carriers/claro.png',
		'assets/images/homepage/carriers/lime.png',
		'assets/images/homepage/carriers/nauta.png',
		'assets/images/homepage/carriers/digicel.png',
		'assets/images/homepage/carriers/movistar.png',
		'assets/images/homepage/carriers/iusacell.png',
		'assets/images/homepage/carriers/telcel.png'
	];

	constructor() { }

	ngOnInit() {
		this.carouselOne = {
			grid: { xs: 1, sm: 2, md: 4, lg: 6, all: 0 },
			slide: 1,
			speed: 400,
			interval: { timing: 7000, initialDelay: 0 },
			point: {
				visible: true
			},
			load: 2,
			touch: true,
			loop: true,
			custom: 'banner'
		};
	}

}
