import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { forEach } from 'lodash';
import { DynamicContentService } from './dynamic-content.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ContentModel } from '../../models/admin/content.model';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'mega-dynamic-content',
	templateUrl: './dynamic-content.component.html',
	styleUrls: ['./dynamic-content.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DynamicContentComponent implements OnInit {
	url: string = '';
	content: ContentModel = new ContentModel();

	constructor(
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
		private activatedRoute: ActivatedRoute,
		private dynamicContentService: DynamicContentService,
		private router: Router,
		private titleService: Title,
		private meta: Meta
	) {
		this.content.content = '';
	}

	ngOnInit() {
		this.activatedRoute.url.subscribe((segments: UrlSegment[]) => {
			forEach(segments, item => {
				this.url += '/' + item.path;
			});

			this.GetContentByUrl();
		});
	}
	
	GetContentByUrl(): void {
		//this.ng4LoadingSpinnerService.show();
		if ((this.url == '/referidos')||(this.url == '/recargas')||(this.url == '/remesas')){			
			const ua = navigator.userAgent				
			if (/android/i.test(ua)) {
				window.location.href = "https://play.google.com/store/apps/details?id=com.embarcadero.MegaConecta&hl=es";	
			}
			else if (/iPad|iPhone|iPod/.test(ua)){
				window.location.href = "https://itunes.apple.com/us/app/megaconecta/id1000791688?ls=1&mt=8";		
			}else{
				window.location.href = "https://www.megaconecta.com/account/register";						
			}
			
		}
		this.dynamicContentService
			.GetContentByUrl(this.url)
			.then(response => {
				if (response.has_error || !response.data) {
					throw new Error();
				}
				this.content = response.data;

				
				let titlePage = this.content.title + ' | Megaconecta';
				if (titlePage == ' | Megaconecta'){
					titlePage='MegaConecta: Recargas y LLamadas A Cuba';
				}
				this.titleService.setTitle(titlePage);

				this.meta.removeTag(`property='og:title'`);
				this.meta.removeTag(`name='twitter:title'`);
				this.meta.removeTag(`itemprop='name'`);

				this.meta.addTag({ property: 'og:title', content: titlePage });
				this.meta.addTag({ name: 'twitter:title', content: titlePage });
				this.meta.addTag({ itemprop: 'name', content: titlePage });

				if (!!this.content.meta_description) {
					this.meta.removeTag(`name='description'`);
					this.meta.removeTag(`property='og:description'`);
					this.meta.removeTag(`name='twitter:description'`);
					this.meta.removeTag(`itemprop='description'`);

					this.meta.addTag({ name: 'description', content: this.content.meta_description });
					this.meta.addTag({ property: 'og:description', content: this.content.meta_description });
					this.meta.addTag({ name: 'twitter:description', content: this.content.meta_description });
					this.meta.addTag({ itemprop: 'description', content: this.content.meta_description });
				}

				if (!!this.content.meta_keyword) {
					this.meta.removeTag(`name='keywords'`);
					this.meta.addTag({ name: 'keywords', content: this.content.meta_keyword });
				}

				//this.ng4LoadingSpinnerService.hide();
			})
			.catch(error => {			
				//this.router.navigate(['/404']);
				window.location.href = "https://www.megaconecta.com/404";				
				//document.location.href = '/404/index.php';
			});
	}

}
