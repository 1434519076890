import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
	datePipe = new DatePipe('en-US');
	dateFormatString: string = 'MM/dd/yyyy';

	constructor() {
		super();
	}

	format(date: NgbDateStruct): string {
		if (date === null) { return ''; }

		try {
			return this.datePipe
				.transform(new Date(date.year, date.month - 1, date.day), this.dateFormatString);
		} catch (e) {
			return '';
		}
	}

	parse(value: string): NgbDateStruct {
		let returnVal: NgbDateStruct;

		if (!value) {
			returnVal = null;
		} else {
			try {
				const dateParts = this.datePipe.transform(value, this.dateFormatString).split('/');

				returnVal = { year: Number(dateParts[2]), month: Number(dateParts[0]), day: Number(dateParts[1]) };
			} catch (e) {
				returnVal = null;
			}
		}

		return returnVal;
	}
}
