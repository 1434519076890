import { Component, OnInit, Input } from '@angular/core';
import { AuthUserService } from '../../services/auth-user.service';
import { UserModel } from '../../../models/user.model';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
	selector: 'mega-page-title',
	templateUrl: './page-title.component.html',
	styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
	@Input() title;

	user: UserModel;

	constructor(
		private authUserService: AuthUserService,
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService
	) { }

	ngOnInit() {
		this.user = this.authUserService.GetAuthUser();
	}

	LogOut(): void {
		this.ng4LoadingSpinnerService.show();
		this.authUserService.LogOut(true);
		this.ng4LoadingSpinnerService.hide();
	}

}
