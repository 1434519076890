import { Directive, ElementRef, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
	// tslint:disable-next-line:directive-selector	
	selector: '[mask-cursor]',
	providers: [NgModel],
	// tslint:disable-next-line:use-host-property-decorator
	host: {
		'(keyup)': 'onInputChange($event)',
		'(click)': 'onClick($event)',
		'(paste)': 'onPaste($event)'
	}
})
export class MaskCursorDirective {	

	aValue = '';
	
	countryMask = '';
	@Input('countryMask') set _countryMask(value: any) {
		if (this.countryMask !== value) {
			this.oldValue = {};
		}

		this.countryMask = value;
	}
	oldValue = {};

	onPaste($event: ClipboardEvent) {
		
		//alert($event.clipboardData.getData('text'));				
		this.aValue = $event.clipboardData.getData('text');
		this.aValue = '+'+this.aValue;
		$event.preventDefault();	
		$event.stopPropagation();
		this.onInputChange($event);
		//$event.preventDefault();
	}

	onClick($event): void {		
		
		let value = $event.target.value;
		value = value || '';
		

		let selectionStart = value.indexOf('X');
		let selectionEnd = value.indexOf('X');

		selectionStart = (selectionStart === -1) ? value.length : selectionStart;
		selectionEnd = (selectionEnd === -1) ? value.length : selectionEnd;

		if ($event.target.setSelectionRange) {
			$event.target.setSelectionRange(selectionStart, selectionEnd);
		} else if ($event.target.createTextRange) {
			const range = $event.target.createTextRange();
			range.collapse(true);
			range.moveEnd('character', selectionEnd);
			range.moveStart('character', selectionStart);
			range.select();
		}
	}

	onInputChange($event): void {		
		
		console.log('onchange');
		
	
		if (this.aValue!=''){
			//this.oldValue[$event.target.name] = '';
			//$event.target.value = this.aValue;
			//this.onClick($event);
		}	
		

		//$event.target.value = this.aValue;
		//return;

		if (this.countryMask) {
			this.countryMask = this.countryMask.replace(/[^\d]/g, '');			
		} else {			
			return;
		}
		const char = $event.key;
		const oldVal = this.oldValue[$event.target.name];
		let currentValue = $event.target.value;	

		if (!char || !/[0-9]/gi.test(char)) {
			this.oldValue[$event.target.name] = $event.target.value;			
			return;
		}
	
		// char typed at the end first time
		const value: string = currentValue.replace(this.countryMask, '');

		if (!oldVal && value.indexOf(char) === -1) {
			currentValue = currentValue.replace('X', char);
			$event.target.value = currentValue;
			this.onClick($event);
			this.oldValue[$event.target.name] = $event.target.value;			
			return;
		}

		if (/X[0-9]/gi.test(currentValue)) {
			currentValue = currentValue.replace(/X[0-9]/gi, 'XX');
			currentValue = currentValue.replace('X', char);
			$event.target.value = currentValue;
			this.onClick($event);
			this.oldValue[$event.target.name] = $event.target.value;			
			return;
		}

		if (value.length > 0 && value[0] === char) {
			this.onClick($event);
			console.log('clicking');
		}

		this.oldValue[$event.target.name] = $event.target.value;

		console.log('End');
	}

	public ResetMask(): void {
		this.oldValue = {};
	}
}
