export const environment = {
	production: true,
	clientId: 'megaconecta_website',
	sourceApp: 'WEB_MEGA',
	clientSecret: 'FQSAhG7OENmEH18gK5I6//sDnw1/6KTP2aOmMfEPHrg=',
	appTitle: 'Megaconecta',
	apiBaseUrl1: 'http://localhost/megaconecta-web-api/',
	apiBaseUrl: 'https://apiweb.megaconecta.com/',
	googleCaptchaKey: '6LcZ-skUAAAAAL3pa5NG-LJYj94gZ7c47-_SUc3F'
};
