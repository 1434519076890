<mega-page-title title="Tarifas"></mega-page-title>

<div class="container mg-top-title mg-rate-page">
	<div class="row">
		<div class="col">
			<p>Seleccione el servicio de su inter&eacute;s para conocer nuestras tarifas.</p>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<ngb-tabset>
				<ngb-tab>
					<ng-template ngbTabTitle>
						Llamadas
					</ng-template>
					<ng-template ngbTabContent>
						<div class="row my-4">
							<div class="col-lg-4 col-md-2 d-none d-sm-none d-md-flex"></div>
							<div class="col-lg-4 col-md-8 col-sm-12">
								<div class="input-group">
									<input type="text"
										class="form-control"
										[(ngModel)]="searchValue"
										placeholder="Buscar por pa&iacute;ses..."
										(keyup.enter)="searchAction()">
									<div class="input-group-append" (click)="searchAction()">
										<div class="input-group-text right" id="btnGroupAddon2">
											<span class="fa fa-search"></span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-2 d-none d-sm-none d-md-flex"></div>
						</div>
						<div class="row my-4" *ngIf="llamadasOptions.search && llamadaList && llamadaList.length > 0">
							<div class="col">
								<span class="mg-result-search">Mostrando resultados para "{{llamadasOptions.search}}"</span>
							</div>
						</div>
						<div class="row my-4" *ngIf="llamadasOptions.search && llamadaList && llamadaList.length == 0">
							<div class="col">
								<span class="mg-result-search">
									No se encontr&oacute; ning&uacute;n resultado para "{{llamadasOptions.search}}"
								</span>
							</div>
						</div>
						<table class="table mg-table" *ngIf="llamadaList && llamadaList.length > 0">
							<thead>
								<tr>
									<th>pa&iacute;s</th>
									<th class="text-center d-none d-sm-none d-md-table-cell">c&oacute;digo</th>
									<th class="text-center d-none d-sm-none d-md-table-cell">mins ($5)</th>
									<th class="text-center d-none d-sm-none d-md-table-cell">mins ($10)</th>
									<th class="text-center">tarifas</th>
									<th class="text-center d-table-cell d-sm-table-cell d-md-none"></th>
								</tr>
							</thead>
							<tbody *ngFor="let item of llamadaList">
								<tr>
									<td>{{item.PATTERNNAME}}</td>
									<td class="text-center d-none d-sm-none d-md-table-cell">{{item.PATTERN}}</td>
									<td class="text-center d-none d-sm-none d-md-table-cell">{{item.FIRST15MIN}}</td>
									<td class="text-center d-none d-sm-none d-md-table-cell">{{item.FIRST110MIN}}</td>
									<td class="text-center">{{item.FIRST1 | currency}}</td>
									<td class="text-center d-table-cell d-sm-table-cell d-md-none mg-info-action">
										<i class="fa" (click)="openInfoSection(item)" [ngClass]="{'fa-plus-circle': !isOpenInfoSection(item), 'fa-minus-circle': isOpenInfoSection(item)}"></i>
									</td>
								</tr>
								<tr class="text-center d-table-row d-sm-table-row d-md-none" id="{{item.PATTERNNAME+item.PATTERN}}" *ngIf="isOpenInfoSection(item)">
									<td colspan="3">
										<div class="row mg-hidden-section">
											<div class="col">
												<h6>c&oacute;digo</h6>
												<span>{{item.PATTERN}}</span>
											</div>
											<div class="col">
												<h6>mins ($5)</h6>
												<span>{{item.FIRST15MIN}}</span>
											</div>
											<div class="col">
												<h6>mins ($10)</h6>
												<span>{{item.FIRST110MIN}}</span>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<mega-pager *ngIf="llamadaList && llamadaList.length > 0"
							[currentPageSize]="llamadasOptions.take"
							[currentPage]="llamadasOptions.currentPage"
							[totalRecords]="totalRecord"
							(pageChangeEvent)="onPageChangeLlamadas($event)">
						</mega-pager>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle>
						Recargas
					</ng-template>
					<ng-template ngbTabContent>
						<div class="row">
							<div class="col-md-6 col-sm-12">
								<form [formGroup]="topupForm">
									<label class="dark">Pais</label>
									<ng-select  formControlName="countryRecargasSelected" [clearable]="false" placeholder="Seleccione..." (change)="GetCarriersRecargas($event)">
										<ng-option *ngFor="let item of countryRecargaList" [value]="item.country" >
											<i class="flag" [ngClass]="item.country | lowercase"></i>{{item.country}}
										</ng-option>
									</ng-select>							

									<label class="dark mt-2">operador</label>
									<ng-select  formControlName="carrierSelected" [clearable]="false" placeholder="Seleccione..." (change)="GetAmountRecargas($event)">
										<ng-option *ngFor="let item of carrierList" [value]="item.UID" >
											<i class="fa fa-podcast" aria-hidden="true"></i>&nbsp;&nbsp;{{item.NAME}}
										</ng-option>
									</ng-select>	
									
									<label class="dark mt-2">cantidad a enviar</label>
									<ng-select #amountSelect formControlName="amountSelected" [clearable]="false" placeholder="Seleccione..." (change)="changeAmountRecargas($event)">
										<ng-option *ngFor="let item of amountList" [value]="item.realamount" >
											<i class="fa fa-usd" aria-hidden="true"></i>&nbsp;&nbsp;{{item.remoteformatedamount}}
										</ng-option>
									</ng-select>	
								</form>
							
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="mg-costo-box">
									<h3>costo</h3>
									<h1>{{ getRecargaFinalPrice() | currency}}</h1>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle>
						Mensaje de Texto
					</ng-template>
					<ng-template ngbTabContent>
						<div class="row">
							
							<div class="col-md-6 col-sm-12">
								<form [formGroup]="smsForm">
									<label>Pais</label>
									<ng-select  formControlName="smsPriceSelected" [clearable]="false" placeholder="Seleccione..." (change)="onSmsCountryChange($event)">
										<ng-option *ngFor="let item of smsPriceList" [value]="item.smscountcod" >
											<i class="flag" [ngClass]="item.smscountcod | lowercase"></i>{{item.smscountdesc}}
										</ng-option>
									</ng-select>	
								</form>		
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="mg-costo-box">
									<h3>costo</h3>
									<h1>{{smsPriceModelSelected.smsprice | currency}}</h1>
								</div>
							</div>
												
						</div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</div>
	</div>
</div>



<mega-social-media></mega-social-media>
