import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

import * as Payment from 'payment';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[ccNum]'
})
export class CCNumberFormatDirective {

	cardType: string;

	constructor(private renderer: Renderer2, private el: ElementRef) {

		const element = this.el.nativeElement;
		this.cardType = '';

		// call lib functions
		Payment.fns.formatCardNumber(element);
		Payment.restrictNumeric(element);
	}

	@HostListener('keypress', ['$event']) onKeypress(e) {

		const element = this.el.nativeElement;
		const elementValue = element.value;

		this.cardType = Payment.fns.cardType(elementValue);

		if (this.cardType !== '') {
			this.renderer.removeClass(element, this.cardType);
		} else {
			this.cardType = '';
		}

	}

}
