<!--The content below is only a placeholder and can be replaced.-->
<sui-sidebar-container>
    <sui-sidebar-sibling>
        <mega-header [sidebar]="sidebar"></mega-header>

        <router-outlet></router-outlet>

        <mega-footer></mega-footer>
    </sui-sidebar-sibling>

    <sui-sidebar [(isVisible)]="isVisibleSideBar" class="inverted vertical" direction="right" transition="push" #sidebar>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
                <div class="bg-primary py-2 text-center mg-lang-mobile mb-5">
                    <a class="text-white">ES /</a>
                    <a class="text-secondary">EN</a>
                </div>
            </div>
            <div class="col-3"></div>
        </div>

        <div class="row">
            <div class="col-3"></div>
            <div class="col-9">
                <div class="mg-mobile-title">MegaConecta</div>
                <small *ngIf="isAuthenticated()">
					Hola, {{getUserName()}}
					<a class="text-primary" (click)="logOut($event)">(Salir)</a>
				</small>
                <div class="mg-mobile-login" *ngIf="!isAuthenticated()">
                    <a routerLink="/account/login">Entrar /</a>
                    <a routerLink="/account/register">Registrar</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-3"></div>
            <div class="col-9 mg-mobile-link-section text-truncate">
                <a [routerLink]="item.link" routerLinkActive="active" *ngFor="let item of links" [innerHTML]="item.name" [routerLinkActiveOptions]="{ exact: true }"></a>
                <div *ngIf="isAuthenticated()">
                    <a [routerLink]="item.link" routerLinkActive="active" *ngFor="let item of linksAccount" [innerHTML]="item.name" [routerLinkActiveOptions]="{ exact: true }"></a>
                </div>
            </div>
        </div>

        <div class="fixed-bottom">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-9 mg-mobile-social">
                    <h4>
                        Siguenos en
                        <div class="mg-line-bottom"></div>
                    </h4>
                    <div>
                        <a href="https://www.facebook.com/MegaConecta/" target="_blank">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a href="https://twitter.com/MegaConecta" target="_blank">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UC0DeBP9gdjh3YFAFndk9m_Q" target="_blank">
                            <i class="fa fa-youtube" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/16064162/" target="_blank">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </sui-sidebar>
</sui-sidebar-container>

<ng4-loading-spinner [template]="template" [timeout]="15000"> </ng4-loading-spinner>