<div class="container">
    <div class="row align-items-start d-none d-sm-none d-md-flex">
        <div class="col-lg-4 col-md-5 col-sm-6 ml-auto">
            <div class="row">
                <div class="col-auto bg-primary py-2">
                    <div class="mg-lang">
                        <a href class="text-white">ES /</a>
                        <a href class="text-secondary">EN</a>
                    </div>
                </div>
                <div class="col bg-secondary py-2 text-truncate">
                    <div class="mg-lang" *ngIf="!isAuthenticated()">
                        <a routerLink="account/login" class="text-white">ENTRAR /</a>
                        <a routerLink="account/register" class="text-primary">REGISTRAR</a>
                    </div>
                    <div class="mg-lang text-truncate w-100" *ngIf="isAuthenticated()">
                        <a class="text-white mr-2" routerLink="/account">Hola, {{getUserName()}}</a>
                        <a href class="text-primary" routerLink="/account">Mi Cuenta</a>
                        <span class="text-white">&nbsp;/&nbsp;</span>
                        <a href class="text-primary" (click)="logOut($event)">Salir</a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Menu for tablet and desktop -->
    <div class="row justify-content-center align-items-center d-none d-sm-none d-md-flex">
        <div class="col col-md-2 col-lg">
            <a routerLink="/">
                <img width="100%" height="100%" src="assets/images/mainlogo.webp" class="img-responsive mg-main-logo">
            </a>
        </div>
        <div class="col-lg-auto col-md-10">
            <ul class="mg-main-nav list-unstyled text-right">
                <li *ngFor="let link of links">
                    <a [routerLink]="link.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
						{{ link.name }}
						<div class="ac-line-primary"></div>
					</a>
                </li>
                <li *ngIf="true">                   
                    <div placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                        <img src="assets/images/cart.webp" class="img-responsive" >
                        <span class="badge badge-primary mg-shopping-cart" >{{shoppingCartService.shoppingCart.numItemsCart}}</span>
                    </div>                    

                    
                    <ng-template #popTitle><img src="assets/images/cart.webp" class="img-responsive mr-3" > <span class="text-primary">Tus Productos</span></ng-template>
                    <ng-template #popContent>
                        <table class="table mg-table" >
                            <thead>
                                <th>
                                    PRODUCTO
                                </th>
                                <th>
                                    CANTIDAD
                                </th>
                                <th>
                                    PAGAS
                                </th>                                
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of  shoppingCartService.shoppingCart.productsInCart; let i = index">
                                    <td>
                                        {{product.productName}}
                                    </td>
                                    <td>
                                        {{product.quantity}}
                                    </td>
                                    <td>
                                        {{(product.quantity * product.price) | currency}}
                                    </td>
                                </tr>
                            </tbody>
                            
                            <tfoot>
                                <th>
                                    TOTAL
                                </th>
                                <th>
                                    
                                </th>
                                <th>
                                    {{shoppingCartService.shoppingCart.totalBeforeShipping | currency}}
                                </th>
                            </tfoot>
                        </table>
                        <button class="btn btn-outline-primary btn-block" routerLink="/store">
                            SEGUIR COMPRANDO
                        </button>
                        <button class="btn btn-primary btn-block text-white" routerLink="/store/shopping-cart">
                            IR AL CARRO dE COMPRAS                
                        </button>
                    </ng-template>

                   

                </li>
            </ul>
        </div>
        <div class="col col-lg d-md-none d-lg-block"></div>
    </div>

    <div class="row justify-content-center align-items-center d-flex d-md-none mt-2">
        <div class="col text-left">
            <img src="assets/images/mainlogo.webp" class="img-responsive mg-main-logo">
        </div>
        
        
            <div class="col-8 mr-2" *ngIf="shoppingCartService.shoppingCart.numItemsCart>0" placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                <div >                   
                    <span class="badge badge-primary mg-shopping-cart" style="float: right">{{shoppingCartService.shoppingCart.numItemsCart}}</span>
                    <img src="assets/images/cart.webp" class="img-responsive" style="float: right">
                </div>                    
    
                
                <ng-template #popTitle><img src="assets/images/cart.webp" class="img-responsive mr-3" > <span class="text-primary">Tus Productos</span></ng-template>
                <ng-template #popContent>
                    <table class="table mg-table" >
                        <thead>
                            <th>
                                PRODUCTO
                            </th>
                            <th>
                                CANTIDAD
                            </th>
                            <th>
                                PAGAS
                            </th>                                
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of  shoppingCartService.shoppingCart.productsInCart; let i = index">
                                <td>
                                    {{product.productName}}
                                </td>
                                <td>
                                    {{product.quantity}}
                                </td>
                                <td>
                                    {{(product.quantity * product.price) | currency}}
                                </td>
                            </tr>
                        </tbody>
                        
                        <tfoot>
                            <th>
                                TOTAL
                            </th>
                            <th>
                                
                            </th>
                            <th>
                                {{shoppingCartService.shoppingCart.totalBeforeShipping | currency}}
                            </th>
                        </tfoot>
                    </table>
                    <button class="btn btn-outline-primary btn-block" routerLink="/store">
                        SEGUIR COMPRANDO
                    </button>
                    <button class="btn btn-primary btn-block text-white" routerLink="/store/shopping-cart">
                        IR AL CARRO dE COMPRAS                
                    </button>
                </ng-template>
                
            </div>
            <div style="float: right;" class="mr-2">
                <a href (click)="$event.preventDefault(); sidebar.toggle();">
                    <span class="oi oi-menu text-primary mg-mobile-menu"></span>
                </a>
            </div>
        
    </div>
</div>
