import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-term-conditions',
	templateUrl: './term-conditions.component.html',
	styleUrls: ['./term-conditions.component.scss']
})
export class TermConditionsComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
