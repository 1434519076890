import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

	transform(tel: string, args?: any): any {
		if (!tel) { return ''; }

		const value = tel.toString().trim().replace(/^\+/, '');

		if (value.match(/[^0-9]/)) {
			return tel;
		}

		let country: string, city: string, number: string;

		switch (value.length) {
			case 10: // +1PPP####### -> C (PPP) ###-####
				if (args === 'CU') {
					country = value.slice(0, 2);
					city = value.slice(2, 4);
					number = value.slice(4);
					number = number.slice(0, 2) + '.' + number.slice(2);
				} else {
					country = '';
					city = value.slice(0, 3);
					number = value.slice(3);
					number = number.slice(0, 3) + '.' + number.slice(3);
				}

				break;

			case 11: // +CPPP####### -> CCC (PP) ###-####
				const is_us = value[0] === '1';

				country = is_us ? value[0] : value.slice(0, 2);
				city = is_us ? value.slice(1, 4) : value.slice(2, 5);
				number = is_us ? value.slice(4) : value.slice(5);

				number = is_us ? number.slice(0, 3) + '.' + number.slice(3) : number.slice(0, 2) + '.' + number.slice(2);
				break;

			case 12: // +CCCPP####### -> CCC (PP) ###-####
				country = value.slice(0, 2);
				city = value.slice(2, 5);
				number = value.slice(5);

				number = number.slice(0, 3) + '.' + number.slice(3);
				break;

			default:
				return tel;
		}

		country = country ? '+' + country : '';
		return (country + ' (' + city + ') ' + number).trim();
	}

}
