<mega-page-title title="Servicios"></mega-page-title>

<div class="container">
    <div class="row mg-services-spacing">
        <div class="col-sm-12 col-md mg-service-box">
            <h5>
                <span class="d-block d-sm-block d-md-none d-lg-block">Llamadas a bajo costo</span>
                <span class="d-none d-sm-none d-md-block d-lg-none">Llamadas a
					<br>bajo costo</span>
                <div class="mg-line-title"></div>
            </h5>
            <p>
                Llame a decenas de pa&iacute;ses al mas bajo costo, sin utilizar tarjetas ni pines.
            </p>
        </div>
        <div class="col-sm-12 col-md mg-service-box">
            <h5>
                <span class="d-block d-sm-block d-md-none d-lg-block">Recargas Internacionales</span>
                <span class="d-none d-sm-none d-md-block d-lg-none">Recargas
					<br>Internacionales</span>
                <div class="mg-line-title"></div>
            </h5>
            <p>
                Recargue m&oacute;viles de familiares y amigos en todo el mundo de forma instant&aacute;nea.
            </p>
        </div>
        <div class="col-sm-12 col-md mg-service-box">
            <h5>
                <span class="d-block d-sm-block d-md-none d-lg-block">Env&iacute;o de SMS</span>
                <span class="d-none d-sm-none d-md-block d-lg-none">Env&iacute;o
					<br>de SMS</span>
                <div class="mg-line-title"></div>
            </h5>
            <p>
                Env&iacute;e mensajes de texto pagando mucho menos que lo que paga con su operador.
            </p>
        </div>
    </div>
</div>

<div class="mg-service-bar">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-lg-4 mg-service-icon-box" *ngFor="let item of servicesData">
                <img [src]="item.icon" class="img-responsive">
                <h6 [innerHTML]="item.title"></h6>
                <p [innerHTML]="item.description"></p>
            </div>
        </div>
    </div>
</div>

<mega-social-media></mega-social-media>