import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'CurrencyFormat',
	pure: false
})
export class CurrencyFormatPipe implements PipeTransform {
	constructor() {
	}

	transform(content) {
		const anumber = parseFloat(content);
		if (isNaN(anumber)) {
			return '';
		}

		const part1 = Math.floor(anumber);
		const part2 = Math.round((content - Math.floor(anumber)) * 100);

		if (part2 === 0) {
			return '$' + part1 + '.<sup>00</sup>';
		} else {
			return '$' + part1 + '.<sup>' + part2 + '</sup>';
		}
	}
}
