import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { ContactService } from './contact.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
	selector: 'mega-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
	myForm: FormGroup;
	successMessage: string;
	errorMessage: string;

	constructor(
		private formBuilder: FormBuilder,
		private contactService: ContactService,
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService
	) { }

	ngOnInit() {
		this.myForm = this.formBuilder.group({
			'nombre': [null, Validators.compose([Validators.required])],
			'email': [null, Validators.compose([Validators.required, Validators.email])],
			'mensaje': [null, Validators.compose([Validators.required])]
		});
	}

	submitForm(form: FormGroupDirective): void {
		const values = form.value;
		this.ng4LoadingSpinnerService.show();

		this.contactService
			.SendEmail(values.nombre, values.email, values.mensaje)
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.ng4LoadingSpinnerService.hide();
				this.errorMessage = '';
				this.successMessage = 'El mensaje ha sido enviado satisfactoriamente. Por favor, espere respuesta en las pr&oacute;ximas 24 horas.';

				this.myForm.markAsPristine();
				this.myForm.markAsUntouched();
				this.myForm.controls.nombre.setValue('');
				this.myForm.controls.email.setValue('');
				this.myForm.controls.mensaje.setValue('');
			})
			.catch(error => {
				this.ng4LoadingSpinnerService.hide();
				this.successMessage = '';
				this.errorMessage = 'Hubo un error inesperado enviando su informaci&oacute;n. Por favor, intente de nuevo.';
			});
	}

}
