<div class="text-center">
    <img src="assets/images/paypal_verified.webp" class="img-responsive" width="130px">
    <p class="my-3">
        Al presionar
        <b>Pagar con PayPal</b> usted ver&aacute; una ventana con el sitio de PayPal donde podr&aacute; realizar el pago. Una vez que el pago sea confirmado, se completará la acción deseada y podr&aacute; seguir navegando en nuestro sitio.
    </p>
    <div class="text-center mb-3" *ngIf="source === 'topups'">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="termPaypal" [(ngModel)]="termConditions">
            <label class="custom-control-label" for="termPaypal">
				Acepto Los T&eacute;rminos y Condiciones y entiendo que una vez recargados estos num&eacute;ros
				<b>no se aceptan devoluciones</b>.
			</label>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-2 col-md-1 d-md-flex d-sm-none d-none"></div>
        <div class="col-lg-8 col-md-10 col-sm-12">
            <div id="paypalButton"></div>
        </div>
        <div class="col-lg-2 col-md-1 d-md-flex d-sm-none d-none"></div>
    </div>

</div>