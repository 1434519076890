import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { InformationModule } from './information/information.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { HttpClientInterceptor } from './shared/interceptors/http-client.interceptor';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SuiModule } from 'ng2-semantic-ui';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgSelectModule } from '@ng-select/ng-select';

import { environment } from '../environments/environment';
import { ShoppingCartService } from './shared/services/shopping-cart.service';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		InformationModule,
		SharedModule,
		NgbModule,
		NgxLocalStorageModule.forRoot({
			prefix: 'mega-',
			allowNull: true
		}),
		HttpClientModule,
		SuiModule,
		NgSelectModule,
		Ng4LoadingSpinnerModule.forRoot(),
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		DeviceDetectorModule.forRoot(),	
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true },
		Title,
		ShoppingCartService
	],
	bootstrap: [AppComponent],
	
})
export class AppModule { }
