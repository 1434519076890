<mega-page-title title="Contacto"></mega-page-title>

<div class="container mg-top-title">
	<div class="row" *ngIf="successMessage || errorMessage">
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
		<div class="col-lg-8 col-md-12">
			<ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">
				<span [innerHTML]="successMessage" [(megaAutoDismissAlerts)]="successMessage"></span>
			</ngb-alert>
			<ngb-alert *ngIf="errorMessage" type="danger" (close)="errorMessage = null">
				<span [innerHTML]="errorMessage" [(megaAutoDismissAlerts)]="errorMessage"></span>
			</ngb-alert>
		</div>
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
	</div>
	<div class="row">
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
		<div class="col-lg-8 col-md-12">
			<form name="contactForm" #contactForm="ngForm" [formGroup]="myForm" (ngSubmit)="submitForm(contactForm)" novalidate>
				<div class="form-group" [ngClass]="{'has-error':!myForm.controls.nombre.valid && myForm.controls.nombre.touched}">
					<label for="nombre" class="required">nombre completo</label>
					<input type="text"
						class="form-control"
						[formControl]="myForm.controls.nombre"
						id="nombre"
						name="nombre">
					<div class="alert alert-danger mt-2" *ngIf="!myForm.controls.nombre.valid && myForm.controls.nombre.touched">
						El Nombre es requerido.
					</div>
				</div>
				<div class="form-group" [ngClass]="{'has-error':!myForm.controls.email.valid && myForm.controls.email.touched}">
					<label for="email" class="required">email</label>
					<input type="text"
						class="form-control"
						id="email"
						name="email"
						[formControl]="myForm.controls.email" >
					<div class="alert alert-danger mt-2" *ngIf="myForm.controls.email.errors && myForm.controls.email.errors.required && myForm.controls.email.touched">
						El Email es requerido.
					</div>
					<div class="alert alert-danger mt-2" *ngIf="myForm.controls.email.errors && !myForm.controls.email.errors.required && myForm.controls.email.errors.email && myForm.controls.email.touched">
						El Email tiene un formato no v&aacute;lido.
					</div>
				</div>
				<div class="form-group" [ngClass]="{'has-error':!myForm.controls.mensaje.valid && myForm.controls.mensaje.touched}">
					<label for="email" class="required">mensaje</label>
					<textarea class="form-control"
						rows="6"
						id="mensaje"
						name="mensaje"
						[formControl]="myForm.controls.mensaje" >
					</textarea>
					<div class="alert alert-danger mt-2" *ngIf="!myForm.controls.mensaje.valid && myForm.controls.mensaje.touched">
						El Mensaje es requerido.
					</div>
				</div>
				<div class="form-group">
					<button type="submit"
						[disabled]="!myForm.valid"
						class="btn btn-outline-primary btn-block text-uppercase py-2">
						Enviar Mensaje
					</button>
				</div>
			</form>
		</div>
		
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
	</div>
	<div class="row">
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
		<div class="col-lg-8 col-md-12">
			<hr class="my-4">
		</div>
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
	</div>
	<div class="row">
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
		<div class="col-lg-3 col-md-4 col-sm-12">
			<div class="mg-title-contact-box">
				<h5>
					direcci&oacute;n
				</h5>
				<div class="mg-line-title"></div>
				<address>
					MegaConnect Corp					
					<br> Miami FL
				</address>
				<div class="text-primary mg-contact-with-icon">
					<i class="fa fa-envelope-o"></i> ayuda@megaconecta.com
				</div>
				<div class="text-primary mg-contact-with-icon">
					<i class="fa fa-globe"></i> www.megaconecta.com
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-12">
			<div class="mg-title-contact-box">
				<h5>
					atenci&oacute;n al consumidor
				</h5>
				<div class="mg-line-title"></div>
				<div class="mg-contact-with-icon mt-10px mb-0">
					<i class="flag us"></i> <span class="text-primary">US /</span> <a href="tel:+13055078545">1(305).507.8545</a>
				</div>
				<div class="mg-contact-with-icon mb-0">
					<i class="flag us"></i>
					<span class="text-primary">US /</span> <a href="tel:+18552103000">1(855).210.3000</a>
				</div>
				<div class="mg-contact-with-icon mb-0">
					<i class="flag mx"></i>
					<span class="text-primary">MX /</span> <a href="tel:+525585264024">52(558).526.4024</a>
				</div>
				<div class="mg-contact-with-icon mb-0">
					<i class="flag ca"></i>
					<span class="text-primary">CA /</span> <a href="tel:+16475607510">1(647).560.7510</a>
				</div>
				<div class="mg-contact-with-icon mb-0">
					<i class="flag es"></i>
					<span class="text-primary">ES /</span> <a href="tel:+34910601594">34(910).60.1594</a>
				</div>
			</div>
		</div>
		<div class="col-lg-2 col-md-4 col-sm-12">
			<div class="mg-title-contact-box">
				<h5>
					HORARIO
				</h5>
				<div class="mg-line-title"></div>
				<div class="text-primary mt-10px">Lunes / Viernes</div>
				<div>10:00am a 10:00pm</div>
				<div class="text-primary mt-10px">S&aacute;bados</div>
				<div>10:00am a 5:00pm</div>
				<div class="text-primary mt-10px">Domingos / Dias feriados</div>
				<div>Cerrados</div>
			</div>
		</div>
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
	</div>
</div>

<mega-social-media></mega-social-media>
