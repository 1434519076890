import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-llamadas-cuba',
	templateUrl: './llamadas-cuba.component.html',
	styleUrls: ['./llamadas-cuba.component.scss']
})
export class LlamadasCubaComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
