import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthUserService } from './auth-user.service';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../models/user.model';
import { ContactModel } from '../../models/contact.model';
import { ResultModel } from '../../models/result.model';

@Injectable()
export class ContactService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private authUserService: AuthUserService
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	public GetAllUserContacts(): Promise<ResultModel<ContactModel>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);

				return this.httpClient
					.get<ResultModel<ContactModel>>(`${this.baseUrl}api/contact/get_all`, { params: params })
					.toPromise();
			});
	}

	public DeleteContact(id: string): Promise<ResultModel<any>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('id', id);

				return this.httpClient
					.get<ResultModel<any>>(`${this.baseUrl}api/contact/delete_contact`, { params: params })
					.toPromise();
			});
	}

	public CreateContact(model: any): Promise<ResultModel<any>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		const data = new URLSearchParams();

		return Promise.resolve()
			.then(e => {
				data.set('serno', user.serno);
				Object.keys(model).forEach(key => {
					data.set(key, model[key].toString());
				});

				return this.httpClient
					.post<ResultModel<any>>(`${this.baseUrl}api/contact/create`, data)
					.toPromise();
			});
	}

}
