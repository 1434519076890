export class CreditCardModel {
	ADDRESS1: string;
	ADDRESS2: string;
	CITY: string;
	COMPANY: string;
	COUNTRY: string;
	CURRENCY: string;
	CVC: string;
	DATE_ADDED: string;
	EMAILADDRESS: string;
	EXP_DATE: string;
	FAXNO: string;
	FIRST_NAME: string;
	FULLCVC: string;
	FULLCCNUMBER: string;
	FULL_CC_NUMBER: string;
	LAST_FOUR_DIGITS: string;
	LAST_IPADDRESS: string;
	LAST_NAME: string;
	PAYMENT_TYPE: string;
	PHONE: string;
	SERIAL: string;
	STATE: string;
	TITLE: string;
	ZIP_CODE: string;

	constructor() {
		this.ADDRESS1 = '';
		this.CITY = '';
		this.STATE = '';
		this.ZIP_CODE = '';
		this.COUNTRY = '';
		this.FIRST_NAME = '';
		this.LAST_NAME = '';
		this.CVC = '';
		this.EXP_DATE = '';
		this.FULL_CC_NUMBER = '';
		this.TITLE = '';
	}
}
