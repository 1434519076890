import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-access-number',
	templateUrl: './access-number.component.html',
	styleUrls: ['./access-number.component.scss']
})
export class AccessNumberComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
