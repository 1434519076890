<mega-page-title title="Pol&iacute;tica de Privacidad"></mega-page-title>

<div class="container mg-top-title">
	<div class="row">
		<div class="col">
			<p>
				La informaci&oacute;n proporcionada de car&aacute;cter personal o cualquier informaci&oacute;n que pueda comprometer la privacidad de los usuarios, ser&aacute; utilizada estrictamente
				por
				<b class="text-primary">MegaConecta</b> para efectos de que los usuarios puedan ser reconocidos como usuarios registrados del sitio en Internet de
				<b class="text-primary">MegaConecta</b>, al que los usuarios han ingresado dichos datos y puedan utilizar los servicios de ese sitio, as&iacute; como facilitar la navegaci&oacute;n en dicho sitio y promocionar
				sus servicios.
			</p>
			<p>
				<b class="text-primary">MegaConecta</b> garantiza que todos los datos consignados en su base de datos, son netamente privados y no podr&aacute;n ser divulgados por ninguna circunstancia. En consecuencia,
				no vender&aacute;, alquilar&aacute;, compartir&aacute;, ni ceder&aacute; informaci&oacute;n personal de los usuarios a terceros, a menos que disponga de su permiso o que se solicite
				por autoridad competente. No obstante lo anterior, con respecto a la informaci&oacute;n personal del usuario,
				<b class="text-primary">MegaConecta</b> podr&aacute; compartirla, o permitir el acceso a ella, a terceros que directa o indirectamente presten servicios a
				<b class="text-primary">MegaConecta</b>, cuando as&iacute; sea requerido para procesar o hacer posible la realizaci&oacute;n de transacciones llevadas a cabo por el usuario en un sitio de
				<b class="text-primary">MegaConecta</b>.
			</p>
			<p>
				<b class="text-primary">MegaConecta</b> puede facilitar el v&iacute;nculo con p&aacute;ginas de terceros para consolidar algunas transacciones del usuario y/o para ofrecer servicios adicionales. Si el
				usuario utiliza estos v&iacute;nculos, abandonar&aacute; el respectivo sitio en Internet de
				<b class="text-primary">MegaConecta</b>, por lo tanto
				<b class="text-primary">MegaConecta</b> no podr&aacute; garantizar ni hacerse responsable de la informaci&oacute;n , pol&iacute;ticas de seguridad y privacidad, y en general, de cualquier contenido o transacci&oacute;n
				que se efect&uacute;e en estas p&aacute;ginas.
			</p>

			<p>
				<b class="text-primary">MegaConecta</b> utiliza la informaci&oacute;n personal del usuario con el objeto de prestarle un mejor servicio. As&iacute; mismo, utilizan la informaci&oacute;n del usuario para
				ayudarle a realizar transacciones, para contactarlo, para mantenerlo informado de productos, servicios y ofertas, as&iacute; como para personalizar sus sitios en Internet. No obstante,
				para el env&iacute;o de correos electr&oacute;nicos de car&aacute;cter comercial o publicitario, el usuario conservar&aacute; siempre la facultad de ser borrado de las listas de
				distribuci&oacute;n de acuerdo con el procedimiento indicado en el respectivo sitio. En caso de que el usuario no desee recibir dicha informaci&oacute;n, podr&aacute; solicitarlo
				de manera expresa a la l&iacute;nea de servicio al cliente 305-507-8545.
			</p>
		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
