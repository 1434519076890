import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { CountryRegisterModel } from '../../models/country-register.model';
import { CountrySmsModel } from '../../models/country-sms.model';
import { CountryCarrierModel } from '../../models/country-carrier.model';

@Injectable()
export class CountryService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetCountryListRegister(): Promise<ResultModel<CountryRegisterModel>> {
		return this.httpClient
			.get<ResultModel<CountryRegisterModel>>(`${this.baseUrl}api/countries/list_register`)
			.toPromise();
	}

	GetCountriesSmsPrice(): Promise<ResultModel<CountrySmsModel>> {
		return this.httpClient
			.get<ResultModel<CountrySmsModel>>(`${this.baseUrl}api/countries/list_sms_rates`)
			.toPromise();
	}

	GetCountryByCarriers(): Promise<ResultModel<CountryCarrierModel>> {
		return this.httpClient
			.get<ResultModel<CountryCarrierModel>>(`${this.baseUrl}api/countries/get_all_active_carriers`)
			.toPromise();
	}

	GetAll(): Promise<ResultModel<CountryCarrierModel>> {
		return this.httpClient
			.get<ResultModel<CountryCarrierModel>>(`${this.baseUrl}api/countries/get_all`)
			.toPromise();
	}
}
