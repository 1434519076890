import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable()
export class LoginAuthGuard implements CanActivate {
	constructor(
		private localStorageService: LocalStorageService,
		private router: Router
	) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const token = JSON.parse(this.localStorageService.get('token'));
		const user = JSON.parse(this.localStorageService.get('mega_data'));

		const can = !!token && !!token.accessToken && !!user && !!user.isAuthenticated && !!user.user;

		if (!can) {
			this.router.navigate(['/account/login'], { queryParams: { security: 1 } });
			return false;
		}

		return true;
	}
}
