<div class="container mg-carousel-container">
	<ngb-carousel *ngIf="imgslides.length > 0">
		<ng-template ngbSlide *ngFor="let slide of imgslides">
			<div class="row mg-carousel-wrapper ">
				<div class="col-12 d-flex text-center">
					<img [src]="slide.image" class="ml-auto">
				</div>
			</div>
		</ng-template>
	</ngb-carousel>
</div>
