import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { RatesService } from './rates.service';
import { CountrySmsModel } from '../../models/country-sms.model';
import { find, isEmpty } from 'lodash';
import { CarrierRatesModel } from '../../models/carrier-rates.model';
import { AmountRatesModel } from '../../models/amount-rates.model';
import { SearchPagerModel } from '../../models/search-pager.model';
import { LlamadaRateModel } from '../../models/llamada-rate.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'mega-rates',
	templateUrl: './rates.component.html',
	styleUrls: ['./rates.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RatesComponent implements OnInit {

	topupForm: FormGroup;
	smsForm: FormGroup;

	smsPriceList: CountrySmsModel[];
	smsPriceSelected: string;
	smsPriceModelSelected: CountrySmsModel;

	countryRecargaList: any[];
	countryRecargasSelected: string;

	carrierList: CarrierRatesModel[];
	carrierSelected: number;

	amountList: AmountRatesModel[];
	amountSelected: number;

	isLoadingCarriers: boolean;
	isLoadingAmount: boolean;
	

	llamadasOptions: SearchPagerModel;
	totalRecord: number = 0;
	llamadaList: LlamadaRateModel[] = [];
	sectionOpenId: string;
	searchValue: string = '';

	constructor(
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
		private ratesService: RatesService
	) {
		this.llamadasOptions = new SearchPagerModel();
		this.llamadasOptions.search = '';
		this.llamadasOptions.sortDir = 'asc';
		this.llamadasOptions.sortField = '';
		this.llamadasOptions.take = 20;
		this.llamadasOptions.currentPage = 1;
	}

	ngOnInit() {

		this.topupForm = new FormGroup({
			countryRecargasSelected: new FormControl(null, [
				Validators.required
			]),
			carrierSelected: new FormControl(null, [
				Validators.required
			]),
			amountSelected: new FormControl(null, [
				Validators.required
			])		
		});

		this.smsForm = new FormGroup({
			smsPriceSelected: new FormControl(null, [
				Validators.required
			])		
		});

		this.ng4LoadingSpinnerService.show();

		Promise.all([
			this.GetInitialMensajeDeTextoTab(),
			this.GetInitialRecargasTab(),
			this.GetLlamadasRate()
		])
			.then(response => {
				this.ng4LoadingSpinnerService.hide();
			})
			.catch(error => {
				this.ng4LoadingSpinnerService.hide();
			});
	}

	GetInitialRecargasTab(): Promise<any> {
		return this.ratesService
			.GetCountriesTopup()
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.countryRecargaList = response.array;
				this.countryRecargasSelected = 'Cuba';
				this.topupForm.get('countryRecargasSelected').setValue(this.countryRecargasSelected);

				return this.GetCarriersRecargas(this.countryRecargasSelected);
			})
			.catch(error => {
				this.countryRecargaList = [];
				this.countryRecargasSelected = null;
				this.topupForm.get('countryRecargasSelected').setValue(this.countryRecargasSelected);
			});
	}

	GetInitialMensajeDeTextoTab(): Promise<any> {
		return this.ratesService
			.GetSmsPrice()
			.then(response => {
				if (!response.has_error) {
					this.smsPriceList = response.array;
					this.smsPriceSelected = 'CU';
					this.smsForm.get('smsPriceSelected').setValue(this.smsPriceSelected);
					this.onSmsCountryChange(this.smsPriceSelected);
				} else {
					throw new Error('error');
				}
			})
			.catch(error => {
				this.smsPriceSelected = null;
				this.smsForm.get('smsPriceSelected').setValue(this.smsPriceSelected);
				this.smsPriceList = [];
			});
	}

	GetCarriersRecargas(country: string): Promise<number> {
		this.countryRecargasSelected = country;

		this.isLoadingCarriers = true;
		return this.ratesService
			.GetCarriersRecargas(country)
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.carrierList = response.array;
				this.carrierSelected = (this.carrierList.length > 0) ? this.carrierList[0].UID : null;
				this.isLoadingCarriers = false;

				this.topupForm.get('carrierSelected').setValue(this.carrierSelected);				

				return this.carrierSelected;
			})
			.then(response => {
				return this.GetAmountRecargas(response);
			})
			.catch(error => {
				this.carrierList = [];
				this.carrierSelected = null;
				this.topupForm.get('carrierSelected').setValue(this.carrierSelected);
				this.isLoadingCarriers = false;
				return null;
			});
	}

	GetAmountRecargas(operador: number): Promise<any> {
		this.isLoadingAmount = true;
		if (!operador) { return; }

		return this.ratesService
			.GetAmountRecargas(this.countryRecargasSelected, operador)
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.amountList = response.array;
				this.amountSelected = (this.amountList.length > 0) ? this.amountList[0].realamount : null;
				this.isLoadingAmount = false;
				this.topupForm.get('amountSelected').setValue(this.amountSelected);
			})
			.catch(error => {
				this.amountList = [];
				this.amountSelected = null;
				this.isLoadingAmount = false;
				this.topupForm.get('amountSelected').setValue(this.amountSelected);
			});
	}

	changeAmountRecargas(amount: number) {
		this.amountSelected = amount;		
	}

	onSmsCountryChange(value: string): void {
		this.smsPriceSelected = value;
		this.smsPriceModelSelected = find(this.smsPriceList, { smscountcod: value });
	}

	getRecargaFinalPrice(): number {
		const custamount = Number(this.amountSelected);
		let servicecost = 0;

		/*if ((custamount > 0) && (custamount < 20)) {
			servicecost = (4.54 * custamount / 100);
		}
		if (custamount >= 20) {
			servicecost = (-0.05 * custamount / 100);
		}*/

		return Number(custamount + servicecost);
	}

	GetLlamadasRate(search?: string, forceSearch?: boolean, showLoading?: boolean): Promise<any> {
		if (showLoading) {
			this.ng4LoadingSpinnerService.show();
		}

		if (!isEmpty(search)) {
			this.llamadasOptions.search = search;
		}

		if (forceSearch) {
			this.llamadasOptions.search = search;
		}
		this.llamadasOptions.skip = (Number(this.llamadasOptions.currentPage) - 1) * Number(this.llamadasOptions.take);

		return this.ratesService
			.GetLlamadasRates(this.llamadasOptions)
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.totalRecord = response.total;
				this.llamadaList = response.array;

				if (showLoading) {
					this.ng4LoadingSpinnerService.hide();
				}
			})
			.catch(error => {
				this.totalRecord = 0;
				this.llamadaList = [];

				if (showLoading) {
					this.ng4LoadingSpinnerService.hide();
				}
			});
	}

	onPageChangeLlamadas(data: string): void {
		const parts = data.split('-');

		this.llamadasOptions.currentPage = Number(parts[0]);
		this.llamadasOptions.take = Number(parts[1]);

		this.GetLlamadasRate('', false, true);
	}

	openInfoSection(item: LlamadaRateModel) {
		const sectionid = item.PATTERNNAME + item.PATTERN;
		if (sectionid === this.sectionOpenId) {
			this.sectionOpenId = '';
			return;
		}

		this.sectionOpenId = sectionid;
	}

	isOpenInfoSection(item: LlamadaRateModel): boolean {
		const sectionid = item.PATTERNNAME + item.PATTERN;
		return (sectionid === this.sectionOpenId);
	}

	searchAction(): void {
		this.llamadasOptions.currentPage = 1;
		this.GetLlamadasRate(this.searchValue, true, true);
	}

}
