<mega-page-title title="Llamadas a Cuba y el mundo"></mega-page-title>

<div class="container mg-top-title">
	<div class="row">
		<div class="col-12 col-sm-12 col-md-6">
			<img class="img-responsive" width="100%" src="assets/images/banner-llamdas-cuba-seo.png">
		</div>
		<div class="col-12 col-sm-12 col-md-6">
			<p>
				<strong>Megaconecta</strong> te ofrece los mejores precios de llamadas a cada Rincon del mundo. Con oferta en llamadas todos los dias nuestros precios son imbatibles. Create una nueva cuenta
				ahora mismo y obten 10 minutos con un $1 en llamadas a Cuba.
			</p>

			<p>
				Pero los precios no es lo unico Bueno en nuestros servicios. Ahora con la opcion de crear tu lista de contactos es mucho mas facil para ti llamar a tu familia. Por cada contacto que
				usted crea, se le proporcionara un numero de acceso personalizado a su pais, de esta forma usted estara llamando a cualquier pais del mundo, incluido Cuba, a traves de un numero
				de telefono local.
			</p>

			<p>
				Pero aun no terminamos con todo lo Bueno, todos los dias de 11:00 PM a 7:00 AM EST (hora de Miami) le ofrecemos un descuentos en todas las llamadas a Cuba, ya sea a cellular o telefono
				fijo. A $0.55 el minuto, con calidad premium y sin cargos ocultos para que usted disfrute de su dinero aun mas hablando con los suyos.
			</p>

			<p>
				No espere mas! Comienze a usar nuestros servicios ahora mismo.
			</p>

			<p class="text-center">
				<button class="btn btn-primary text-white" routerLink="/account/register">
					Registrate Ahora!
				</button>
			</p>

			<p>
				Si quiere ver mas información de los precio para su país, puede hacerlo a través de nuestra pagina de Tarifas o
				<a routerLink="/rates">click</a> para verlos. También puede instalar nuestra aplicación móvil para realizar recargas desde cualquier lugar.
			</p>

			<div class="row mg-mt-cta">
				<div class="col-12 text-center">
					<h3>Descargue la aplicaci&oacute;n gratis</h3>
					<p class="mb-4">Llame, Recargue y Env&iacute;e SMS
						<b>gratis</b> a Cuba</p>
					<a href="https://itunes.apple.com/us/app/megaconecta/id1000791688?ls=1&mt=8" target="_blank">
						<img class="img-responsive mr-3" src="assets/images/homepage/app-store.webp">
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.embarcadero.MegaConecta&hl=es" target="_blank">
						<img class="img-responsive" src="assets/images/homepage/app-store.webp">
					</a>
				</div>
			</div>



		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
