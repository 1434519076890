export class NewCreditCardModel {
	cbxCCProfile: string;
	chkSaveCC: boolean;
	edtCCNumber: string;
	cbxCCMonth: string;
	cbxCCYear: string;
	edtCCCCV: string;
	edtCustFirstName: string;
	edtCustLastName: string;
	edtCustAddress: string;
	edtCustCity: string;
	edtCustState: string;
	cbxCountry: string;
	edtCustZip: string;

	constructor() {
		this.cbxCCProfile = '0';
	}
}
