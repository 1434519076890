export class ContentModel {
	id: number;
	title: string;
	url: string;
	start_date: string;
	end_date: string;
	tag: string;
	target: string;
	content: string;
	meta_description?: string;
	meta_keyword: string;
}
