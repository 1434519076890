import { Directive, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[megaAutoDismissAlerts]'
})
export class AutoDismissAlertsDirective implements OnChanges {

	_message: string;
	@Output() megaAutoDismissAlertsChange = new EventEmitter<string>();
	@Input() megaAutoDismissAlerts;
	timeoutValue;

	constructor() { }

	private setTimeOut(): void {
		if (!!this.megaAutoDismissAlerts) {
			this.timeoutValue = setTimeout(() => {
				this.megaAutoDismissAlerts = '';
				this.megaAutoDismissAlertsChange.emit(this.megaAutoDismissAlerts);
			}, 20000);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!!changes.megaAutoDismissAlerts && !!changes.megaAutoDismissAlerts.currentValue) {
			this.setTimeOut();
		} else {
			if (!!this.timeoutValue) {
				clearTimeout(this.timeoutValue);
			}
		}
	}

}
