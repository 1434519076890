
import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, Input } from '@angular/core';
import { CreditCardService } from '../../services/credit-card.service';
import { CreditCardResponse } from '../../../models/responses/credit-card.response';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { remove } from 'lodash';
import { NewCreditCardModel } from '../../../models/new-credit-card.model';
import { FundPaymentModel } from '../../../models/fund-payment.model';
import { PaypalReturnModel } from '../../../models/paypal/paypal-return.model';
import { TopupModel } from '../../../models/topup-model';
import { Subject } from 'rxjs';
import { ShoppingCartModel } from '../../../models/store/shopping-cart.model';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
	selector: 'mega-credit-cards',
	templateUrl: './credit-cards.component.html',
	styleUrls: ['./credit-cards.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CreditCardsComponent implements OnInit {
	creditCardList: CreditCardResponse[] = [];
	// selectedCard: string;
	deleteCard: string;
	editCard: string;
	editModalWindows: NgbModalRef;

	errorMessage: string;
	successMessage: string;
	openCreateModal: Subject<boolean> = new Subject<boolean>();
	openEditModal: Subject<boolean> = new Subject<boolean>();

	isNewFormOpen: boolean = false;

	@Input() selectedCard: string;
	@Input() updateCards: Subject<boolean>;
	@Input() source: string;

	@Input('topup-list') topupList: TopupModel[];
	@Input('shopping-cart') shoppingCart: ShoppingCartModel;

	@Output() requireAutoDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onPayment: EventEmitter<FundPaymentModel> = new EventEmitter<FundPaymentModel>();
	@Output() onDeleteCard: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output('onCancel') cancel: EventEmitter<PaypalReturnModel> = new EventEmitter<PaypalReturnModel>();

	termConditions: boolean = false;

	constructor(
		private creditCardService: CreditCardService,
		private modalService: NgbModal,
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
		private shoppingCartService: ShoppingCartService
	) { }

	ngOnInit() {
		this.GetCreditCards();

		this.updateCards
			.subscribe(e => {
				if (!e) { return; }
				this.GetCreditCards();
			});
	}

	GetCreditCards(): void {
		this.errorMessage = '';
		this.successMessage = '';

		this.creditCardService
			.GetCreditCards()
			.then(response => {
				if (response.has_error) {
					throw new Error();
				}				

				this.creditCardList = response.array;
				if (this.source !== 'checkout') {
					if (this.creditCardList.length > 0) {
						this.selectedCard = this.creditCardList[0].LAST_FOUR_DIGITS;
					} else {
						this.selectedCard = 'new';
					}
				}
			})
			.catch(error => {
				this.creditCardList = [];
				this.selectedCard = 'new';
			});
	}

	EditCard(editModal: any, lastFour: string): void {
		this.editCard = lastFour;

		this.editModalWindows = this.modalService
			.open(editModal, { keyboard: false, windowClass: 'fade' });
	}

	onCancelEditCreditCard(refresh: boolean): void {
		if (this.editModalWindows) {
			this.editModalWindows.dismiss();
		}

		if (refresh) {
			this.GetCreditCards();
		}
	}

	DeleteCard(content, cardToDelete: string): void {
		this.deleteCard = cardToDelete;
		this.modalService
			.open(content, { keyboard: false, windowClass: 'fade' })
			.result
			.then((result) => {
				if (result === 'delete') {
					this.ng4LoadingSpinnerService.show();
					this.creditCardService
						.DeleteCreditCard(this.deleteCard)
						.then(response => {
							if (response.has_error) {
								throw new Error();
							}

							this.ng4LoadingSpinnerService.hide();
							this.errorMessage = '';
							this.successMessage = `La Tarjeta <b>${this.deleteCard}</b> fue eliminada correctamente`;

							remove(this.creditCardList, { LAST_FOUR_DIGITS: this.deleteCard });
							if (this.deleteCard === this.selectedCard && this.creditCardList.length > 0) {
								this.selectedCard = this.creditCardList[0].LAST_FOUR_DIGITS;
							} else if (this.deleteCard === this.selectedCard) {
								this.selectedCard = 'new';
							}

							this.deleteCard = '';
							this.onDeleteCard.emit(true);
							if (response.data.requireDeleteAuto) {
								this.requireAutoDelete.emit(true);
							}
						})
						.catch(error => {
							this.ng4LoadingSpinnerService.hide();
							this.successMessage = '';
							this.errorMessage = 'Hubo un error eliminando la tarjeta. Por favor intente de nuevo.';
						});
				}
			})
			.catch(reason => {
				// do nothing & close the modal
			});
	}

	Pay(event?: any): void {		
		if (!!event) {
			event.preventDefault();
		}

		if (this.source === 'topups' && !this.termConditions) {
			const err = new PaypalReturnModel();
			err.success = false;
			err.errorMessage = 'Tiene que aceptar nuestros T&eacute;rminos y Condiciones antes de continuar.';
			this.cancel.emit(err);
			return;
		}

		if (this.source === 'topups' && this.topupList.length === 0) {
			const err = new PaypalReturnModel();
			err.success = false;
			err.errorMessage = 'Tiene que agregar al menos una recarga a la lista.';
			this.cancel.emit(err);
			return;
		}

		if (this.selectedCard === 'new') {
			this.openCreateModal.next(true);
			this.isNewFormOpen = true;
			return;
		}

		const model = new FundPaymentModel();
		model.cbxCCProfile = this.selectedCard;
		model.IsCCSaved = true;
		model.newCard = null;

		this.onPayment.emit(model);
	}

	SaveNewCreditCard(card: NewCreditCardModel): void {
		const model = new FundPaymentModel();
		model.cbxCCProfile = '0';
		model.IsCCSaved = false;
		model.newCard = card;

		// cleaning the number
		model.newCard.edtCCNumber = model.newCard.edtCCNumber.replace(/\D/g, '');

		if (this.source !== 'checkout') {
			this.onPayment.emit(model);
		}


		this.isNewFormOpen = false;
	}

	onCancelCreateCreditCard(event: any): void {
		this.isNewFormOpen = false;
	}

	SetSelectedCard(cardToSelect: string) {
		if (this.source === 'checkout') {
			this.shoppingCartService.shoppingCart.selectedCreditCard = cardToSelect;
		}
	}
}
