import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthUserService } from '../services/auth-user.service';
import { UserModel } from '../../models/user.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
	constructor(
		private localStorageService: LocalStorageService,
		private injector: Injector
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const clientId = environment.clientId;
		const clientSecret = environment.clientSecret;

		const authUserService = this.injector.get(AuthUserService);

		const token = JSON.parse(this.localStorageService.get('token'));
		const authUser: UserModel = authUserService.GetAuthUser();

		let headers;
		let params: HttpParams;
		let body: URLSearchParams;

//		localStorage.clear();

		if (!!token && !!token.accessToken) {
			headers = request.headers
				.append('Authorization', `Bearer ${token.accessToken}`);
		} else {
			headers = request.headers
				.append('Authorization', 'Basic ' + btoa(clientId + ':' + clientSecret));
		}

		headers = headers.append('Mega-Client-Id', clientId);
		headers = headers.append('Accept', 'application/json');

		if (authUser && authUser.username) {
			headers = headers.append('Mega-Client-User', authUser.username);
		}

		params = new HttpParams({ fromString: request.params.toString() });
		body = request.body as URLSearchParams;
		if (!body) {
			body = new URLSearchParams();
		}

		if (request.method === 'POST') {
			headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
			body.set('source_app', environment.sourceApp);
		}

		if (request.method === 'GET') {
			params = params.append('source_app', environment.sourceApp);
		}

		if (request.method !== 'POST') {
			headers = headers.append('Content-Type', 'application/json');
		}

		if (!!this.localStorageService.get('_device_gch')) {
			headers = headers.append('Mega-Device-Id', this.localStorageService.get('_device_gch'));
		} else {
			headers = headers.append('Mega-Device-Id', '');
		}

		const reqClone = request.clone({ headers: headers, params: params, body: body.toString() });

		return next
			.handle(reqClone)
			.pipe(
				tap((event: HttpEvent<any>) => {
					// refresh token
					if (event instanceof HttpResponse) {
						const resHeaders = event.headers;

						if (resHeaders.get('Mega-Device-Id')) {
							this.localStorageService.set('_device_gch', resHeaders.get('Mega-Device-Id'));
						}

						if (resHeaders.get('_refresh_token')) {
							const refreshToken = {
								accessToken: resHeaders.get('_refresh_token')
							};

							this.localStorageService.set('token', JSON.stringify(refreshToken));
						}
					}

					return event;
				}),
				catchError(response => {
					if (response instanceof HttpErrorResponse && response.status === 401) {
						authUserService.LogOut();
						return;
					}

					// return the error to the method that called it
					return throwError(response);
				})
			);
	}

}
