import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { ShoppingCartModel } from '../../models/store/shopping-cart.model';
import { UserModel } from '../../models/user.model';
import { AuthUserService } from './auth-user.service';

@Injectable()
export class StorePaymentService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private authUserService: AuthUserService

	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetAuthorization(): Promise<ResultModel<any>> {
		return this.httpClient
			.get<ResultModel<any>>(`${this.baseUrl}api/storepayment/get_autorization`)
			.toPromise();
	}

	CompletePayment(deviceData: string, nonceToken: string, amount: number, shoppingCart: ShoppingCartModel): Promise<ResultModel<any>> {

		const user: UserModel = this.authUserService.GetAuthUser();

		const data = new URLSearchParams();		
		data.set('deviceData', deviceData);
		data.set('nonceToken', nonceToken);
		data.set('amount', amount.toString());
		data.set('serno', user.serno);
		data.set('shoppingCart', JSON.stringify(shoppingCart));

		return this.httpClient
			.post<ResultModel<any>>(`${this.baseUrl}api/storepayment/complete_payment`, data)
			.toPromise();
	}

	
	CompletePaypalPayment(deviceData: string, nonceToken: string, amount: number, shoppingCart: ShoppingCartModel): Promise<ResultModel<any>> {

		const user: UserModel = this.authUserService.GetAuthUser();

		const data = new URLSearchParams();
		data.set('deviceData', deviceData);
		data.set('nonceToken', nonceToken);
		data.set('amount', amount.toString());
		data.set('serno', user.serno);
		data.set('shoppingCart', JSON.stringify(shoppingCart));

		return this.httpClient
			.post<ResultModel<any>>(`${this.baseUrl}api/storepayment/complete_paypal_payment`, data)
			.toPromise();
	}


}
