import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { AuthUserService } from './auth-user.service';
import { UserModel } from '../../models/user.model';
import { ShoppingCartModel } from '../../models/store/shopping-cart.model';

@Injectable()
export class PaypalService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private authUserService: AuthUserService
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	public GetPaypalEnvironment(): Promise<ResultModel<string>> {
		return this.httpClient
			.get<ResultModel<string>>(`${this.baseUrl}api/paypal/init`)
			.toPromise();
	}

	// Funds

	public GetPaymendIdFunds(itemPrice: number): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('itemprice', itemPrice.toString());

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/init_funds`, { params: params })
					.toPromise();
			});
	}

	public CancelPayment(token: string): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('token', token);

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/cancel`, { params: params })
					.toPromise();
			});
	}

	public ProcessFundPayment(token: string, payerId: string, paymentId: string): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('token', token);
				params = params.append('payerId', payerId);
				params = params.append('paymentId', paymentId);

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/process_funds`, { params: params })
					.toPromise();
			});
	}

	// CHECKOUT
	public GetPaymentIdCheckout(shoppingCart: ShoppingCartModel): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('data', JSON.stringify(shoppingCart));

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/init_checkout`, { params: params })
					.toPromise();
			});
	}

	public ProcessCheckoutPayment(token: string, payerId: string, paymentId: string,
		shoppingCart: ShoppingCartModel): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('token', token);
				params = params.append('payerId', payerId);
				params = params.append('paymentId', paymentId);
				params = params.append('data', JSON.stringify(shoppingCart));

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/process_checkout`, { params: params })
					.toPromise();
			});
	}

	// TOPUPS

	public GetPaymentIdTopups(topups: any[]): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('data', JSON.stringify(topups));

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/init_topups`, { params: params })
					.toPromise();
			});
	}

	public ProcessTopupPayment(token: string, payerId: string, paymentId: string): Promise<ResultModel<string>> {
		const user: UserModel = this.authUserService.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);
				params = params.append('token', token);
				params = params.append('payerId', payerId);
				params = params.append('paymentId', paymentId);
				params = params.append('username', user.username);

				return this.httpClient
					.get<ResultModel<string>>(`${this.baseUrl}api/paypal/process_topups`, { params: params })
					.toPromise();
			});
	}
}
