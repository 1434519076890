import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthUserService } from '../services/auth-user.service';

@Injectable()
export class AdminAccessGuard implements CanActivate {
	constructor(
		private localStorageService: LocalStorageService,
		private router: Router,
		private authUserService: AuthUserService
	) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const token = JSON.parse(this.localStorageService.get('token'));
		const user = this.authUserService.GetAuthUser();
		const can = !!token && !!token.accessToken && !!user && user.edt_acs;

		if (!can) {
			this.router.navigate(['/account'], { queryParams: { security: 1 } });
			return false;
		}

		return true;
	}
}
