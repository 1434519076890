<mega-page-title title="Recargas a Cuba y el mundo"></mega-page-title>

<div class="container mg-top-title">
	<div class="row">
		<div class="col-12 col-sm-12 col-md-6">
			<img class="img-responsive" width="100%" src="assets/images/banner-recargas-cubacel-seo.png">
		</div>
		<div class="col-12 col-sm-12 col-md-6">
			<p>
				Aquí en Megaconecta encontraras las mejores opciones para enviar recargas a Cuba y el resto del mundo. Proveemos una serie de herramientas para brindar servicios mas rápidos, fáciles
				y con mejor calidad que otras compañías. Ahora en su cuenta usted pueda guardar su lista de contactos de Cuba o cualquier otros país, con tan solo un click usted puede realizar
				esta recarga desde su lista de contactos. Tambien brindamos reportes en tiempo real del estado de sus recargas y un departamento de atención al cliente con alta calidad para atender
				cualquier pregunta o inquietud.
			</p>
			<p>
				Somos los números uno en recargas a Cuba y el mundo; y lo confirman los cientos de usuarios que usan nuestros servicios cada dia. Todas nuestras recargas son 100% seguras, asi como
				su dinero y toda su información. Aceptamos una gran variedad de tipos de tarjetas de crédito, asi como PayPal también. No lo dude mas, comienze a usar nuestros servicios ahora mismo!
			</p>

			<p>
				No espere mas! Comienze a usar nuestros servicios ahora mismo.
			</p>

			<p class="text-center">
				<button class="btn btn-primary text-white" routerLink="/account/register">
					Registrate Ahora!
				</button>
			</p>

			<p>
				Su quiere ver mas información de los precio para su país, puede hacerlo a través de nuestra pagina de Tarifas o click
				<a routerLink="/rates">click</a> para verlos. Tambien puede instalar nuestra aplicación móvil para realizar recargas desde cualquier lugar.
			</p>

			<div class="row mg-mt-cta">
				<div class="col-12 text-center">
					<h3>Descargue la aplicaci&oacute;n gratis</h3>
					<p class="mb-4">Llame, Recargue y Env&iacute;e SMS
						<b>gratis</b> a Cuba</p>
					<a href="https://itunes.apple.com/us/app/megaconecta/id1000791688?ls=1&mt=8" target="_blank">
						<img class="img-responsive mr-3" src="assets/images/homepage/app-store.webp">
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.embarcadero.MegaConecta&hl=es" target="_blank">
						<img class="img-responsive" src="assets/images/homepage/app-store.webp">
					</a>
				</div>
			</div>



		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
