import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[only-number]'
})
export class OnlyNumberDirective {
	constructor(private el: ElementRef) { }

	@HostListener('keydown', ['$event']) onKeyDown(event) {
		const e = <KeyboardEvent>event;
		if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
			// Allow: Ctrl+A
			(e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
			// Allow: Ctrl+C
			(e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
			// Allow: Ctrl+V
			(e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
			// Allow: Ctrl+X
			(e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
			// Allow: home, end, left, right
			(e.keyCode >= 35 && e.keyCode <= 39)) {
			// let it happen, don't do anything
			return;
		}

		// make sure we are including only one dot & we dont allow the dot in the begining
		if ((e.keyCode === 190 && /\./i.test(this.el.nativeElement.value)) ||
			(e.keyCode === 190 && !this.el.nativeElement.value)) {
			e.preventDefault();
		} else if (e.keyCode === 190) {
			// let it happen, don't do anything
			return;
		}

		// Ensure that it is a number and stop the keypress
		if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
			e.preventDefault();
		}
	}

}
