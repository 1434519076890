<footer class="mg-footer">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 d-md-block d-none d-sm-none">
				<h4>
					enlaces de inter&eacute;s
					<div class="mg-line-bottom"></div>
				</h4>

				<ul class="mg-footer-links list-unstyled">
					<li>
						<a href routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
					</li>
					<li>
						<a href routerLink="services" routerLinkActive="active">Servicios</a>
					</li>
					<li>
						<a href routerLink="rates" routerLinkActive="active">Tarifas</a>
					</li>
					<li>
						<a href routerLink="access-numbers" routerLinkActive="active">N&uacute;meros de Acceso</a>
					</li>
					
					<li>
						<a href routerLink="contact" routerLinkActive="active">Contacto</a>
					</li>
					<li>
						<a href routerLink="help" routerLinkActive="active">Ayuda</a>
					</li>
				</ul>
			</div>
			<div class="col-lg-4 col-md-6 d-sm-12">
				<h4>
					contacto
					<div class="mg-line-bottom"></div>
				</h4>
				<div class="row">
					<div class="col-6">
						<address>						
							Miami, Florida
							<br>USA
						</address>
						<div class="mg-info-block">
							<h6>US:</h6>
							<a href="tel:+18552103000">1.855.210.3000</a><br>
							<a href="tel:+13055078545">1.305.507.8545</a>
						</div>
						<div class="mg-info-block">
							<h6>MX:</h6>
							<a href="tel:+525585264024">52.558.526.4024</a>
						</div>
					</div>
					<div class="col-6">
						<div class="mg-info-block mt-0">
							<h6>CA:</h6>
							<a href="tel:+16475607510">1.647.560.7510</a>
						</div>
						<div class="mg-info-block">
							<h6>ES:</h6>
							<a href="tel:+34910601594">34.910.60.1594</a>
						</div>
						<div class="mg-info-block">
							<h6>Email:</h6>
							<a href="mailto:ayuda@megaconecta.com" class="text-primary">ayuda@megaconecta.com</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12 mt-md-5 mt-lg-0">
				<div class="row">
					<div class="col-lg-12 col-md-6 d-md-block d-none d-sm-none">
						<h4>
							mantengase en contacto
							<div class="mg-line-bottom"></div>
						</h4>
						<p>Ahora puede subscribirse a nuestra lista de correos para recibir Ofertas Y Noticias de Megaconecta.</p>
						<form name="emailForm" [formGroup]="myForm" #formEmail="ngForm" (ngSubmit)="submitForm(formEmail)" novalidate>
							<div class="input-group">
								<input type="text" class="form-control" name="emailsus" id="emailsus" placeholder="Ingrese su email..." [formControl]="myForm.controls.email">
								<div class="input-group-append" (click)="formEmail.ngSubmit.emit()">
									<div class="input-group-text right text-uppercase text-white bg-primary">
										enviar
									</div>
								</div>
							</div>
							<div class="alert alert-danger mt-2" *ngIf="myForm.controls.email.errors && myForm.controls.email.errors.required && myForm.controls.email.touched">
								El Email es requerido.
							</div>
							<div class="alert alert-danger mt-2" *ngIf="myForm.controls.email.errors && !myForm.controls.email.errors.required && myForm.controls.email.errors.email && myForm.controls.email.touched">
								El Email tiene un formato no v&aacute;lido.
							</div>
						</form>
					</div>
					<div class="col-lg-12 col-md-6 mt-lg-4 col-sm-12 mt-sm-5 mt-5 mt-md-0">
						<h4>
							Siguenos en
							<div class="mg-line-bottom"></div>
						</h4>
						<div class="mg-social-links">
							<a href="https://www.facebook.com/MegaConecta/" target="_blank">
								<i class="fa fa-facebook" aria-hidden="true"></i>
							</a>
							<a href="https://twitter.com/MegaConecta" target="_blank">
								<i class="fa fa-twitter" aria-hidden="true"></i>
							</a>
							<a href="https://www.youtube.com/channel/UC0DeBP9gdjh3YFAFndk9m_Q" target="_blank">
								<i class="fa fa-youtube" aria-hidden="true"></i>
							</a>
							<a href="https://www.linkedin.com/company/16064162/" target="_blank">
								<i class="fa fa-linkedin" aria-hidden="true"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="w-100 mg-footer-infobar">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-12 text-center text-lg-left mb-lg-0 mb-4">
					<p>Copyright &copy; Mega Connect Corp. Todos los derechos reservados.</p>
				</div>
				<div class="col-lg-6  col-md-12 text-center text-lg-right">
					<ul class="list-inline">
						<li class="list-inline-item">
							<a href routerLink="/about-us">¿Qui&eacute;nes somos?</a>
						</li>
						<li class="list-inline-item">/</li>
						<li class="list-inline-item">
							<a href routerLink="/term-conditions">T&eacute;minos y Condiciones</a>
						</li>
						<li class="list-inline-item">/</li>
						<li class="list-inline-item">
							<a href routerLink="/privacy">Pol&iacute;tica de Privacidad</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	

</footer>
