<mega-page-title title="T&eacute;rminos y Condiciones"></mega-page-title>

<div class="container mg-top-title">
	<div class="row">
		<div class="col">
			<p>
				<b class="text-primary">MegaConecta</b> es un servicio de Mega Connect Corp. El objetivo del Mega Connect Corp es proveer el servicio de telefon&iacute;a de la m&aacute;s alta calidad con las mejores tarifas.
				El uso de este sitio web est&aacute; expresamente sujeto a la aceptaci&oacute;n de todos los t&eacute;rminos y condiciones establecidos en el presente documento y en cualquier otro
				lugar de este sitio Web. No utilice este sitio web, si no acepta todos los t&eacute;rminos y condiciones establecidos en el presente documento.
			</p>
			<p>
				Es una ofensa federal y estatal el comprar cualquier producto o servicio por medios fraudulentos. Los productos y/o servicios adquiridos en este sitio Web no se deber&aacute;n ser utilizados
				para ning&uacute;n prop&oacute;sito ilegal. Al usar este sitio web, usted admite tener 18 anos de edad, ser mayor o poseer el consentimiento de sus padres o tutores y que son plenamente
				capaces y competentes para entender los t&eacute;rminos, condiciones, representaciones y garant&iacute;as establecidos en los t&eacute;rminos y condiciones de lo contrario, por
				favor deje el sitio web.
			</p>
			<p>
				Al utilizar el servicio de llamadas de prepago de
				<b class="text-primary">MegaConecta</b>, el usuario acepta las tarifas, t&eacute;rminos y condiciones establecidos en este sitio Web.
				<b class="text-primary">MegaConecta</b> puede cambiar o modificar los t&eacute;rminos sin m&aacute;s aviso que publicar los t&eacute;rminos modificados en el sitio web.
				<b class="text-primary">MegaConecta</b> se reserva el derecho de cambiar, modificar o discontinuar temporal o permanentemente el sitio Web (o cualquier parte del mismo), incluyendo cualquier y todo el
				contenido en el sitio web, en cualquier momento sin previo aviso. Usted acepta que
				<b class="text-primary">MegaConecta</b>
				no ser&aacute; responsable ante usted o terceros por cualquier modificaci&oacute;n, suspensi&oacute;n o interrupci&oacute;n del sitio web.
			</p>

			<p>
				Siempre que usted proporciona informaci&oacute;n en nuestro sitio web, usted acepta: (a) proporcionar informaci&oacute;n verdadera, precisa, actual y completa y (b) mantener y actualizar
				dicha informaci&oacute;n a la brevedad posible para mantenerla verdadera, precisa, actual y completa. Si usted brinda cualquier informaci&oacute;n o tengamos motivos razonables
				para sospechar que la informaci&oacute;n es falsa, inexacta, no actual o incompleta,
				<b class="text-primary">MegaConecta</b> puede, sin previo aviso, suspender o cancelar su cuenta y negarle todo uso actual o futuro de nuestro sitio web (o cualquier parte del mismo). Por favor, seleccione
				una contrase&ntilde;a que no ser&iacute;a evidente a cualquiera que intente adivinarla, y c&aacute;mbiela regularmente como una precauci&oacute;n adicional. Adem&aacute;s, le recomendamos
				utilizar una contrase&ntilde;a diferente de la cuenta de correo electr&oacute;nico como una medida de seguridad adicional. Usted es responsable de mantener la confidencialidad de
				la contrase&ntilde;a y la cuenta, y usted es plenamente responsable de todas las actividades que ocurran bajo su cuenta.Usted acepta notificar inmediatamente a
				<b class="text-primary">MegaConecta</b> de cualquier uso no autorizado de su contrase&ntilde;a o cuenta o cualquier otra violaci&oacute;n de seguridad.
			</p>
			<p>
				Mediante la aceptaci&oacute;n por su parte de los T&eacute;rminos y Condiciones expuestos ac&aacute; por
				<b class="text-primary">MegaConecta</b>, usted da su consentimiento a la compa&ntilde;&iacute;a para que le sean enviados e-mails y mensajes de texto (SMS) con anuncios y promociones v&aacute;lidas y de
				utilidad para cada cliente. A pesar de ello, usted tendr&aacute; la opci&oacute;n de eliminar su suscripci&oacute;n en el momento en que lo requiera.
			</p>
			<p>
				Nuestro sitios web se comunican a trav&eacute;s del protocolo Secure Socket Layer mediante certificados para garantizar la protecci&oacute;n de sus datos personales.
			</p>
			<p>
				Acuerdo de llamadas prepago de
				<b class="text-primary">MegaConecta</b> - sujeto a cambio en cualquier momento sin previo aviso.
			</p>
			<ul>
				<li> Las tarifas pueden variar y est&aacute;n sujetas a cambios sin previo aviso.</li>
				<li> Las llamadas de tel&eacute;fonos est&aacute;n disponibles s&oacute;lo a trav&eacute;s de los n&uacute;meros de acceso local.
				</li>
				<li> Las cuentas inactivas por mas de tres meses seran cerradas</li>
				<li> Al utilizar los n&uacute;meros de acceso local, el usuario acepta los cargos por la llamada local a nuestro n&uacute;mero desde su compa&ntilde;&iacute;a telef&oacute;nica.
				</li>
				<li> Las llamadas ser&aacute;n facturadas en incrementos de un minuto, con fracciones redondeadas al minuto.
				</li>
				<li> No aceptamos reclamaciones de llamadas despues de transcurrido 48 horas de la misma.</li>
			</ul>
		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
