import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-services',
	templateUrl: './services.component.html',
	styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
	public servicesData: any[] = [];

	constructor() { }

	ngOnInit() {
		this.servicesData = [
			{
				title: 'Marcado Sin Pin',
				description: `Marcado sin PIN es la capacidad de realizar llamadas sin tener que introducir su PIN.
				 M&aacute;s r&aacute;pido que llamar con tarjetas y ahorra m&aacute;s del 80% en largas distancias.`,
				icon: 'assets/images/services/marcado-sin-pin.png'
			},
			{
				title: 'Marcado Directo',
				description: `Marcado directo le permite llamar a sus familiares o amigos con un n&uacute;mero de acceso local y el sistema
				 autom&aacute;ticamente lo conectar&aacute; con su destino internacional.`,
				icon: 'assets/images/services/marcado-directo.png'
			},
			{
				title: 'Recarga por Teléfono',
				description: `Ahora usted no est&aacute; limitado a recargar su cuenta solo online, usted puede hacer
				 una recarga en su tel&eacute;fono marcando el n&uacute;mero de acceso y luego seleccionando la opci&oacute;n ( * # ).`,
				icon: 'assets/images/services/recarga-por-telefono.png'
			},
			{
				title: 'Recarga Autom&aacute;tica',
				description: `La recarga automática funciona añadiendo saldo a su cuenta cuando es inferior a cierta
				 cantidad que usted decida para que nunca se quede sin minutos de llamada.`,
				icon: 'assets/images/services/recarga-automatica.png'
			},
			{
				title: 'Panel de Control',
				description: `Acceso a ver el historial de llamadas, ver el historial de pagos,
				 la marcaci&oacute;n directa! Usted nunca ha tenido tanta comodidad a su alcance.`,
				icon: 'assets/images/services/panel-control.png'
			},
			{
				title: 'Cobertura Mundial',
				description: `Ofrecemos cobertura mundial para llamadas, mensajes de texto,
				 recargas internacionales gracias a nuestra tecnolog&iacute;as y redes de comunicaci&oacute;n.`,
				icon: 'assets/images/services/cobertura-mundial.png'
			},
			{
				title: 'M&uacute;ltiples Formas de Pago',
				description: `Nuestro formulario de pagos permite tarjetas de cr&eacute;dito/d&eacute;bito
				 o paypal y es completamente asegurado con un s&oacute;lido cifrado de 128 bits.`,
				icon: 'assets/images/services/multiple-formas-pago.png'
			},
			{
				title: 'Soporte T&eacute;cnico',
				description: `Brindamos un soporte t&eacute;cnico de excelencia.
				 Atendemos sus dudas, sugerencias e inquietudes por tel&eacute;fono, chat o por email.`,
				icon: 'assets/images/services/soporte-tecnico.png'
			},
			{
				title: 'Lista de Contactos',
				description: `Ahora le brindamos la posibilidad de guardar su lista de contactos
				 para r&aacute;pido acceso en recargas, env&iacute;os de SMS y otras acciones.`,
				icon: 'assets/images/services/lista-contacto.png'
			},
		];
	}

}
