<div class="container mt-4 mt-md-0">
	<div class="row">
		<div class="col"></div>
		<div class="col-auto text-center">
			<h2 class="text-secondary">
				<span [innerHTML]="title"></span>
				<div class="mg-line-title"></div>
			</h2>

			<span *ngIf="user?.edt_acs">
				<a href class="text-primary" routerLink="/admin/content-list">Ir a Admin</a>
			</span>

			<span class="mt-2 d-flex d-md-none" *ngIf="!!user">
				Bienvenido, {{ user?.firstname }} (
				<a href class="text-primary" (click)="LogOut(); $event.preventDefault();">Salir</a>)
			</span>
		</div>
		<div class="col"></div>
	</div>
</div>
