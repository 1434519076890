<mega-page-title title="N&uacute;meros de Acceso"></mega-page-title>

<div class="container mg-top-title">
    <div class="row">
        <div class="col-lg-2 d-lg-flex d-md-none"></div>
        <div class="col-lg-4 col-md-12">
            <p>
                Los N&uacute;meros de Acceso son n&uacute;meros telef&oacute;nicos para hacer llamadas a Cuba y llamadas internacionales al mejor precio.
            </p>
            <p>
                MegaConecta va a asignar n&uacute;meros de acceso personalizados para cada uno de sus contactos una vez creados. Puede guardar estos en tu lista de contactos de su tel&eacute;fono y evita tener que marcar cada vez.
            </p>
            <p>
                Tambi&eacute;n le asignaremos un n&uacute;mero de Acceso global para poder llamar a tel&eacute;fonos que no tenga creado como contacto en nuestra p&aacute;gina.
            </p>
        </div>
        <div class="col-lg-4 col-md-12">
            <div class="text-primary mb-4 mg-access-title">
                Puede acceder a estos n&uacute;meros creando una cuenta en nuestra p&aacute;gina o entrando a su cuenta:
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-12 mb-4">
                    <button routerLink="/account/login" class="btn btn-primary text-white btn-block">Entrar a su Cuenta</button>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-12">
                    <button routerLink="/account/register" class="btn btn-outline-primary btn-block">Crear nueva Cuenta</button>
                </div>
            </div>
        </div>
        <div class="col-lg-2 d-lg-flex d-md-none"></div>
    </div>
    <div class="row">
        <div class="col-lg-2 d-lg-flex d-md-none"></div>
        <div class="col-lg-8 col-md-12">
            <hr class="my-4">
        </div>
        <div class="col-lg-2 d-lg-flex d-md-none"></div>
    </div>
</div>

<mega-social-media></mega-social-media>