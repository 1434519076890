import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { ProvinceModel } from '../../models/province.model';
import { MunicipeModel } from '../../models/municipe.model';
import { ShoppingCartModel } from '../../models/store/shopping-cart.model';

@Injectable()
export class LocationService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetProvinces(): Promise<ResultModel<ProvinceModel>> {
		return this.httpClient
			.get<ResultModel<ProvinceModel>>(`${this.baseUrl}api/location/list_provinces`)
			.toPromise();
	}

	GetProductMunicipes(provinceId: string, shoppingCart: ShoppingCartModel): Promise<ResultModel<MunicipeModel>> {		

		const data = new URLSearchParams();		
		data.set('provinceId', provinceId);
		data.set('shoppingCart', JSON.stringify(shoppingCart));

		return this.httpClient
			.post<ResultModel<any>>(`${this.baseUrl}api/location/list_product_municipes`, data)
			.toPromise();		
	}


	GetMunicipes(provinceId: string): Promise<ResultModel<MunicipeModel>> {
		return this.httpClient
			.get<ResultModel<MunicipeModel>>(`${this.baseUrl}api/location/list_municipes/${provinceId}`)
			.toPromise();
	}

	GetProvince(provinceId: string): Promise<ResultModel<ProvinceModel>> {
		return this.httpClient
			.get<ResultModel<ProvinceModel>>(`${this.baseUrl}api/location/singleprovince/${provinceId}`)
			.toPromise();
	}

	GetMunicipe(municipeId: string): Promise<ResultModel<MunicipeModel>> {
		return this.httpClient
			.get<ResultModel<MunicipeModel>>(`${this.baseUrl}api/location/singlemunicipe/${municipeId}`)
			.toPromise();
	}


}
