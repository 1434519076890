import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CountrySmsModel } from '../../models/country-sms.model';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { CarrierRatesModel } from '../../models/carrier-rates.model';
import { AmountRatesModel } from '../../models/amount-rates.model';
import { SearchPagerModel } from '../../models/search-pager.model';
import { LlamadaRateModel } from '../../models/llamada-rate.model';

@Injectable()
export class RatesService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetSmsPrice(): Promise<ResultModel<CountrySmsModel>> {
		return this.httpClient
			.get<ResultModel<CountrySmsModel>>(`${this.baseUrl}api/countries/list_sms_rates`)
			.toPromise();
	}

	GetCountriesTopup(): Promise<ResultModel<any>> {
		return this.httpClient
			.get<ResultModel<any>>(`${this.baseUrl}api/countries/list_rates`)
			.toPromise();
	}

	GetCarriersRecargas(country: string): Promise<ResultModel<CarrierRatesModel>> {
		let params = new HttpParams();
		params = params.append('country', country);

		return this.httpClient
			.get<ResultModel<CarrierRatesModel>>(`${this.baseUrl}api/mobile_carriers/list_rates`, { params: params })
			.toPromise();
	}

	GetAmountRecargas(country: string, operador: number): Promise<ResultModel<AmountRatesModel>> {
		let params = new HttpParams();
		params = params.append('country', country);
		params = params.append('opercod', operador.toString());

		return this.httpClient
			.get<ResultModel<AmountRatesModel>>(`${this.baseUrl}api/mobile_carriers/list_amount_rates`, { params: params })
			.toPromise();
	}

	GetLlamadasRates(searchParams: SearchPagerModel): Promise<ResultModel<LlamadaRateModel>> {
		let params = new HttpParams();
		params = params.append('text', searchParams.search);
		params = params.append('skip', searchParams.skip.toString());
		params = params.append('take', searchParams.take.toString());
		params = params.append('sort_fied', searchParams.sortField);
		params = params.append('sort_dir', searchParams.sortDir);

		return this.httpClient
			.get<ResultModel<LlamadaRateModel>>(`${this.baseUrl}api/ratetablepatterns/list`, { params: params })
			.toPromise();
	}
}
