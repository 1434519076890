export class UpdateCreditCardModel {
	NUMBER: string;
	MONTH: string;
	YEAR: string;
	CVC: string;
	ADDRESS: string;
	CITY: string;
	STATE: string;
	ZIP: string;
}
