import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'mega-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit {
	panelOpen: string;
	helpItems: any[];
	constructor() { }

	ngOnInit() {
		this.helpItems = [
			{ id: 'v1', text: 'Agregar Fondos con PayPal', video: 'iuKMEMzzyhI' },
			{ id: 'v2', text: 'Agregar fondos con Tarjeta de Cr&eacute;dito', video: 'Enh4RnCzQUw' },
			{ id: 'v3', text: 'Agregar N&uacute;meros de Marcado Directo', video: 'ZWhWUA_IeJk' },
			{ id: 'v4', text: 'Registrar Nueva Cuenta', video: 'kMYe3xH5kUI' },
			{ id: 'v5', text: 'Enviar SMS', video: '7Mix4hNCf98' },
			{ id: 'v6', text: 'Crear Nuevo Contacto', video: 'fjnP4Y9iZQ0' },
			{ id: 'v7', text: 'Editar su Cuenta', video: 'n0qfhi5MGh4' },
			{ id: 'v8', text: 'Entrar a su Cuenta', video: 'EQ0TuvvdMqw' },
			{ id: 'v9', text: 'Recargar un M&oacute;vil', video: 'p4Qw4ypimnc' },
			{ id: 'v10', text: 'Recibir SMS desde Cuba', video: 'Bi3qi8_-P6w' },
			{ id: 'v11', text: 'Recuperar su Contrase&ntilde;a', video: 'aRvbH28G_W4' },
			{ id: 'v12', text: 'Ver las Tarifas', video: '3joMtp04Udk' }
		];
	}

	panelChange(data: NgbPanelChangeEvent): void {
		if (data.panelId && data.nextState) {
			this.panelOpen = data.panelId;
		}

		if (!data.nextState) {
			this.panelOpen = '';
		}
	}

	isOpen(id: string): boolean {
		return id === this.panelOpen;
	}

}
