import { Injectable } from '@angular/core';
import { ShoppingCartModel } from '../../models/store/shopping-cart.model';
import { ProductCartModel } from '../../models/store/product-cart.model';
import { ProductModel } from '../../models/store/product.model';
import { ProductFeatureModel } from '../../models/store/product-feature.model';
import { ProductImageModel } from '../../models/store/product-image.model';
import { relativeTimeRounding } from 'moment';

@Injectable()
export class ShoppingCartService {
	public shoppingCart: ShoppingCartModel;
	public deliveryContactValid: boolean;

	constructor() {
		this.shoppingCart = new ShoppingCartModel();
		this.shoppingCart.saveCcAccount = false;
		const savedData = JSON.parse(localStorage.getItem('MCShoppingCart'));
		if (savedData) {
			this.shoppingCart = savedData;
			if (this.shoppingCart.selectedDeliveryType === '') {
				this.shoppingCart.selectedDeliveryType = '1';
			}

			if (this.shoppingCart.selectedPaymentMethod === '') {
				this.shoppingCart.selectedPaymentMethod = '1';
			}
		}


		this.calculateTotals();
	}

	saveAll() {
		localStorage.setItem('MCShoppingCart', JSON.stringify(this.shoppingCart));
	}

	clearAll() {
		this.shoppingCart = new ShoppingCartModel();
		this.saveAll();
		localStorage.setItem('MCShoppingCart', JSON.stringify(this.shoppingCart));
	}

	calculateTotals() {
		this.shoppingCart.totalBeforeShipping = 0;
		this.shoppingCart.totalAfterShipping = 0;

		for (let i = 0; i < this.shoppingCart.productsInCart.length; i++) {
			// tslint:disable-next-line:max-line-length
			this.shoppingCart.totalBeforeShipping += (Number(this.shoppingCart.productsInCart[i].price) * Number(this.shoppingCart.productsInCart[i].quantity));
		}

		this.shoppingCart.totalAfterShipping = this.shoppingCart.totalBeforeShipping + Number(this.shoppingCart.deliveryOptions.price);

		this.countItemsInCart();
	}

	countItemsInCart() {

		this.shoppingCart.numItemsCart = 0;
		for (let i = 0; i < this.shoppingCart.productsInCart.length; i++) {
			this.shoppingCart.numItemsCart += Number(this.shoppingCart.productsInCart[i].quantity);
		}

		if (this.shoppingCart.numItemsCart === 0){
			this.shoppingCart = new ShoppingCartModel();
		}

		this.saveAll();
	}

	validateDelivery(AProductDetailsModel: ProductModel){
		let wrongDelivery: boolean = false;
		for (let i = 0; i < this.shoppingCart.productsInCart.length; i++) {
			if (this.shoppingCart.productsInCart[i].deliveries[0].code !== AProductDetailsModel.deliveries[0].code) {				
				wrongDelivery = true;
			}
		}

		return wrongDelivery;
	}

	addProductToCart(AProductDetailsModel: ProductModel, ColorID: string, Quantity: number) {

		let found: boolean = false;
		let AProductCartModel: ProductCartModel;
		

		for (let i = 0; i < Quantity; i++) {

			AProductCartModel = new ProductCartModel;
			AProductCartModel.productId = AProductDetailsModel.id;
			AProductCartModel.productName = AProductDetailsModel.name;
			AProductCartModel.shoppingCartText = AProductDetailsModel.shopping_cart_text;			
			AProductCartModel.cost = Number(AProductDetailsModel.cost);	
			AProductCartModel.colorId = '';

			for (let i = 0; i < AProductDetailsModel.colors.length; i++) {
				if (AProductDetailsModel.colors[i].id === ColorID) {
					AProductCartModel.colorId = ColorID;
					AProductCartModel.colorName = AProductDetailsModel.colors[i].color;
				}
			}

			AProductCartModel.productDetails = AProductDetailsModel.details as ProductFeatureModel[];
			AProductCartModel.price = this.GetProductPrice(AProductDetailsModel);
			AProductCartModel.productImage = (AProductDetailsModel.imagen[0] as ProductImageModel).image;
			AProductCartModel.quantity = 1;
			AProductCartModel.description = AProductDetailsModel.description;
			AProductCartModel.deliveries = AProductDetailsModel.deliveries;
			this.shoppingCart.productsInCart.push(AProductCartModel);
		}
		


		this.calculateTotals();
	}

	removeProductFromCart(AProductCartModel: ProductCartModel) {
		for (let i = 0; i < this.shoppingCart.productsInCart.length; i++) {
			if ((this.shoppingCart.productsInCart[i].productId === AProductCartModel.productId) &&
				(this.shoppingCart.productsInCart[i].colorId === AProductCartModel.colorId)) {
				this.shoppingCart.productsInCart.splice(i, 1);
			}
		}

		this.calculateTotals();
	}

	private GetProductPrice(product: ProductModel): number {
		if (!!product.new_price) {
			return Number(product.new_price);
		}

		return Number(product.original_price);
	}
}
