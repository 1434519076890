<div class="text-center mg-social-bar">
	<h6>COMPARTE EL SITIO</h6>

	<div>
		<a href="https://www.facebook.com/MegaConecta/" target="_blank">
			<i class="fa fa-facebook" aria-hidden="true"></i>
		</a>
		<a href="https://twitter.com/MegaConecta" target="_blank">
			<i class="fa fa-twitter" aria-hidden="true"></i>
		</a>
		<a href="https://www.youtube.com/channel/UC0DeBP9gdjh3YFAFndk9m_Q" target="_blank">
			<i class="fa fa-youtube" aria-hidden="true"></i>
		</a>
		<a href="https://www.linkedin.com/company/16064162/" target="_blank">
			<i class="fa fa-linkedin" aria-hidden="true"></i>
		</a>
	</div>
</div>
