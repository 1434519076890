import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ProductImageModel } from '../../../models/store/product-image.model';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'mega-img-carousel',
	templateUrl: './img-carousel.component.html',
	styleUrls: ['./img-carousel.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ImgCarouselComponent implements OnInit {
	@Input() imgslides: ProductImageModel[];

	constructor(
		private ngbCarouselConfig: NgbCarouselConfig
	) {
		this.ngbCarouselConfig.interval = 10000;
		this.ngbCarouselConfig.wrap = true;
		this.ngbCarouselConfig.keyboard = true;
	}

	ngOnInit() {

	}
}
