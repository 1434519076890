import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CarouselService } from '../../services/carousel.service';
import { CarouselModel } from '../../../models/carousel.model';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { sortBy } from 'lodash';

@Component({
	selector: 'mega-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CarouselComponent implements OnInit {
	slides: CarouselModel[] = [];

	constructor(
		private carouselService: CarouselService,
		private ngbCarouselConfig: NgbCarouselConfig
	) {
		this.ngbCarouselConfig.interval = 10000;
		this.ngbCarouselConfig.wrap = true;
		this.ngbCarouselConfig.keyboard = true;
	}

	ngOnInit() {
		this.carouselService
			.GetAllSlides()
			.then(response => {
				if (response.has_error) {
					throw new Error();
				}

				this.slides = sortBy(response.array, o => {
					return Number(o.ORDER);
				});
			})
			.catch(err => {
				this.slides = [];
			});
	}

}
