import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { AuthUserService } from '../../services/auth-user.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
	selector: 'mega-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	links: any[];

	@Input() sidebar;

	constructor(
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
		private localStorageService: LocalStorageService,
		private router: Router,
		private authUserService: AuthUserService,
		public shoppingCartService: ShoppingCartService
	) { }

	ngOnInit() {
		this.links = [
			{ name: 'INICIO', link: '/' },
			{ name: 'SERVICIOS', link: 'services' },
			{ name: 'TARIFAS', link: 'rates' },
			{ name: 'NUMEROS DE ACCESSO', link: 'access-numbers' },
			{ name: 'COMBOS DE COMIDA', link: 'store' },
			{ name: 'CONTACTO', link: 'contact' },
			{ name: 'AYUDA', link: 'help' }
		];


	}

	isAuthenticated(): boolean {
		const data = JSON.parse(this.localStorageService.get('mega_data'));

		return !!data && !!data.isAuthenticated && !!data.user;
	}

	getUserName(): string {
		const data = JSON.parse(this.localStorageService.get('mega_data'));

		if (data && data.user) {
			return data.user.firstname;
		}

		return '';
	}

	logOut(event: any): void {
		event.preventDefault();

		this.ng4LoadingSpinnerService.show();
		this.authUserService.LogOut(true);
		this.ng4LoadingSpinnerService.hide();
	}



}
