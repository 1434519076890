import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { HelpComponent } from './help/help.component';
import { ServicesComponent } from './services/services.component';
import { RatesComponent } from './rates/rates.component';
import { AccessNumberComponent } from './access-number/access-number.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { LlamadasCubaComponent } from './llamadas-cuba/llamadas-cuba.component';
import { RecargasNautaComponent } from './recargas-nauta/recargas-nauta.component';
import { RecargasCubacelComponent } from './recargas-cubacel/recargas-cubacel.component';
import { LoginAuthGuard } from '../shared/guards/login-auth.guard';


// tslint:disable-next-line:max-line-length
const keywords = `SMS gratis,llama cuba,sms gratis a cuba,recarga cubacel,sms a cuba,sms gratis,llamada desde cuba,llamada Cuba,llamada a Cuba,mensaje de texto a cuba,llamada cuba gratis,llama gratis,llama cuba,llamar a Cuba,llamada cuba barata,sms Cuba,llamada regalo,mini llamada,mensaje de voz cuba,minillamada,mensaje de texto cuba,llamada gratis,mini llamada,sms desde cuba,minillamada desde cuba,sms barato,sms gratis,sms desde cuba,texto cuba,skype llama cuba,mini llamada desde Cuba,sms de respuesta,recarga doble,buzon voz,llama a Cuba,mensaje voz,eléfono Cuba,mensaje voz cuba,buzon voz cuba,sms cuba,celular cuba,móvil cuba, nauta, recarga nauta, cubacel, internet cuba, wifi cuba, nauta internet cuba`;

const routes: Routes = [
	{
		path: '',
		component: HomepageComponent,
		data: {
			title: 'Llamadas, Recargas Cubacel, Mensajes y Nauta a Cuba',
			seo: {
				// tslint:disable-next-line:max-line-length
				keywords: `sms gratis,sms gratis a cuba,sms a cuba,sms Cuba,sms desde cuba,sms de respuesta,mensaje de texto a cuba,texto cuba,sms barato,llamada desde cuba,llamada Cuba,llamada a Cuba,llamada cuba gratis,llama gratis,llamar a Cuba,llamada cuba barata,llamada gratis,llama a Cuba,kype llama cuba,llamadas a Cuba,mensaje de voz cuba,recarga doble,buzon voz,mensaje voz,mensaje voz cuba,buzon voz cuba,celular cuba,móvil cuba, nauta, recarga nauta, cubacel, internet cuba, wifi cuba, nauta internet cuba`,
				description: 'Mejor servicio y calidad en llamadas, recargas Cubacel, SMS y recargas Nauta a Cuba y el mundo.'
			}
		}
	},
	{
		path: 'services',
		component: ServicesComponent,
		data: {
			title: 'Servicios',
			seo: {
				keywords: keywords,
				description: 'Conozca mas sobre todos los servicios que ofrecemos en Megaconecta'
			}
		}
	},
	{
		path: 'rates',
		component: RatesComponent,
		data: {
			title: 'Tarifas',
			seo: {
				keywords: keywords,
				description: 'Todas las tarifas de llamadas, recargas y SMS para todos los paises y operadores que ofrecemos'
			}
		}
	},
	{
		path: 'access-numbers',
		component: AccessNumberComponent,
		data: {
			title: 'Numeros de Acceso',
			seo: {
				keywords: keywords,
				description: 'Obtén los Números de acceso para su cuenta para las llamadas de Megaconecta'
			}
		}
	},
	{
		path: 'contact',
		component: ContactComponent,
		data: {
			title: 'Contacto',
			seo: {
				keywords: keywords,
				description: 'Contacte nuestro Departamento de Servicio al cliente para cualquier informacion o quejas'
			}
		}
	},
	{
		path: 'help',
		component: HelpComponent,
		data: {
			title: 'Ayuda',
			seo: {
				keywords: keywords,
				description: 'Ver todos los videos de ayuda para aprender de forma sencilla y rápida como una nuestra aplicación'
			}
		}
	},
	{
		path: 'about-us',
		component: AboutUsComponent,
		data: {
			title: '¿Quienes Somos?',
			seo: {
				keywords: keywords,
				description: 'Conozca todo sobre nuestra compañia y quienes somos'
			}
		}
	},
	{
		path: 'term-conditions',
		component: TermConditionsComponent,
		data: {
			title: 'Terminos y Condiciones',
			seo: {
				keywords: keywords,
				description: 'Lea los Terminos y Condiciones para el uso de nuestros servicios online'
			}
		}
	},
	{
		path: 'privacy',
		component: PrivacyComponent,
		data: {
			title: 'Politica de Privacidad',
			seo: {
				keywords: keywords,
				description: 'Lea la Politica de Privacidad que le ofrecemos con el uso de nuestros servicios online'
			}
		}
	},
	{
		path: 'llamadas-cuba',
		component: LlamadasCubaComponent,
		data: {
			title: 'Llamadas a Cuba y el mundo',
			seo: {
				keywords: keywords,
				description: 'Megaconecta le ofrece el mejor servicio de calidad en llamadas a Cuba (celulares y fijos) y al resto del mundo.'
			}
		}
	},
	{
		path: 'recargas-nauta',
		component: RecargasNautaComponent,
		data: {
			title: 'Recargas Nauta',
			seo: {
				keywords: keywords,
				description: 'Megaconecta le ofrece el mejor servicio de recargas Nauta para la conexión de Internet via WiFi en Cuba.'
			}
		}
	},
	{
		path: 'recargas-cubacel',
		component: RecargasCubacelComponent,
		data: {
			title: 'Recargas Cubacel',
			seo: {
				keywords: keywords,
				description: 'Megaconecta le ofrece el mejor servicio de recargas CubaCel para los celulares en Cuba.'
			}
		}
	},
	{
		path: '**',
		component: DynamicContentComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class InformationRoutingModule { }
