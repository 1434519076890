import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-social-media',
	templateUrl: './social-media.component.html',
	styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
