import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { CarouselModel } from '../../models/carousel.model';

@Injectable()
export class ImgCarouselService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetAllSlides(): Promise<ResultModel<CarouselModel>> {
		return this.httpClient
			.get<ResultModel<CarouselModel>>(`${this.baseUrl}api/content/getall_active_carousel`)
			.toPromise();
	}
}
