<mega-page-title title="Recargas Nauta"></mega-page-title>

<div class="container mg-top-title">
	<div class="row">
		<div class="col-12 col-sm-12 col-md-6">
			<img class="img-responsive" width="100%" src="assets/images/banner-recargas-nauta-seo.png">
		</div>
		<div class="col-12 col-sm-12 col-md-6">
			<p>
				Desde que Cuba lanzó el servicio de
				<strong>Internet Nauta</strong>, este se ha convertido en la via mas popular de comunicacion hacia y desde la Isla para todo el mundo.
				<strong>Megaconecta</strong> te brinda variedad de opciones para mantenerte comunicados con toda tu gente en Cuba, siendo la recarga Nauta una de ellas.
			</p>
			<p>
				En Cuba los que quieren usar este servicio necesitan contratar una cuenta permanente Nauta, tras lo cual el usuario deberá solicitar la activación del servicio de navegación. Esta cuenta
				es recargable desde el exterior y caduca a los 330 días si no se recarga.
			</p>
			<p>
				Megaconecta ofrece varios paquetes de recarga comenzando desde los 10 CUC. Para ver mas opciones de recarga a Nauta y Cubacel, registrese ahora en nuestro sitio o si ya tiene una cuenta
				entre ahora mismo para comenzar a comunicarte con los tuyos.
			</p>

			<p class="text-center">
				<button class="btn btn-primary text-white" routerLink="/account/register">
					Registrate Ahora!
				</button>
			</p>

			<p>
				Tambien puede acceder a nuestra aplicación mobil para tener acceso a Megaconecta y a sus recargas desde todas partes.
			</p>
			<div class="row mg-mt-cta mb-3">
				<div class="col-12 text-center">
					<h3>Descargue la aplicaci&oacute;n gratis</h3>
					<p class="mb-4">Llame, Recargue y Env&iacute;e SMS
						<b>gratis</b> a Cuba</p>
					<a href="https://itunes.apple.com/us/app/megaconecta/id1000791688?ls=1&mt=8" target="_blank">
						<img class="img-responsive mr-3" src="assets/images/homepage/app-store.webp">
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.embarcadero.MegaConecta&hl=es" target="_blank">
						<img class="img-responsive" src="assets/images/homepage/app-store.webp">
					</a>
				</div>
			</div>
			<h3 class="mt-5 text-center">Puntos Nauta de Cuba</h3>
			<p>
				ETECSA creó Puntos de Acceso Nauta WIFI en todo el país, desde los que tus familiares y amigos pueden conectarse utilizando un celular, tablet o una laptop con la WIFI habilitada. Para
				buscar un punto cercano a tu familia en Cuba, seleccione la provincia para ver todos los puntos disponibles:
			</p>
			<p>
				<label class="w-100">Provincia:</label>
				<select class="form-control" [(ngModel)]="selectedProvince" (change)="onProvinciaChange()">
					<option *ngFor="let item of provinces" [value]="item.id">{{ item.text }}</option>
				</select>
				<label class="w-100 mt-3">Municipio:</label>
				<select class="form-control" [(ngModel)]="selectedMunicipio">
					<option value="Todos">Todos</option>
					<option *ngFor="let muni of getAllMunicipiosByProvince()" [value]="muni">{{ muni }}</option>
				</select>
			</p>

			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'PR'">
				<div class="panel-body areas_wifi">
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Pinar del Río'">
						<h5>Pinar del Río</h5>
						<ul>
							<li>Parque Independencia</li>
							<li>Parque Roberto Amarán </li>
							<li>Parque Centro Comercial Hermanos Cruz </li>
							<li>Parque Colón</li>
							<li>El Renacer.Hermanos Cruz </li>
							<li>Centro Comercial Alameda</li>
							<li>Punto de venta CIMEX La Cristal</li>
							<li>Parque La Coloma</li>
							<li>Parque Las Ovas</li>
							<li>Paseo Calle Martí</li>
							<li>Parque José Martí</li>
							<li>Parque Montequin</li>
							<li>Centro recreativo Super Gol</li>
							<li>Centro recreativo Criollo</li>
							<li>Cabaret Restaurante Rumayor</li>
							<li>Parque Hosp. Pediatrico</li>
							<li>Parque Briones Montoto</li>
							<li>Parque la Conchita</li>
							<li>Plaza Hermanos Cruz</li>
							<li>Parque Arroyo de Mantua</li>
							<li>Parque IPE Alameda</li>
							<li>Parque de la Audiencia</li>
							<li>Parque-10-Octubre-ARTEX</li>
							<li>Parque-Capitan-San-Luis</li>
							<li>Parque-Capó</li>
							<li>Parque-Pedagógico</li>
							<li>Parque Ferrocarriles</li>
							<li>Parque Celso Maragoto</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Consolación del Sur'">
						<h5>Consolación del Sur</h5>
						<ul>
							<li>Parque Antonio Maceo, Consolación del Sur</li>
							<li>Parque Entronque Herradura.</li>
							<li>Parque Herradura</li>
							<li>Parque Pilotos</li>
							<li>Parque Alonso Rojas</li>
							<li>Parque Puerta de Golpe</li>
							<li>Parque-Pueblo-Nuevo</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guane'">
						<h5>Guane</h5>
						<ul>
							<li>Parque 26 de Julio. Guane</li>
							<li>Parque Isabel Rubio</li>
							<li>Parque-Combate-Teneria</li>
							<li>Parque-Sabalo</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'La Palma'">
						<h5>La Palma</h5>
						<ul>
							<li>Restaurante y Parque La Palma</li>
							<li>Parque San Andrés</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Los Palacios'">
						<h5>Los Palacios</h5>
						<ul>
							<li>Pizzería 23 y 24 y Parque Los Palacios</li>
							<li>Parque San Diego de los Baños</li>
							<li>Parque La Guira</li>
							<li>Parque-Los-Palacios</li>
							<li>Parque-Paso-Quemado</li>
							<li>Parque-Paso-San-Diego</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Mantua'">
						<h5>Mantua</h5>
						<ul>
							<li>Cafeteria CIMEX y Parque Mantua</li>
							<li>Parque Arroyo de Mantua</li>
							<li>Parque-Dimas</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Minas de Matahambre'">
						<h5>Minas de Matahambre</h5>
						<ul>
							<li>Restaurante y Area El Cobre. Minas de Matahambre</li>
							<li>Parque Santa Lucia</li>
							<li>Parque Sumidero</li>
							<li>Parque Viejo Santa Lucía</li>
							<li>Emincar (Santa Lucía)</li>
							<li>Parque Pons</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Juan y Martínez'">
						<h5>San Juan y Martínez</h5>
						<ul>
							<li>Cafeteria La Esquinita/Parque Martín Herrera. San Juan</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Luis'">
						<h5>San Luis</h5>
						<ul>
							<li>Parque San Luis</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Sandino'">
						<h5>Sandino</h5>
						<ul>
							<li>Parque Centro Comercial Sandino</li>
							<li>Parque Manuel Lazo. Sandino</li>
							<li>Centro-Recreativo-Cortes</li>
							<li>Parque-Las-Martinas</li>
						</ul>
					</div>
					<div class="munic" *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Viñales'">
						<h5>Viñales</h5>
						<ul>
							<li>Parque Viñales</li>
							<li>Cafeteria Las Cubanitas. Viñales</li>
							<li>Parque Puerto Esperanza</li>
							<li>El Palenque. Viñales</li>
							<li>Bar de Tapas. Viñales</li>
							<li>Restaurante Las Brisas. Viñales</li>
							<li>Parque Marina Azcuy . Viñales
							</li>
							<li>El Moncada</li>
							<li>Parque República de Chile</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'AR'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Artemisa'">
						<h5>Artemisa</h5>
						<ul>
							<li>Parque Libertad (Iglesia)</li>
							<li>Boulevar Artemisa</li>
							<li>Parque PCC Municipal Artemisa</li>
							<li>Estadio-Artemisa</li>
							<li>Parque de Las cañas</li>
							<li>Parque Los Caballitos</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Alquízar'">
						<h5>Alquízar</h5>
						<ul>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Bauta'">
						<h5>Bauta</h5>
						<ul>
							<li>Parque Central de Bauta</li>
							<li>Playa Baracoa Parque</li>
							<li>BMTE, Baracoa</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Caimito'">
						<h5>Caimito</h5>
						<ul>
							<li>Parque Central Caimito</li>
							<li>CT Pueblo Nuevo Caimito</li>
							<li>Escuela de Cadetes</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guanajay'">
						<h5>Guanajay</h5>
						<ul>
							<li>Parque Central Guanajay</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Güira de Melena'">
						<h5>Güira de Melena</h5>
						<ul>
							<li>Boulevard Guira de Melena</li>
							<li>Parque Guira Melena</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Mariel'">
						<h5>Mariel</h5>
						<ul>
							<li>Parque Central</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Antonio de los Baños'">
						<h5>San Antonio de los Baños</h5>
						<ul>
							<li>Parque Camilo Cienfuegos</li>
							<li>Parque La Placita San Antonio</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Bahía Honda'">
						<h5>Bahía Honda</h5>
						<ul>
							<li>Casa de la Cultura</li>
							<li>Pizzería </li>
							<li>CT Silvio Caro</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Cristóbal'">
						<h5>San Cristóbal</h5>
						<ul>
							<li>Coopelia</li>
							<li>CT López Peña</li>
							<li>Fachada CTLP San Cristóbal</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Candelaria'">
						<h5>Candelaria</h5>
						<ul>
							<li>Parque Central Candelaria</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'LH'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Arroyo Naranjo'">
						<h5>Arroyo Naranjo</h5>
						<ul>
							<li>Pabellones Central y 14 EXPOCUBA</li>
							<li>Parque Santa Amalia, Arroyo Naranjo</li>
							<li>Parque de Caballero y Pizarro. Arroyo Naranjo</li>
							<li>Parque Isabel Sofia Parraga</li>
							<li>Parque Aristides Viera Rpto Eléctrico</li>
							<li>Parque de Lourdes y San Gregorio (Vibora-Park)</li>
							<li>Finca Alcona</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Boyeros'">
						<h5>Boyeros</h5>
						<ul>
							<li>Parque José Martí, Santiago de las Vegas. </li>
							<li>Parque de Calabazar. Boyeros</li>
							<li>Parque Wajay</li>
							<li>Parque Lugardita, Muncipio Boyeros</li>
							<li>Parque de la Iglesia. Managua</li>
							<li>Parque Río Cristal</li>
							<li>Complejo Recreatur Fontanar</li>
							<li>Parque en la zona de Altahabana</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Centro Habana'">
						<h5>Centro Habana</h5>
						<ul>
							<li>Parque Fe del Valle (Galeano y San Rafael)</li>
							<li>Parque Trillo</li>
							<li>Malecón. Exterior zona Hotel Deauville</li>
							<li>Malecón. Exterior Restaurant Castro Poll</li>
							<li>Parque Infanta y Salud. Municipio Centro Habana</li>
							<li>Parque Karl Marx. Centro Habana</li>
							<li>Boulevar-San-Rafael</li>
							<li>Parque Angeles-Estrella</li>
							<li>Parque La Pera</li>
							<li>Parque de los Martires-Centro-Hab</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cerro'">
						<h5>Cerro</h5>
						<ul>
							<li>Parque Estadio Latinoamericano, Cerro</li>
							<li>Parque Nguyen Van Troy </li>
							<li>Parque Palatino, Cerro</li>
							<li>Parque-Manila</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cotorro'">
						<h5>Cotorro</h5>
						<ul>
							<li>Parque 9 de Abril, Cotorro</li>
							<li>Parque de Santa Maria del Rosario. Cotorro</li>
							<li>Parque de Cuatro Caminos</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Diez de Octubre'">
						<h5>Diez de Octubre</h5>
						<ul>
							<li>Parque Mónaco, Diez de Octubre.</li>
							<li>Parque Cordóba. Diez de Octubre</li>
							<li>Parque Asunción.</li>
							<li>Parque Lawton. Calle B y 8. Diez de Octubre</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guanabacoa'">
						<h5>Guanabacoa</h5>
						<ul>
							<li>Parque Viondi. Guanabacoa</li>
							<li>Parque de los Martires de la Jata. Guanabacoa</li>
							<li>Parque consejo popular La Gallega</li>
							<li>Parque Nestor Aranguren (El Machaco)</li>
							<li>Parque Infantil Sta Fe Gbcoa</li>
							<li>Parque Infantil Dbeche</li>
							<li>Parque en el reparto Eduardo Chibás</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Habana del Este'">
						<h5>Habana del Este</h5>
						<ul>
							<li>Paseo de la Villa Panamericana</li>
							<li>Parque Hanoi, Alamar</li>
							<li>Centro Cultural Alamar</li>
							<li>Parque de Campo Florido. Habana del Este</li>
							<li>Parque de Guanabo. H. Este</li>
							<li>Complejo Morro Cabaña</li>
							<li>Parque Centro Comercial Camilo Cienfuegos</li>
							<li>Parque del Rpto Guiteras</li>
							<li>Hospital Naval</li>
							<li>Centro Comercial El-Progreso-Alamar</li>
							<li>Parque-La-Guayabera</li>
							<li>Parque Diversiones "José Martí"</li>
							<li>Rotonda-Guanabo</li>
							<li>Parque Ernest Hemingway</li>
							<li>Paseo de la Villa Panamerica (II )</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Habana Vieja'">
						<h5>Habana Vieja</h5>
						<ul>
							<li>Plaza Cristo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'La Lisa'">
						<h5>La Lisa</h5>
						<ul>
							<li>Parque de 51 en La Lisa</li>
							<li>Parque Punta Brava. La lisa</li>
							<li>Centro Comercial 31 y 240-S.Agustin</li>
							<li>Parque Cano Lisa</li>
							<li>Parque Valle Grande</li>
							<li>Parque-Elena-La-Lisa</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Marianao'">
						<h5>Marianao</h5>
						<ul>
							<li>Anfiteatro de Marianao </li>
							<li>Hospital Militar</li>
							<li>Parque 118 Anfiteatro Marianao</li>
							<li>Parque 51 y76</li>
							<li>Parque Marianao en 104 y 41 </li>
							<li>Parque-126-y-51</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Playa'">
						<h5>Playa</h5>
						<ul>
							<li>Centro Multiservicios Miramar</li>
							<li>Parque Coyula. 19 y 30, Playa</li>
							<li>Parque 13 y 76, Playa</li>
							<li>Balneario Universitario 1ra y 42. Playa. </li>
							<li>Recinto Ferial Pabexpo</li>
							<li>Palacio_Convenciones</li>
							<li>Teatro Karl Marx</li>
							<li>Laboratorio KCHO</li>
							<li>Parque Flores Playa</li>
							<li>Parque-Genetica-Playa</li>
							<li>Parque Juan Manuel Marquez Playa</li>
							<li>Parque Nautico Playa</li>
							<li>Parque Rincón Martiano</li>
							<li>Parque Lázaro Cárdenas Kolhy</li>
							<li>Parque Jaimanitas Playa</li>
							<li>Parque Japones Playa</li>
							<li>ParqueTropicana</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Plaza'">
						<h5>Plaza</h5>
						<ul>
							<li>La Rampa (Malecón hasta el cine Yara), </li>
							<li>Parque Línea y L, Plaza</li>
							<li>Parque Wilfredo Lam. Plaza</li>
							<li>Sucursal Cimex Sol y Mar, Malecón</li>
							<li>Malecón y Paseo</li>
							<li>La Piragua</li>
							<li>El Castillito</li>
							<li>Centro Tecnológico. Hola Ola</li>
							<li>Calle G y Malecón</li>
							<li>Malecón. Exterior Centro HispanoAmericano. </li>
							<li>Malecón. Zona Abadía</li>
							<li>Parque Coopelia</li>
							<li>Parque Linea y H. Municipio plaza</li>
							<li>Parque Abel Santa María. Municipo Plaza</li>
							<li>Pabellón Cuba</li>
							<li>Parque Clandestinidad</li>
							<li>Cacsa-La-Rampa</li>
							<li>Casa de Las-Américas</li>
							<li>Parque Wifredo Lam Vedado</li>
							<li>Cupet-23-y-Malecón</li>
							<li>ICRT-La Rampa</li>
							<li>PV CM Sala Navegación Internet 17 y A </li>
							<li>Parque La Piragua</li>
							<li>Parque Fuente de La Juventud</li>
							<li>Parque de 3ra y B</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Regla'">
						<h5>Regla</h5>
						<ul>
							<li>Parque Las Madres, Regla</li>
							<li>Parque-Arcoiris-Regla</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Miguel del Padrón'">
						<h5>San Miguel del Padrón</h5>
						<ul>
							<li>Parque José Martí, San Miguel del Padrón</li>
							<li>Parque La Curva. San Miguel</li>
							<li>Parque de Los Chivos( Manuel Ascunse) </li>
							<li>Parque Dolores</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'MY'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Güines'">
						<h5>Güines</h5>
						<ul>
							<li>Parque de Güines</li>
							<li>Parque Catalina de Guines</li>
							<li>El-Congo-Catalina</li>
							<li>Parque Micro-de-Guines</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Batabanó'">
						<h5>Batabanó</h5>
						<ul>
							<li>Parque Batabanó</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Bejucal'">
						<h5>Bejucal</h5>
						<ul>
							<li>Parque Bejucal</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jaruco'">
						<h5>Jaruco</h5>
						<ul>
							<li>Parque Jaruco</li>
							<li>Plaza Jaruco</li>
							<li>Parque-San-Antonio-Rio-Blanco</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Madruga'">
						<h5>Madruga</h5>
						<ul>
							<li>Parque de Madruga</li>
							<li>Parque Aguacate</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Melena del Sur'">
						<h5>Melena del Sur</h5>
						<ul>
							<li>Parque y Cuatro esquina. Melena del Sur</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Nueva Paz'">
						<h5>Nueva Paz</h5>
						<ul>
							<li>Parque Nueva Paz</li>
							<li>CT-Nueva Paz</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Quivicán'">
						<h5>Quivicán</h5>
						<ul>
							<li>Parque Quivican</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San José de las Lajas'">
						<h5>San José de las Lajas</h5>
						<ul>
							<li>Boulevard de San José de las Lajas</li>
							<li>Las Ruinas</li>
							<li>Parque Camilo, San José</li>
							<li>Tienda El Samurai</li>
							<li>Parque Tapaste</li>
							<li>Parque-San-Antonio-Las-Vegas</li>
							<li>Joven Club Parque San José</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Nicolás de Bari'">
						<h5>San Nicolás de Bari</h5>
						<ul>
							<li>Parque San Nicolás de Bari</li>
							<li>Correo-San-Nicolás</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Santa Cruz del Norte'">
						<h5>Santa Cruz del Norte</h5>
						<ul>
							<li>Parque y Malecón de Santa Cruz del Norte</li>
							<li>Rapido-Sta-Cruz-Norte</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'MZ'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Calimete'">
						<h5>Calimete</h5>
						<ul>
							<li>Parque municipio Calimete</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cárdenas'">
						<h5>Cárdenas</h5>
						<ul>
							<li>Parque José Antonio Echeverría, Cárdenas.</li>
							<li>Complejo Todo en Uno, Varadero</li>
							<li>Centro Convenciones Plaza América</li>
							<li>Plaza las Morlas.</li>
							<li>Centro Comercial Plaza Hicacos</li>
							<li>Parque Boca Camarioca. Varadero</li>
							<li>Centro-Artex-Varadero</li>
							<li>Parque-Colón-Cardenas</li>
							<li>Hotel Sol Palmeras</li>
							<li>Centro de la música Varadero</li>
							<li>Parque Santa Marta Varadero</li>
							<li>Hotel Meliá América, Varadero</li>
							<li>Parque Martí</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Ciénaga de Zapata'">
						<h5>Ciénaga de Zapata</h5>
						<ul>
							<li>Parque municipio Ciénaga de Zapata</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Colón'">
						<h5>Colón</h5>
						<ul>
							<li>Parque-La-Libertad-Colon</li>
							<li>Boulevar de Colón</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jagüey Grande'">
						<h5>Jagüey Grande</h5>
						<ul>
							<li>Parque Jaguey Grande</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jovellanos'">
						<h5>Jovellanos</h5>
						<ul>
							<li>Parque Jovellanos</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Limonar'">
						<h5>Limonar</h5>
						<ul>
							<li>Parque municipio Limonar</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Los Arabos'">
						<h5>Los Arabos</h5>
						<ul>
							<li>Parque municipio Los Arabos</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Martí'">
						<h5>Martí</h5>
						<ul>
							<li>Parque municipio Martí</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Matanzas'">
						<h5>Matanzas</h5>
						<ul>
							<li>Parque La Libertad </li>
							<li>Parque Peñas Altas </li>
							<li>Parque René Fraga</li>
							<li>Asociacion Cultural Artesanos</li>
							<li>Centro Restaurant-Bar-Cafeteria Plaza La Vigia</li>
							<li>Centro de Artes Escenicas </li>
							<li>Parque La Catedral</li>
							<li>Centro Tecnológico Narvaez</li>
							<li>Parque Recreativo Monserrate</li>
							<li>Paseo Martí</li>
							<li>Parque Guanima</li>
							<li>Instituto de Belleza</li>
							<li>Parque-La-Estrella</li>
							<li>Parque-Naranjal</li>
							<li>Centro Tecnológico Universidad Camilo Cienfuegos</li>
							<li>Sala de Turismo Hospital Faustino Pérez</li>
							<li>Universidad de Ciencias Médicas</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Pedro Betancourt'">
						<h5>Pedro Betancourt</h5>
						<ul>
							<li>Parque municipio Pedro Betancourt</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Perico'">
						<h5>Perico</h5>
						<ul>
							<li>Parque municipio Perico</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Unión de Reyes'">
						<h5>Unión de Reyes</h5>
						<ul>
							<li>Parque municipio Union Reyes</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'CF'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Abreus'">
						<h5>Abreus</h5>
						<ul>
							<li>Prado. Municipio Abreu</li>
							<li>CT-Yaguaramas-Abreu</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Aguada de Pasajeros'">
						<h5>Aguada de Pasajeros</h5>
						<ul>
							<li>Prado Aguada de Pasajero</li>
							<li>Ranchón Aguada Pasajero</li>
							<li>Primero de Mayo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cienfuegos'">
						<h5>Cienfuegos</h5>
						<ul>
							<li>Parque Martí </li>
							<li>Rápido Punta Gorda</li>
							<li>Parque Villuendas</li>
							<li>Parque Imago</li>
							<li>La Bolera</li>
							<li>Parque La Aduana</li>
							<li>CEN</li>
							<li>Librería Prado-San Fernando</li>
							<li>Correo-Rpto-Pastorita</li>
							<li>Parque-de-la-Circunvalacion</li>
							<li>RCM-Malecón</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cruces'">
						<h5>Cruces</h5>
						<ul>
							<li>Parque del Municipio Cruces</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cumanayagua'">
						<h5>Cumanayagua</h5>
						<ul>
							<li>Boulevar de Cumanayagua</li>
							<li>Barajagua-Cumanayagua</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Palmira'">
						<h5>Palmira</h5>
						<ul>
							<li>Parque de Palmira</li>
							<li>Prado-San-Fdo-Camarones</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Rodas'">
						<h5>Rodas</h5>
						<ul>
							<li>El Paseo del Prado, Rodas</li>
							<li>Parque-14-de-Julio</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Santa Isabel de las Lajas'">
						<h5>Santa Isabel de las Lajas</h5>
						<ul>
							<li>Prado del Municipio Santa Isabel de las Lajas</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'VC'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Caibarién'">
						<h5>Caibarién</h5>
						<ul>
							<li>Parque La libertad Caibarién</li>
							<li>Parque-Nguyen-Van-Troi</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Camajuaní'">
						<h5>Camajuaní</h5>
						<ul>
							<li>Boulevard Camajuaní</li>
							<li>Parque Central Vueltas</li>
							<li>Parque Vega Alta</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cifuentes'">
						<h5>Cifuentes</h5>
						<ul>
							<li>Parque Cifuentes</li>
							<li>Parque-San-Diego</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Corralillo'">
						<h5>Corralillo</h5>
						<ul>
							<li>Parque Corralillo</li>
							<li>Rancho Veloz</li>
							<li>La Panchita Corralillo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Encrucijada'">
						<h5>Encrucijada</h5>
						<ul>
							<li>Parque Encrucijada</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Manicaragua'">
						<h5>Manicaragua</h5>
						<ul>
							<li>Parque Manicaragua</li>
							<li>Parque-Guinia-Manicaragua</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Placetas'">
						<h5>Placetas</h5>
						<ul>
							<li>Parque Casallas Placetas</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Quemado de Güines'">
						<h5>Quemado de Güines</h5>
						<ul>
							<li>reas Policlínico Municipio Quemado de Guines</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Ranchuelo'">
						<h5>Ranchuelo</h5>
						<ul>
							<li>Parque Ranchuelo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Remedios'">
						<h5>Remedios</h5>
						<ul>
							<li>Parque-Buena-Vista</li>
							<li>Parque-Zulueta</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Sagua la Grande'">
						<h5>Sagua la Grande</h5>
						<ul>
							<li>Parque Sagua</li>
							<li>Parque Calabazar. Sagua</li>
							<li>Parque-Isabela de Sagua</li>
							<li>Rpto-26-Julio-Sagua</li>
							<li>Sitiecito-Sagua-la-Grande</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Santa Clara'">
						<h5>Santa Clara</h5>
						<ul>
							<li>Parques Leoncio Vidal </li>
							<li>Parque de Remedios</li>
							<li>Centro Multiservicio Salud</li>
							<li>Parque y Áreas del Estadio Sandino</li>
							<li>Parque de los Mártires.</li>
							<li>Reparto José Martí</li>
							<li>Conjunto Escultórico Memorial Ernesto Che Guevara</li>
							<li>Complejo Gastronómico Vista Hermosa</li>
							<li>Parque Temático Sandino</li>
							<li>Jardín Zoologico Camilo</li>
							<li>Parque Audiencia</li>
							<li>Biblioteca Provincial José Martí</li>
							<li>Patio Bar del centro Cultural ARTEX </li>
							<li>Centro Recreativo Los pinos</li>
							<li>Parque de los Columpios</li>
							<li>Parque-La-Esperanza</li>
							<li>Parque Maceo</li>
							<li>Rápido-8-de-Marzo</li>
							<li>Parque-Base-áerea</li>
							<li>Boulevard Santa Clara</li>
							<li>Círculo Juvenil Somos Jovenes</li>
							<li>Parque América Latina</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Santo Domingo'">
						<h5>Santo Domingo</h5>
						<ul>
							<li>Parque Santo Domingo</li>
							<li>Parque-Manacas</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'SS'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cabaigúan'">
						<h5>Cabaigúan</h5>
						<ul>
							<li>Parque Cabaiguan</li>
							<li>Paseo Cabaiguán</li>
							<li>Parque José Martí de Guayos</li>
							<li>Parque Santa Lucía</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Fomento'">
						<h5>Fomento</h5>
						<ul>
							<li>Parque Fomento</li>
							<li>Centro-Cultural-El-Pedrero</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jatibonico'">
						<h5>Jatibonico</h5>
						<ul>
							<li>Paseo Jatibonico</li>
							<li>Parque Arroyo Blanco</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'La Sierpe'">
						<h5>La Sierpe</h5>
						<ul>
							<li>Parque La Sierpe</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Sancti Spíritus'">
						<h5>Sancti Spíritus</h5>
						<ul>
							<li>Parque Serafín Sánchez</li>
							<li>Plaza Cultural Yayabo</li>
							<li>Paseo Colón</li>
							<li>Complejo Gastronómico Mar y Cielo</li>
							<li>Complejo Gastronómico Pío Lindo</li>
							<li>Casa de la Guayabera</li>
							<li>Paseo Norte I y II</li>
							<li>Paseo-Coppelia</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Taguasco'">
						<h5>Taguasco</h5>
						<ul>
							<li>Parque Taguasco</li>
							<li>Parque José Martí de Zaza del Medio</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Trinidad'">
						<h5>Trinidad</h5>
						<ul>
							<li>Parque Céspedes de Trinidad </li>
							<li>Parque-Casilda-Trinidad</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Yaguajay'">
						<h5>Yaguajay</h5>
						<ul>
							<li>Parque Yaguajay</li>
							<li>Parque Mayajigua</li>
							<li>Parque-Meneses</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'CA'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Ciro Redondo'">
						<h5>Ciro Redondo</h5>
						<ul>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Baraguá'">
						<h5>Baraguá</h5>
						<ul>
							<li>Paseo Gaspar</li>
							<li>Parque-Baraguá</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Bolivia'">
						<h5>Bolivia</h5>
						<ul>
							<li>Parque de Los Martires. Bolivia</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Chambas'">
						<h5>Chambas</h5>
						<ul>
							<li>Paseo de Chambas</li>
							<li>Paseo-Varona</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Ciego de Ávila'">
						<h5>Ciego de Ávila</h5>
						<ul>
							<li>Parque Martí </li>
							<li>Parque Máximo Gómez</li>
							<li>Paseo Calle D. Ciego de Ávila.</li>
							<li>Paseo Jicotea</li>
							<li>Estadio José Ramón Cepero</li>
							<li>Parque Edad de Oro</li>
							<li>Micro Ortiz</li>
							<li>Paseo-Calle-K</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Florencia'">
						<h5>Florencia</h5>
						<ul>
							<li>Boulevard de Florencia</li>
							<li>Paseo-Tamarindo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Majagua'">
						<h5>Majagua</h5>
						<ul>
							<li>Parque Marti Majagua</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Morón'">
						<h5>Morón</h5>
						<ul>
							<li>Parque Morón</li>
							<li>Parque de Las Madres, Morón</li>
							<li>Rápido Turiguanó</li>
							<li>Parque-Bautista</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Primero de Enero'">
						<h5>Primero de Enero</h5>
						<ul>
							<li>Bullevard. Primero de Enero</li>
							<li>Paseo-Velazco</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Venezuela'">
						<h5>Venezuela</h5>
						<ul>
							<li>Centro Cultural Auraca. Venezuela</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'CM'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Camagüey'">
						<h5>Camagüey</h5>
						<ul>
							<li>Parque Agramonte</li>
							<li>Plaza del Gallo. Segmento Calle República</li>
							<li>Plaza de los Trabajadores</li>
							<li>Centro Cultural Recreativo Casino (Sepmi)</li>
							<li>Complejo Mercado La Caridad</li>
							<li>Parque 28 de Septiembre. </li>
							<li>Complejo Lago de los Sueños</li>
							<li>Callejón de los Milagros. Segmento Calle Ignacio Agramonte</li>
							<li>Bosque Tecnológico. </li>
							<li>Asociación Hermanos Saiz Casino Campestre </li>
							<li>PV-TRD El Legendario. </li>
							<li>Parque del Amor.</li>
							<li>Centro de Convenciones Santa Cecilia</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Carlos Manuel de Céspedes'">
						<h5>Carlos Manuel de Céspedes</h5>
						<ul>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Esmeralda'">
						<h5>Esmeralda</h5>
						<ul>
							<li>Parque Esmeralda </li>
							<li>IPS-Turismo-Brasil</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Florida'">
						<h5>Florida</h5>
						<ul>
							<li>Parque Florida José Martí</li>
							<li>Asociación Hermanos Saiz Florida </li>
							<li>Academia de Ajedrez-Florida</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guaimaro'">
						<h5>Guaimaro</h5>
						<ul>
							<li>Parque Guaimaro Constitución</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jimaguayú'">
						<h5>Jimaguayú</h5>
						<ul>
							<li>Parque Jimaguayú</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Minas'">
						<h5>Minas</h5>
						<ul>
							<li>Parque Central de Minas Salvador Cisneros </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Najasa'">
						<h5>Najasa</h5>
						<ul>
							<li>Parque Najasa </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Nuevitas'">
						<h5>Nuevitas</h5>
						<ul>
							<li>Parque Maceo </li>
							<li>Parque Cañon </li>
							<li>Plaza Residencial Santa Lucía</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Santa Cruz del Sur'">
						<h5>Santa Cruz del Sur</h5>
						<ul>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Sibanicú'">
						<h5>Sibanicú</h5>
						<ul>
							<li>Parque Sibanicú</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Sierra de Cubitas'">
						<h5>Sierra de Cubitas</h5>
						<ul>
							<li>Parque Sola Sierra de Cubitas </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Vertientes'">
						<h5>Vertientes</h5>
						<ul>
							<li>Paseo de Vertientes</li>
							<li>Parque Central Vertientes</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'LT'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Amancio Rodríguez'">
						<h5>Amancio Rodríguez</h5>
						<ul>
							<li>Parque Las Madres. Amancio</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Colombia'">
						<h5>Colombia</h5>
						<ul>
							<li>Parque Las Madres. Colombia</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jesús Menéndez'">
						<h5>Jesús Menéndez</h5>
						<ul>
							<li>Parque de Jesús Menéndez</li>
							<li>Parque El Palín</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jobabo'">
						<h5>Jobabo</h5>
						<ul>
							<li>Parque José Martí. Jobabo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Las Tunas'">
						<h5>Las Tunas</h5>
						<ul>
							<li>Telepunto Las Tunas</li>
							<li>Plaza Martiana </li>
							<li>Tanque de Buena Vista.</li>
							<li>Parque Antonio Maceo</li>
							<li>Complejo las Antillas</li>
							<li>Casa Piedra</li>
							<li>Parador El Cornito</li>
							<li>Ranchón La Arboleda</li>
							<li>Zona Edificio 12 Plantas</li>
							<li>Terminal Omnibus Nacional</li>
							<li>Universidad VI Lenin</li>
							<li>Parque Temático</li>
							<li>Boullevard Buena Vista</li>
							<li>Parque Casa-de-la-Musica</li>
							<li>Ctro-Prov-Artes-Plasticas</li>
							<li>Mercado-Telegrafo</li>
							<li>Parque-Bartle</li>
							<li>UJC Municipal Las Tunas</li>
							<li>Plaza-Mercado-Rio</li>
							<li>Universidad Lenin Sede 1</li>
							<li>Parque Calle 7</li>
							<li>Parque Casa-Insolita</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Majibacoa'">
						<h5>Majibacoa</h5>
						<ul>
							<li>Parque Francisco Vega. Municipio Majibacoa</li>
							<li>Parque Las Parras</li>
							<li>Parque Omaja</li>
							<li>Parque Plaza-Vivienda</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Manatí'">
						<h5>Manatí</h5>
						<ul>
							<li>Parque Manatí</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Puerto Padre'">
						<h5>Puerto Padre</h5>
						<ul>
							<li>Parque Emiliano Salvador, Puerto Padre</li>
							<li>Parque José Martí. Puerto Padre</li>
							<li>Cremería El Rapidito. Puerto Padre</li>
							<li>Parque Antonio Guiteras. Tunas</li>
							<li>Parque Vazquez</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'GR'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Bartolomé Masó'">
						<h5>Bartolomé Masó</h5>
						<ul>
							<li>Parque Bartolomé Masó</li>
							<li>Parque Caney de las Mercedes</li>
							<li>Campismo La Sierrita</li>
							<li>Las Mercedes</li>
							<li>Providencia</li>
							<li>La Estrella</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Bayamo'">
						<h5>Bayamo</h5>
						<ul>
							<li>Parque Cespedes. Bayamo </li>
							<li>Boulevard Bayamo</li>
							<li>Parque del Amor</li>
							<li>Plaza de la Patria Bayamo</li>
							<li>Complejo Las Novedades</li>
							<li>Reparto El Valle</li>
							<li>Parque Camilo Cienfuegos</li>
							<li>Parque Ciro Redondo</li>
							<li>Pedro Pompa</li>
							<li>Retablo de los Héroes</li>
							<li>Biosaludable Polígono</li>
							<li>Parque El Horno</li>
							<li>Parque Latinoamericano</li>
							<li>Micro 5</li>
							<li>Las Caobas</li>
							<li>Casa de la Fiesta</li>
							<li>Expoferia Granma</li>
							<li>Cabaret Bayamo</li>
							<li>Parque Juan Clemente Zenea</li>
							<li>Parque Rosa la Bayamesa</li>
							<li>Restaurante La Cubana</li>
							<li>Parque Fco Vicente Aguilera</li>
							<li>TRD La Defensa</li>
							<li>Centro Cultural Mi Tumbao</li>
							<li>Café Serrano</li>
							<li>Terminal Omnibus Nacionales</li>
							<li>Restaurante El Luanda</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Buey Arriba'">
						<h5>Buey Arriba</h5>
						<ul>
							<li>Parque Ignacio Agramonte. Buey Arriba</li>
							<li>Plaza San Pablo Yao. Buey Arriba</li>
							<li>Parque Bueycito</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Campechuela'">
						<h5>Campechuela</h5>
						<ul>
							<li>Parque 24 de Febrero. Campechuela </li>
							<li>Parque San Ramon</li>
							<li>Parque Ceiba-Hueca</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cauto Cristo'">
						<h5>Cauto Cristo</h5>
						<ul>
							<li>Boulevard Cauto Cristo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guisa'">
						<h5>Guisa</h5>
						<ul>
							<li>Parque Calixto García. Guisa</li>
							<li>Jardín Botánico Cupaynicú</li>
							<li>Ctro Recreativo El Meson</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Jiguaní'">
						<h5>Jiguaní</h5>
						<ul>
							<li>Parque de Jiguaní</li>
							<li>Parque Santa Rita</li>
							<li>Parque WiFi Jiguaní</li>
							<li>Parque Cautillo</li>
							<li>Chaco Redondo </li>
							<li>Parque 13 de Marzo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Manzanillo'">
						<h5>Manzanillo</h5>
						<ul>
							<li>Parque Céspedes. Manzanillo</li>
							<li>Malecón Manzanillo </li>
							<li>Escuela Latinoamericana de Medicina</li>
							<li>Parque-ICP-Manzanillo</li>
							<li>Parque Valle Espín</li>
							<li>Terminal de Cruceros</li>
							<li>Calicito</li>
							<li>San Francisco</li>
							<li>Troya</li>
							<li>La Pesquera</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Media Luna'">
						<h5>Media Luna</h5>
						<ul>
							<li>Parque de los Martires. Media Luna</li>
							<li>Calle Nuevo Media Luna</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Niquero'">
						<h5>Niquero</h5>
						<ul>
							<li>Parque Niquero </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Pilón'">
						<h5>Pilón</h5>
						<ul>
							<li>Parque José Martí. Pilón</li>
							<li>Plaza Marea del Portillo</li>
							<li>Sevilla</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Río Cauto'">
						<h5>Río Cauto</h5>
						<ul>
							<li>Boulevard Río Cauto</li>
							<li>Parque Grito-de-Yara</li>
							<li>Parque Paseo-Cauto-Embarcadero</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Yara'">
						<h5>Yara</h5>
						<ul>
							<li>Parque Carlos Manuel de Céspedes. Yara</li>
							<li>Boulevard Yara</li>
							<li>Parque Veguitas</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'HO'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Antilla'">
						<h5>Antilla</h5>
						<ul>
							<li>Parque Antilla</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Báguanos'">
						<h5>Báguanos</h5>
						<ul>
							<li>Mercado Indutrial Tacajó </li>
							<li>Avenida Báguanos</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Banes'">
						<h5>Banes</h5>
						<ul>
							<li>Parque de Banes</li>
							<li>Deleyte</li>
							<li>Flamboyanes</li>
							<li>Parque-El-Bosque-Banes</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cacocum'">
						<h5>Cacocum</h5>
						<ul>
							<li>Cine Antonio Maceo. Cacocum</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Calixto García'">
						<h5>Calixto García</h5>
						<ul>
							<li>Parque Calixto García</li>
							<li>Estadio Calixto García</li>
							<li>Boulevard-Buenaventura. </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Cueto'">
						<h5>Cueto</h5>
						<ul>
							<li>Parque de Cueto</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Frank País'">
						<h5>Frank País</h5>
						<ul>
							<li>Parque Municipio Frank País</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Gibara'">
						<h5>Gibara</h5>
						<ul>
							<li>Plaza de la Cultura Gibara</li>
							<li>Parque Uñas. Gibara</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Holguín'">
						<h5>Holguín</h5>
						<ul>
							<li>Parque Julio de Peralta</li>
							<li>Parque infantil Rubén Bravo</li>
							<li>Loma de la Cruz</li>
							<li>Los Framboyanes. </li>
							<li>Club Bariay</li>
							<li>Reparto Emilio Bárcenas</li>
							<li>Parque el Quijote</li>
							<li>Parque San José</li>
							<li>Sitio Viviendas Militares</li>
							<li>Reparto Villanueva</li>
							<li>Patana Mayabe</li>
							<li>Castillito Mayabe</li>
							<li>Parrillada Mayabe</li>
							<li>Parque Clinica Manuel Angulo</li>
							<li>Expo-Holguín</li>
							<li>Boulevard-Holguín</li>
							<li>Terminal-Viazul</li>
							<li>Parque-1ro-de-Enero</li>
							<li>Plaza-La-Central</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Mayarí'">
						<h5>Mayarí</h5>
						<ul>
							<li>Parque Mayarí I</li>
							<li>Parque Mayarí</li>
							<li>Parque Guatemala. Mayarí</li>
							<li>Tienda el Minero Levisa. Mayarí</li>
							<li>Arroyo Seco. Mayarí</li>
							<li>Plaza Guaro. Mayarí</li>
							<li>Hotel 90 Habitaciones</li>
							<li>Parque Felton</li>
							<li>Alto-Cedro</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Moa'">
						<h5>Moa</h5>
						<ul>
							<li>Parque Las Auroras. Moa</li>
							<li>Parque Universidad-Moa</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Rafael Freyre'">
						<h5>Rafael Freyre</h5>
						<ul>
							<li>Boullevard Rafael Freyre</li>
							<li>Plaza Pesquero.</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Sagua de Tánamo'">
						<h5>Sagua de Tánamo</h5>
						<ul>
							<li>Sitio Sagua de Tánamo</li>
							<li>Calabaza de Sagua de Tánamo</li>
							<li>Naranjo Agrio. Sagua de Tánamo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Urbano Noris'">
						<h5>Urbano Noris</h5>
						<ul>
							<li>Boulevard San German. </li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'SC'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Contramaestre'">
						<h5>Contramaestre</h5>
						<ul>
							<li>Parque Central Baire</li>
							<li>Parque Central Contramaestre</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guamá'">
						<h5>Guamá</h5>
						<ul>
							<li>Parque Chivirico. </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Julio Antonio Mella'">
						<h5>Julio Antonio Mella</h5>
						<ul>
							<li>reas Emisora Radio Titán. Mella</li>
							<li>Plaza-Mella </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Palma Soriano'">
						<h5>Palma Soriano</h5>
						<ul>
							<li>Parque José Marti. Palma Soriano</li>
							<li>Parque Juvenil Palma Soriano</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Luis'">
						<h5>San Luis</h5>
						<ul>
							<li>Parque San Luis</li>
							<li>Parque Dos Caminos</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Santiago de Cuba'">
						<h5>Santiago de Cuba</h5>
						<ul>
							<li>Centro Multiservicios Parque Céspedes</li>
							<li>Centro Multiservicios Distrito Jose Martí</li>
							<li>Parques Céspedes</li>
							<li>Parque Ferreiro</li>
							<li>Plaza de Marte </li>
							<li>La Alameda</li>
							<li>Parque Palma Soriano</li>
							<li>Centro Tecnológico Enramada</li>
							<li>Enramada Final</li>
							<li>Areas Supermercado Versalles</li>
							<li>Galería René Valdés Cedeño (Caguayo)</li>
							<li>Cafetería ON </li>
							<li>Restaurante Siboney</li>
							<li>Centro Multiservicio Versalles</li>
							<li>Parque Cobre</li>
							<li>Consejo Popular Cristo</li>
							<li>Cafeteria NP El caney</li>
							<li>Centro urbano Abel Santa Maria</li>
							<li>Escuela Inter Armas Santiago de Cuba</li>
							<li>Parque Abel Santamaría</li>
							<li>Centro Comercial Distrito José Martí</li>
							<li>Cine Capitolio</li>
							<li>Cine el Cobre</li>
							<li>Parque Boniato</li>
							<li>Cine el Cristo</li>
							<li>FerroCaribe</li>
							<li>Anfiteatro</li>
							<li>CM-Distrito-José-Martí</li>
							<li>Cpjo-Recreativo-Algarrobos</li>
							<li>El Cayo</li>
							<li>La Claqueta Rialto</li>
							<li>Placita SantoTomás</li>
							<li>Parque Campo-X</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Segundo Frente'">
						<h5>Segundo Frente</h5>
						<ul>
							<li>reas de Biblioteca Enma Rosa Chuy. Mayarí. </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Songo la Maya'">
						<h5>Songo la Maya</h5>
						<ul>
							<li>Parque La Maya</li>
							<li>Parque Songo</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Tercer Frente'">
						<h5>Tercer Frente</h5>
						<ul>
							<li>reas Cine III Frente. Cruce de lo Baños</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'GT'">
				<div class="panel-body areas_wifi">
					<div class="munic" style="display: block;">
						<h5>Baracoa</h5>
						<ul>
							<li>Parque Central Baracoa</li>
							<li>Cafeteria El Parque</li>
							<li>Sitio Río Toa</li>
							<li>Parque El Jamal Baracoa</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Caimanera'">
						<h5>Caimanera</h5>
						<ul>
							<li>Parque Central Caimanera</li>
							<li>Area Boquerón </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'El Salvador'">
						<h5>El Salvador</h5>
						<ul>
							<li>Parque Municipio El Salvador</li>
							<li>Parque Costa Rica </li>
							<li>CT Carrera Larga</li>
							<li>CT Costa Rica</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Guantánamo'">
						<h5>Guantánamo</h5>
						<ul>
							<li>Parque Martí</li>
							<li>Avenida Camilo Cienfuegos.</li>
							<li>Parque Máximo Gómez/ Centro Comercial Caribe </li>
							<li>Complejo Comercial Reparto Obrero</li>
							<li>Parque 24 Febrero</li>
							<li>Plaza 28 de septiembre</li>
							<li>Plaza Pedro A. Pérez</li>
							<li>Parque Faustino Perez</li>
							<li>Parque Sur Isleta</li>
							<li>OBE San Justo</li>
							<li>Parque Complejo-Comercial-Caribe</li>
							<li>Parque La Majagua</li>
							<li>Parque San Rafael</li>
							<li>Sta Maria</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Imías'">
						<h5>Imías</h5>
						<ul>
							<li>Parque Imías</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Maisí'">
						<h5>Maisí</h5>
						<ul>
							<li>Parque La Máquina</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Manuel Tames'">
						<h5>Manuel Tames</h5>
						<ul>
							<li>Parque Municipio Manuel Tames</li>
							<li>Parque Jamaica </li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Niceto Pérez'">
						<h5>Niceto Pérez</h5>
						<ul>
							<li>Parque Municipio Niceto Pérez</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'San Antonio del Sur'">
						<h5>San Antonio del Sur</h5>
						<ul>
							<li>Parque San Antonio del Sur</li>
						</ul>
					</div>
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Yateras'">
						<h5>Yateras</h5>
						<ul>
							<li>Parque Municipio Yateras (Palenque)</li>
							<li>CT Felicidad</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="nauta-wifi-prov" *ngIf="selectedProvince == 'IJ'">
				<div class="panel-body areas_wifi">
					<div *ngIf="selectedMunicipio == 'Todos' || selectedMunicipio == 'Isla de la Juventud'">
						<h5>Isla de la Juventud</h5>
						<ul>
							<li>Boulevard Nueva Gerona.</li>
							<li>Parque Estudiantes /Cotorras </li>
							<li>Parque La Fe</li>
							<li>Parque El Lucero La Demajagua</li>
							<li>Parque Cine Caribe</li>
							<li>Parque Ahao</li>
							<li>Parque-La-Victoria</li>
							<li>Parque Delio Chacón</li>
							<li>Estadio Cristobal Labra</li>
							<li>Parque Ctro-Tecnologico-Recreativo</li>
							<li>Plaza marina Cayo largo del sur</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
