import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { PaypalService } from '../../services/paypal.service';
import { PaypalOnAuthorizeModel } from '../../../models/paypal/paypal-authorize.model';
import { PaypalOnCancelModel } from '../../../models/paypal/paypal-cancel.model';
import { PaypalReturnModel } from '../../../models/paypal/paypal-return.model';
import { AuthUserService } from '../../services/auth-user.service';
import { forEach } from 'lodash';
import { TopupModel } from '../../../models/topup-model';
import { ShoppingCartModel } from '../../../models/store/shopping-cart.model';

declare var paypal: any;

@Component({
	selector: 'mega-paypal',
	templateUrl: './paypal.component.html',
	styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit, OnChanges {
	@Input() source: string;
	@Input('refund-amount') refundAmount: number;
	@Input('topup-list') topupList: TopupModel[];
	@Input('shopping-cart') shoppingCart: ShoppingCartModel;

	@Output('onCancel') cancel: EventEmitter<PaypalReturnModel> = new EventEmitter<PaypalReturnModel>();
	@Output('onPaid') paid: EventEmitter<PaypalReturnModel> = new EventEmitter<PaypalReturnModel>();
	actions: any;

	env: string;
	_termConditions: boolean = false;

	get termConditions(): boolean {
		return this._termConditions;
	}

	set termConditions(val: boolean) {
		this._termConditions = val;
		this.CheckForValidity();
	}

	constructor(
		private paypalService: PaypalService,
		private authUserService: AuthUserService
	) { }

	ngOnInit() {
		this.paypalService
			.GetPaypalEnvironment()
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.env = response.data;
			})
			.catch(error => {
				this.env = 'production';
			})
			.then(e => {
				this.Init();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.CheckForValidity();
	}

	CheckForValidity(): void {
		if (this.source === 'topups' && !this.termConditions) {
			if (this.actions) {
				this.actions.disable();
				return;
			}
		}

		if (this.source === 'topups' && this.topupList.length === 0) {
			if (this.actions) {
				this.actions.disable();
				return;
			}
		}

		if (this.source === 'topups' && this.termConditions && this.topupList.length > 0) {
			if (this.actions) {
				this.actions.enable();
				return;
			}
		}

		if (this.source === 'funds') {
			if (this.actions) {
				this.actions.enable();
			}
		}

		if (this.source === 'checkout') {
			if (this.actions) {
				this.actions.enable();
			}
		}
	}

	Init(): void {
		paypal.Button.render({
			env: this.env,
			commit: true,
			locale: 'es_ES',
			style: {
				label: 'checkout',
				size: 'responsive',
				shape: 'rect',
				color: 'gold'
			},
			validate: (actions) => {
				this.actions = actions;

				if (this.source === 'topups') {
					actions.disable();
				}
			},
			onClick: () => {
				if (this.source === 'topups' && !this._termConditions) {
					const err = new PaypalReturnModel();
					err.success = false;
					err.errorMessage = 'Tiene que aceptar nuestros T&eacute;rminos y Condiciones antes de continuar.';
					this.cancel.emit(err);
					return;
				}

				if (this.source === 'topups' && this.topupList.length === 0) {
					const err = new PaypalReturnModel();
					err.success = false;
					err.errorMessage = 'Tiene que agregar al menos una recarga a la lista.';
					this.cancel.emit(err);
					return;
				}
			},
			payment: (actions, reject) => {
				if (this.source === 'funds') {
					return this.DoPaymentFunds()
						.catch(error => {
							reject(error);

							const err = new PaypalReturnModel();
							err.success = false;

							if (error.error === '1000' && error.has_error) {
								err.errorMessage = `Por motivos de seguridad no podemos permitir esta transacci&oacute;n.
							 Contacte nuestro Departamento de Servicio Al Cliente para mas informaci&oacute;n.`;
							} else {
								err.errorMessage = 'Hubo un error contactando Paypal. Por favor intente de nuevo.';
							}


							this.cancel.emit(err);
						});
				}

				if (this.source === 'checkout') {
					return this.DoPaymentCheckout()
						.catch(error => {
							reject(error);

							const err = new PaypalReturnModel();
							err.success = false;

							if (error.error === '1000' && error.has_error) {
								err.errorMessage = `Por motivos de seguridad no podemos permitir esta transacci&oacute;n.
							 Contacte nuestro Departamento de Servicio Al Cliente para mas informaci&oacute;n.`;
							} else {
								err.errorMessage = 'Hubo un error contactando Paypal. Por favor intente de nuevo.';
							}


							this.cancel.emit(err);
						});
				}

				return this.DoPaymentTopups()
					.catch(error => {
						reject(error);

						const err = new PaypalReturnModel();
						err.success = false;

						if (error.error === '1000' && error.has_error) {
							err.errorMessage = `Por motivos de seguridad no podemos permitir esta transacci&oacute;n.
							 Contacte nuestro Departamento de Servicio Al Cliente para mas informaci&oacute;n.`;
						} else {
							err.errorMessage = 'Hubo un error contactando Paypal. Por favor intente de nuevo.';
						}


						this.cancel.emit(err);
					});
			},
			onAuthorize: (model: PaypalOnAuthorizeModel, actions: any) => {
				if (this.source === 'funds') {
					return this.ProcessFundPayment(model);
				}
				if (this.source === 'checkout') {
					return this.ProcessCheckoutPayment(model);
				}


				// for topups
				return this.ProcessTopupPayment(model);
			},
			onCancel: (model: PaypalOnCancelModel, actions: any) => {
				return this.CancelFunds(model)
					.then(e => {
						this.cancel.emit();
					});
			},
			onError: function (error) {
				// console.log('onError', error);
			}
		}, '#paypalButton');
	}

	// Funds area
	DoPaymentFunds(): Promise<any> {
		return this.paypalService
			.GetPaymendIdFunds(Number(this.refundAmount))
			.then(response => {
				if (response.has_error) {
					throw response;
				}

				return response.data;
			});
	}

	ProcessFundPayment(model: PaypalOnAuthorizeModel): Promise<any> {
		return this.paypalService
			.ProcessFundPayment(model.paymentToken, model.payerID, model.paymentID)
			.then(response => {
				let errorMessage: string;

				if (response.has_error) {
					if (response.error !== '' && response.error_description !== '') {
						errorMessage = response.error + ': ' + response.error_description;
					} else {
						if (response.error === '2001') {
							errorMessage = `Error 2001: No se pudo recuperar la informaci&oacute;n del usuario
						 realizando el Pago. Por favor contacte nuestro departamento de Servicio al
						 Cliente si su transacci&oacute;n fue completada en su cuenta de Paypal.`;
						} else if (response.error === '2002') {
							errorMessage = `Error 2002: Este pago fue realizado por otro usuario.
						 Contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						} else if (response.error === '2003') {
							errorMessage = `Error 2003: Hubo un error en la informaci&oacute;n del Pago.
						 Por favor contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						} else {
							errorMessage = `Error Inesperado Hubo un error no esperado en su pago.
						 Por favor contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						}
					}

					const err = new PaypalReturnModel();
					err.success = false;
					err.errorMessage = errorMessage;
					this.cancel.emit(err);
					return;
				}

				if (response.error !== '' && response.error_description !== '') {
					errorMessage = `<b>${response.error}</b>! ${response.error_description}`;
				} else {
					errorMessage = `<b>Pago realizado satisfactoriamente!</b> Gracias por usar Megaconecta.`;
				}

				const resp = new PaypalReturnModel();
				resp.success = true;
				resp.errorMessage = errorMessage;
				this.paid.emit(resp);
			})
			.catch(error => {
				const err = new PaypalReturnModel();
				err.success = false;
				err.errorMessage = `Hubo un error no esperado en su pago. Por favor contacte nuestro
				 departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
				this.cancel.emit(err);
			});
	}

	CancelFunds(model: PaypalOnCancelModel): Promise<any> {
		return this.paypalService
			.CancelPayment(model.paymentToken);
	}

	// Checkout area
	DoPaymentCheckout(): Promise<any> {
		return this.paypalService
			.GetPaymentIdCheckout(this.shoppingCart)
			.then(response => {
				if (response.has_error) {
					throw response;
				}

				return response.data;
			});
	}

	ProcessCheckoutPayment(model: PaypalOnAuthorizeModel): Promise<any> {
		return this.paypalService
			.ProcessCheckoutPayment(model.paymentToken, model.payerID, model.paymentID, this.shoppingCart)
			.then(response => {
				let errorMessage: string;

				if (response.has_error) {
					if (response.error !== '' && response.error_description !== '') {
						errorMessage = response.error + ': ' + response.error_description;
					} else {
						if (response.error === '2001') {
							errorMessage = `Error 2001: No se pudo recuperar la informaci&oacute;n del usuario
						 realizando el Pago. Por favor contacte nuestro departamento de Servicio al
						 Cliente si su transacci&oacute;n fue completada en su cuenta de Paypal.`;
						} else if (response.error === '2002') {
							errorMessage = `Error 2002: Este pago fue realizado por otro usuario.
						 Contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						} else if (response.error === '2003') {
							errorMessage = `Error 2003: Hubo un error en la informaci&oacute;n del Pago.
						 Por favor contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						} else {
							errorMessage = `Error Inesperado Hubo un error no esperado en su pago.
						 Por favor contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						}
					}

					const err = new PaypalReturnModel();
					err.success = false;
					err.errorMessage = errorMessage;
					this.cancel.emit(err);
					return;
				}

				if (response.error !== '' && response.error_description !== '') {
					errorMessage = `<b>${response.error}</b>! ${response.error_description}`;
				} else {
					errorMessage = `<b>Pago realizado satisfactoriamente!</b> Gracias por usar Megaconecta.`;
				}

				const resp = new PaypalReturnModel();
				resp.success = true;
				resp.errorMessage = errorMessage;
				this.paid.emit(resp);
			})
			.catch(error => {
				const err = new PaypalReturnModel();
				err.success = false;
				err.errorMessage = `Hubo un error no esperado en su pago. Por favor contacte nuestro
				 departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
				this.cancel.emit(err);
			});
	}

	CancelCheckout(model: PaypalOnCancelModel): Promise<any> {
		return this.paypalService
			.CancelPayment(model.paymentToken);
	}

	// Topups area
	DoPaymentTopups(): Promise<any> {
		const data: any[] = [];
		forEach(this.topupList, item => {
			data.push({
				item_price: item.localAmount,
				service_cost: item.serviceCost,
				remote_amount: item.remoteAmount,
				operator: item.operatorUid,
				phone: item.contactRecharge,
				is_future: item.isFuture,
				offer_code: item.offerCode,
				discount: item.discount
			});
		});

		return this.paypalService
			.GetPaymentIdTopups(data)
			.then(response => {
				if (response.has_error) {
					throw response;
				}

				return response.data;
			});
	}

	ProcessTopupPayment(model: PaypalOnAuthorizeModel): Promise<any> {
		return this.paypalService
			.ProcessTopupPayment(model.paymentToken, model.payerID, model.paymentID)
			.then(response => {
				let errorMessage: string;

				if (response.has_error) {
					if (response.error !== '' && response.error_description !== '') {
						errorMessage = response.error + ': ' + response.error_description;
					} else {

						if (response.error === '2001') {
							errorMessage = `Error 2001: No se pudo recuperar la informaci&oacute;n del usuario realizando el Pago.
							 Por favor contacte nuestro departamento de Servicio al Cliente si su transacci&oacute;n fue completada en su cuenta de Paypal.`;
						} else if (response.error === '2002') {
							errorMessage = `Error 2002: Este pago fue realizado por otro usuario.
							 Contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						} else if (response.error === '2003') {
							errorMessage = `Error 2003: Hubo un error en la informaci&oacute;n del Pago.
							 Por favor contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						} else {
							errorMessage = `Error Inesperado Hubo un error no esperado en su pago.
							 Por favor contacte nuestro departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
						}
					}

					const err = new PaypalReturnModel();
					err.success = false;
					err.errorMessage = errorMessage;
					this.cancel.emit(err);
					return;
				}

				if (response.error !== '' && response.error_description !== '') {
					errorMessage = `<b>${response.error}</b>! ${response.error_description}`;
				} else {
					errorMessage = `<b>Pago realizado satisfactoriamente!</b> Gracias por usar Megaconecta.`;
				}

				const resp = new PaypalReturnModel();
				resp.success = true;
				resp.errorMessage = errorMessage;
				this.paid.emit(resp);
			})
			.catch(error => {
				const err = new PaypalReturnModel();
				err.success = false;
				err.errorMessage = `Hubo un error no esperado en su pago. Por favor contacte nuestro
				 departamento de Servicio al Cliente para m&aacute;s informaci&oacute;n.`;
				this.cancel.emit(err);
			});
	}
}
