import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,  Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
	selector: 'mega-account-menu',
	templateUrl: './account-menu.component.html',
	styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
	myList: any[];
	isLogged = false;

	constructor(
		private localStorageService: LocalStorageService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) { }

	ngOnInit() {		
		const token = JSON.parse(this.localStorageService.get('token'));
		const user = JSON.parse(this.localStorageService.get('mega_data'));

		const can = !!token && !!token.accessToken && !!user && !!user.isAuthenticated && !!user.user;

		this.isLogged = can;

		this.myList = [
			{ title: 'mi <br class="d-md-block d-sm-none d-lg-none d-none">perfil', link: '/account', showActive: false },
			{ title: 'agregar <br class="d-md-block d-sm-none d-lg-none d-none">fondos', link: '/account/add-funds', showActive: false },
			{ title: 'enviar <br class="d-md-block d-sm-none d-lg-none d-none">recargas', link: '/account/topups', showActive: false },
			{ title: 'recargar <br class="d-md-block d-sm-none d-lg-none d-none">nauta', link: '/account/nauta', showActive: false  },
			{ title: 'CUBACEL <br class="d-md-block d-sm-none d-lg-none d-none">datos', link: '/account/data-packages', showActive: false  },			
			{ title: 'combos <br class="d-md-block d-sm-none d-lg-none d-none">de comida', link: '/store', showActive: false  },
			{ title: 'enviar <br class="d-md-block d-sm-none d-lg-none d-none">sms', link: '/account/sms', showActive: false },
			{ title: 'mis <br class="d-md-block d-sm-none d-lg-none d-none">reportes', link: '/account/reports', showActive: false },
			{ title: 'referir <br class="d-md-block d-sm-none d-lg-none d-none">amigos', link: '/account/refer-friend', showActive: false }
			
		];

		for (let i = 0; i < this.myList.length; i++) {
			if (this.router.url==this.myList[i].link){
				this.myList[i].showActive = true;	
			}
		}
		if (this.router.url.indexOf('/store')>-1){
			this.myList[5].showActive = true;	
		}
	}

}
