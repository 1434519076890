<div class="container mg-carousel-container">
	<ngb-carousel *ngIf="slides.length > 1">
		<ng-template ngbSlide *ngFor="let image of slides">
			<div class="row mg-carousel-wrapper ">
				<div class="col-12 col-sm-12 col-md-6 d-flex text-center">
					<img [src]="image.IMAGE" class="ml-auto">
				</div>
				<div class="col-12 col-sm-12 col-md-6 d-flex mega-caption">
					<div class="align-self-center w-100">
						<h1 [innerHTML]="image.HEADER" class="d-none d-sm-none d-md-block d-lg-block"></h1>
						<p [innerHTML]="image.DESCRIPTION" class="d-none d-sm-none d-md-block d-lg-block"></p>

						<h1 [innerHTML]="image.HEADER_MOBILE" *ngIf="!!image.HEADER_MOBILE" class="d-sm-block d-md-none"></h1>
						<p [innerHTML]="image.DESCRIPTION_MOBILE" *ngIf="!!image.DESCRIPTION_MOBILE" class="d-sm-block d-md-none"></p>

						<button *ngIf="image.HAS_BUTTON == 1" class="btn text-spacing px-5 py-3 btn-lg" [ngClass]="image.BUTTON_CLASS" [routerLink]="image.BUTTON_LINK">
							<span [innerHTML]="image.BUTTON_TITLE"></span>
						</button>
					</div>
				</div>
			</div>
		</ng-template>
	</ngb-carousel>
</div>
