import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'EscapeHtml',
	pure: false
})
export class EscapeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {
	}

	transform(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}
}
