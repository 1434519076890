<mega-page-title title="Ayuda"></mega-page-title>

<div class="container mg-top-title mg-help-page">
	<div class="row">
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
		<div class="col-lg-8 col-md-12">
			<p class="mb-4">
				En los siguientes videos u.d. encontrá los pasos necesarios para realizar cualquiera de las funciones más comunes de nuestro sitio web. Si lo desea puede aumentar el tamaño del video
				haciendo click en el icono en el extremo inferior derecho.
			</p>

			<sui-accordion [closeOthers]="true">
				<sui-accordion-panel [isOpen]="false" *ngFor="let video of helpItems">
					<div title>
						<i class="fa fa-play-circle-o" aria-hidden="true"></i>
						<div [innerHTML]="video.text"></div>
						<i class="dropdown icon"></i>
					</div>
					<div content>
						<youtube-player [videoId]="video.video" width="100%" height="300"></youtube-player>
					</div>
				</sui-accordion-panel>
			</sui-accordion>
		</div>
		<div class="col-lg-2 d-lg-flex d-md-none"></div>
	</div>
</div>


<mega-social-media></mega-social-media>
