import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ResultModel } from '../../../models/result.model';

@Injectable()
export class FooterService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	AddSubscriptionEmail(email: string): Promise<ResultModel<any>> {
		const params = new HttpParams();
		params.set('email', email);

		return this.httpClient
			.get<ResultModel<any>>(`${this.baseUrl}api/register/add_subscription_email`, { params: params })
			.toPromise();
	}
}
