import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { I18nService } from './i18n.service';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
	I18N_VALUES: any = {
		'es': {
			weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
			months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
			fullMonths: [
				'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
				'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
			],
		}
	};

	constructor(
		private i18nService: I18nService
	) {
		super();
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}

	getWeekdayShortName(weekday: number): string {
		return this.I18N_VALUES[this.i18nService.language].weekdays[weekday - 1];
	}

	getMonthShortName(month: number): string {
		return this.I18N_VALUES[this.i18nService.language].months[month - 1];
	}

	getMonthFullName(month: number): string {
		return this.I18N_VALUES[this.i18nService.language].fullMonths[month - 1];
	}
}
