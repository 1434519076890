import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { get } from 'lodash';
import { AuthUserService } from './shared/services/auth-user.service';
import { LocalStorageService } from 'ngx-localstorage';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Meta } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';


@Component({
	selector: 'mega-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
	title = 'mega';
	isVisibleSideBar: boolean = false;
	links: any[];
	linksAccount: any[];

	template: string = `
		<div class="loader loader-default is-active"></div>`;

	constructor(
		private router: Router,
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private localStorageService: LocalStorageService,
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
		private authUserService: AuthUserService,
		private meta: Meta
	
	) {
		
	}

	ngAfterViewInit() {
		// tslint:disable-next-line:no-unused-expression
		/*new SmartBanner({
			daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
			daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
			appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
			title: 'Megaconecta',
			author: 'Mega Connect Corp',
			button: 'VIEW',
			store: {
				ios: 'On the App Store',
				android: 'In Google Play'
			},
			price: {
				ios: 'FREE',
				android: 'FREE'
			},
			// , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
			// , icon: '' // full path to icon image if not using website icon image
			// force: 'ios' // Uncomment for platform emulation
		});*/
	}

	ngOnInit(): void {
		this.links = [
			{ name: 'INICIO', link: '/' },
			{ name: 'SERVICIOS', link: 'services' },
			{ name: 'TARIFAS', link: 'rates' },
			{ name: 'NUMEROS DE ACCESSO', link: 'access-numbers' },
			{ name: 'COMBOS DE COMIDA', link: 'store' },
			{ name: 'CONTACTO', link: 'contact' },
			{ name: 'AYUDA', link: 'help' }
		];

		this.linksAccount = [
			{ name: 'mi perfil', link: '/account' },
			{ name: 'agregar fondos', link: '/account/add-funds' },
			{ name: 'enviar recargas', link: '/account/topups' },
			{ name: 'recargar nauta', link: '/account/nauta1' },
			{ name: 'paquetes de datos', link: '/account/data-packages' },
			{ name: 'enviar sms', link: '/account/sms' },
			{ name: 'mis reportes', link: '/account/reports' },
			{ name: 'referir amigos', link: '/account/refer-friend' }			
		];

		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this.activatedRoute),
			map((route) => {
				while (route.firstChild) { route = route.firstChild; }
				return route;
			}),
			filter((route) => route.outlet === 'primary'),
			mergeMap((route) => route.data)
		)
			.subscribe((event) => {

				if (this.isAuthenticated()) {
					const serno = this.getUserSerno();
					
				}

			

				window.scrollTo(0, 0);
				let titlePage = get(event, 'title', '') + ' | Megaconecta';				
				if (titlePage == ' | Megaconecta'){
					titlePage='MegaConecta: Recargas y LLamadas A Cuba';
				}
				this.titleService.setTitle(titlePage);

				if (event.seo) {
				//	this.meta.removeTag(`name='keywords'`);
					//this.meta.removeTag(`name='description'`);
					//this.meta.removeTag(`property='og:description'`);
					//this.meta.removeTag(`name='twitter:description'`);
					//this.meta.removeTag(`itemprop='description'`);

					//this.meta.addTag({ name: 'keywords', content: event.seo.keywords });
					//this.meta.addTag({ name: 'description', content: event.seo.description });
					//this.meta.addTag({ property: 'og:description', content: event.seo.description });
					//this.meta.addTag({ name: 'twitter:description', content: event.seo.description });
					//this.meta.addTag({ itemprop: 'description', content: event.seo.description });
				}

				this.titleService.setTitle(titlePage);

				this.meta.removeTag(`property='og:title'`);
				this.meta.removeTag(`name='twitter:title'`);
				this.meta.removeTag(`itemprop='name'`);
				this.meta.removeTag(`property='og:url'`);

				this.meta.addTag({ property: 'og:title', content: titlePage });
				this.meta.addTag({ property: 'og:url', content: window.location.href });
				this.meta.addTag({ name: 'twitter:title', content: titlePage });
				this.meta.addTag({ itemprop: 'name', content: titlePage });
			});

		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}

			if (this.isVisibleSideBar) {
				this.isVisibleSideBar = false;
			}
		});
	}

	isAuthenticated(): boolean {
		const data = JSON.parse(this.localStorageService.get('mega_data'));

		return !!data && !!data.isAuthenticated && !!data.user;
	}

	getUserName(): string {
		const data = JSON.parse(this.localStorageService.get('mega_data'));

		if (data && data.user) {
			return data.user.firstname;
		}

		return '';
	}

	getUserSerno(): string {
		const data = JSON.parse(this.localStorageService.get('mega_data'));

		if (data && data.user) {
			return data.user.serno;
		}

		return '';
	}

	logOut(event: any): void {
		event.preventDefault();

		this.ng4LoadingSpinnerService.show();
		this.authUserService.LogOut(true);
		this.ng4LoadingSpinnerService.hide();
	}
}
