import {
	Directive,
	ElementRef
} from '@angular/core';

import * as Payment from 'payment';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[ccExp]'
})
export class CCExpiryFormatDirective {

	constructor(private el: ElementRef) {
		const element = this.el.nativeElement;

		// call lib functions
		Payment.formatCardExpiry(element);
		Payment.restrictNumeric(element);
	}

}
