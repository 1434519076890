<form name="cardForm" #cardForm="ngForm" [formGroup]="myForm" (ngSubmit)="SubmitCardForm(cardForm)" novalidate *ngIf="isVisible">
    <div class="form-row">
        <div class="form-group col-12" *ngIf="isEdit && !!errorMessage">
            <div class="alert alert-danger" [innerHTML]="errorMessage"></div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12">
            <h5>Datos de la Tarjeta</h5>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12" [ngClass]="{'has-error':!isEdit && !myForm.controls.cardNumber.valid && myForm.controls.cardNumber.touched}">
            <label for="cc-number" class="required">N&uacute;mero de la Tarjeta</label>
            <input class="form-control" id="cc-number" [disabled]="isEdit" name="cardNumber" type="tel" autocomplete="cc-number" ccNum [formControl]="myForm.controls.cardNumber">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!isEdit && myForm.controls.cardNumber.errors?.required && myForm.controls.cardNumber.touched">
                El N&uacute;mero de la Tarjeta es requerido.
            </div>
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!isEdit && myForm.controls.cardNumber.errors?.error == 'CREDIT_CARD_INVALID' && !myForm.controls.cardNumber.errors?.required && myForm.controls.cardNumber.touched">
                El N&uacute;mero de la Tarjeta no es v&aacute;lido.
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!myForm.controls.expCard.valid && myForm.controls.expCard.touched}">
            <label for="cc-exp" class="required">Fecha de Expiraci&oacute;n</label>
            <input class="form-control" id="cc-exp" placeholder="MM / YY" maxlength="7" name="expCard" type="tel" autocomplete="cc-exp" ccExp [formControl]="myForm.controls.expCard">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="myForm.controls.expCard.errors?.required && myForm.controls.expCard.touched">
                La Fecha de Expiraci&oacute;n es requerida.
            </div>
            <div class="alert alert-danger mt-2 mb-0" *ngIf="myForm.controls.expCard.errors?.error == 'CREDIT_EXPIRY_INVALID' && !myForm.controls.expCard.errors?.required && myForm.controls.expCard.touched">
                La Fecha de Expiraci&oacute;n tiene que ser v&aacute;lida y en futuro.
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!myForm.controls.cvc.valid && myForm.controls.cvc.touched}">
            <label for="cc-cvc" class="required">C&oacute;digo de Seguridad (CVC)</label>
            <input class="form-control" id="cc-cvc" name="cvc" type="tel" autocomplete="cc-cvc" ccCvc [formControl]="myForm.controls.cvc">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="myForm.controls.cvc.errors?.required && myForm.controls.cvc.touched">
                El C&oacute;digo de Seguridad es requerido.
            </div>
            <div class="alert alert-danger mt-2 mb-0" *ngIf="(!myForm.controls.cvc.errors?.required && myForm.controls.cvc.errors?.error == 'CREDIT_CARD_INVALID') && myForm.controls.cvc.touched">
                El C&oacute;digo de Seguridad tiene que tener entre 3 y 4 caracteres.
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12">
            <h5>Datos del Propietario</h5>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="loadAccountInfo($event)">
                <label class="custom-control-label" for="customCheck1">Usar la Informaci&oacute;n de mi cuenta</label>

                <sui-dimmer class="inverted" [isDimmed]="!isCompletedLoading" [isClickable]="false">
                    <div class="ui text loader">Cargando...!</div>
                </sui-dimmer>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!isEdit && !myForm.controls.name.valid && myForm.controls.name.touched}">
            <label for="nombre" class="required">nombre</label>
            <input type="text" [disabled]="isEdit" class="form-control" id="nombre" name="nombre" [formControl]="myForm.controls.name">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!isEdit && !myForm.controls.name.valid && myForm.controls.name.touched">
                El Nombre es requerido.
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!isEdit && !myForm.controls.lastname.valid && myForm.controls.lastname.touched}">
            <label for="apellido" class="required">apellido(s)</label>
            <input type="text" [disabled]="isEdit" class="form-control" id="apellido" name="apellido" [formControl]="myForm.controls.lastname">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!isEdit && !myForm.controls.lastname.valid && myForm.controls.lastname.touched">
                Los Apellidos son requeridos.
            </div>
        </div>
    </div>
    <div class="form-row" >
        <div class="form-group col-12">
            <label for="nombre" class="required">pais</label>
            <ng-select #country formControlName="country" [clearable]="false" placeholder="Seleccione..." >
                <ng-option *ngFor="let item of countries" [value]="item.countcod">
                    <i class="flag" [ngClass]="item.countcod | lowercase"></i>{{item.countname}} (+{{item.countprefix}})
                </ng-option>
            </ng-select>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12" [ngClass]="{'has-error':!myForm.controls.address.valid && myForm.controls.address.touched}">
            <label for="address" class="required">Direcci&oacute;n</label>
            <input type="text" class="form-control" id="address" name="address" [formControl]="myForm.controls.address">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!myForm.controls.address.valid && myForm.controls.address.touched">
                La Direcci&oacute;n es requerida.
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!myForm.controls.city.valid && myForm.controls.city.touched}">
            <label for="city" class="required">Ciudad</label>
            <input type="text" class="form-control" id="city" name="city" [formControl]="myForm.controls.city">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!myForm.controls.city.valid && myForm.controls.city.touched">
                La Ciudad es requerida.
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!myForm.controls.state.valid && myForm.controls.state.touched}">
            <label for="estado" class="required">Estado</label>
            <input type="text" class="form-control" id="estado" name="estado" [formControl]="myForm.controls.state">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!myForm.controls.state.valid && myForm.controls.state.touched">
                El Estado es requerido.
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12" [ngClass]="{'has-error':!myForm.controls.zip.valid && myForm.controls.zip.touched}">
            <label for="zip" class="required">C&oacute;digo Postal</label>
            <input type="text" class="form-control" id="zip" name="zip" [formControl]="myForm.controls.zip">
            <div class="alert alert-danger mt-2 mb-0" *ngIf="myForm.controls.zip.errors?.required && myForm.controls.zip.touched">
                El C&oacute;digo Postal es requerido.
            </div>
            <div class="alert alert-danger mt-2 mb-0" *ngIf="!myForm.controls.zip.errors?.required && !myForm.controls.zip.valid && myForm.controls.zip.touched">
                El C&oacute;digo Postal no v&aacute;lido.
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12 d-md-flex d-sm-none d-none"></div>
    </div>
    <div class="form-row" *ngIf="!isEdit">
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="saveCcAccount" [(ngModel)]="saveCcAccount" [ngModelOptions]="{standalone: true}">
                <label class="custom-control-label" for="saveCcAccount">Salvar la tarjeta en mi cuenta</label>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12 d-md-flex d-sm-none d-none"></div>
    </div>

    <div class="form-row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <button type="button" class="btn btn-outline-dark btn-block" (click)="Cancel()">Cancelar</button>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <button type="button" class="btn btn-primary text-white btn-block" (click)="cardForm.ngSubmit.emit()">
				{{isEdit || source == 'checkout' ? 'Salvar' : 'Pagar'}}
			</button>
        </div>
    </div>
</form>