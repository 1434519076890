import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { PagerComponent } from './components/pager/pager.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginAuthGuard } from './guards/login-auth.guard';
import { FooterComponent } from './components/footer/footer.component';
import { FooterService } from './components/footer/footer.service';
import { CountryService } from './services/country.service';
import { AuthUserService } from './services/auth-user.service';
import { MaskCursorDirective } from './directives/mask-cursor.directive';
import { LimitToDirective } from './directives/limit-to.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyIntegerDirective } from './directives/only-integer.directive';
import { PaypalService } from './services/paypal.service';
import { PaypalComponent } from './components/paypal/paypal.component';
import { CreditCardsComponent } from './components/credit-cards/credit-cards.component';
import { CreditCardService } from './services/credit-card.service';
import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CreateCreditCardComponent } from './components/credit-cards/create-credit-card.component';
import { I18nService } from './services/i18n.service';
import { CustomDatepickerI18n } from './services/custom-datepicker-i18n.service';
import { CustomNgbDateParserFormatter } from './services/custom-datepicker-formatter.service';
import { ContactService } from './services/contact.service';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselService } from './services/carousel.service';
import { ImgCarouselService } from './services/img-carousel.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AdminAccessGuard } from './guards/admin-access.guard';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { RouteTransformDirective } from './directives/route-transform.directive';
import { SuiModule } from 'ng2-semantic-ui';
import { AutoDismissAlertsDirective } from './directives/auto-dismiss-alerts.directive';
import { NgXCreditCardsModule } from './library/ngx-credit-cards/ngx-credit-cards.module';
import { DisableControlDirective } from './directives/disable-control.directive';
import { ImgCarouselComponent } from './components/img-carousel/img-carousel.component';
import { ShoppingCartService } from './services/shopping-cart.service';
import { LocationService } from './services/location.service';
import { StorePaymentService } from './services/storepayment.service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		SuiModule,
		NgXCreditCardsModule,
		NgSelectModule
	],
	declarations: [
		HeaderComponent,
		PageTitleComponent,
		SocialMediaComponent,
		AccountMenuComponent,
		PagerComponent,
		FooterComponent,
		MaskCursorDirective,
		LimitToDirective,
		OnlyNumberDirective,
		OnlyIntegerDirective,
		PaypalComponent,
		CreditCardsComponent,
		CreateCreditCardComponent,
		PhoneFormatPipe,
		EscapeHtmlPipe,
		CurrencyFormatPipe,
		CarouselComponent,
		RouteTransformDirective,
		AutoDismissAlertsDirective,
		DisableControlDirective,
		ImgCarouselComponent
	],
	providers: [
		LoginAuthGuard,
		AdminAccessGuard,
		FooterService,
		CountryService,
		LocationService,
		StorePaymentService,
		AuthUserService,
		PaypalService,
		CreditCardService,
		I18nService,
		ContactService,
		CarouselService,
		ImgCarouselService,
		NgbCarouselConfig,
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
		{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }
	],
	exports: [
		HeaderComponent,
		PageTitleComponent,
		SocialMediaComponent,
		AccountMenuComponent,
		PagerComponent,
		FooterComponent,
		MaskCursorDirective,
		LimitToDirective,
		OnlyNumberDirective,
		OnlyIntegerDirective,
		PaypalComponent,
		CreditCardsComponent,
		CarouselComponent,
		ImgCarouselComponent,
		PhoneFormatPipe,
		EscapeHtmlPipe,
		CurrencyFormatPipe,
		RouteTransformDirective,
		AutoDismissAlertsDirective,
		NgXCreditCardsModule,
		DisableControlDirective
	]
})
export class SharedModule { }
