import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-recargas-cubacel',
	templateUrl: './recargas-cubacel.component.html',
	styleUrls: ['./recargas-cubacel.component.scss']
})
export class RecargasCubacelComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
