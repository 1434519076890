import { ProductCartModel } from './product-cart.model';
import { DeliveryContactModel } from './delivery-contact.model';
import { CreditCardModel } from '../credit-card.model';
import { ProductDeliveryModel } from './product-delivery.model';

export class ShoppingCartModel {

	productsInCart: ProductCartModel[];
	totalBeforeShipping: number = 0;
	totalAfterShipping: number = 0;
	numItemsCart: number = 0;
	selectedCreditCard: string = '';
	selectedPaymentMethod: string = '1';
	selectedDeliveryType: string = '1';
	selectedDeliverySpeed: string = '';
	deliveryOptions: ProductDeliveryModel;
	deliveryContact: DeliveryContactModel;
	newCreditCard: CreditCardModel;
	saveCcAccount: boolean = false;

	constructor() {

		this.productsInCart = [];
		this.deliveryOptions = new ProductDeliveryModel;
		this.deliveryContact = new DeliveryContactModel;
		this.newCreditCard = new CreditCardModel;
	}

}
