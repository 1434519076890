<mega-page-title title="¿Qui&eacute;nes Somos?"></mega-page-title>

<div class="container mg-top-title">
	<div class="row">
		<div class="col">
			<p>
				<b class="text-primary">MegaConecta</b> es una compa&ntilde;&iacute;a con el proposito de ofrecer la mas alta calidad de servicios telef&oacute;nicos tanto a sus clientes individuales como corporativos.
				Desde su creacion,
				<b class="text-primary">MegaConecta</b> a proveido a sus clientes individuales y corporativos en todo el pais, un servicio de telecomunicaciones de confianza y con los precios mas bajos del mercado, usando
				tecnologias y redes de comunicacion de primer nivel. Contando con innovaciones tecnologicas constantes, una filosofia enfocada en el servicio a nuestros clientes, y confianza de
				nuestros clientes por casi cinco a&ntilde;os,
				<b class="text-primary">MegaConecta</b> esta muy bien posicionada para ofrecer los mejores servicios de telecomunicaciones a sus clientes, especializandose tanto en el cliente individual como el corporativo.
			</p>
			<p>
				<b class="text-primary">MegaConecta</b> esta comprometido para darle un buen servicio con honradez, nos sentimos honrados de tenerlo como nuestro cliente y haremos lo que este a nuestro alcance para que
				se quede con nosotros muchos tiempo mas. D&iacute;a a d&iacute;a hacemos un esfuerzo por servirle mejor, por esta raz&oacute;n, pedimos su ayuda con sugerencias e ideas de modo
				tal, que la compa&ntilde;&iacute;a que construimos entregue el servicio que usted merece. Escr&iacute;banos en cualquier momento a ayuda@MegaConecta.com.
			</p>
			<p>
				Llame con
				<b class="text-primary">MegaConecta</b> y tendra las mejores tarifas y servicio en llamadas internacionales a todos los paises del mundo. Nuestro servicio funciona en mobile, oficina y los telefonos de
				linea fija, con solo marcar un numero de acceso y el numero internacional de destino al que desea llamar. Reg&iacute;strese con nosotros.
			</p>

			<p>
				Si utiliza
				<b class="text-primary">MegaConecta</b> recargar los móviles de sus familiares o seres queridos es muy fácil. Regístrese con nosotros de forma totalmente gratuita y empieze a utilizar el servicio de recarga
				a un precio excelente. Nuestros clientes son notificados inmediatamente con las mejores ofertas de recargas que muchas veces duplican el monto recargado.

			</p>

			<h3>Ventajas de Nuestros Servicios.</h3>

			<div class="row">
				<div class="col-12 col-sm-6">
					<ul class="list-unstyled list-icons">
						<li>
							<i class="fa fa-check text-primary"></i> Env&iacute;o de SMS a bajos costos</li>
						<li>
							<i class="fa fa-check text-primary"></i> Alcance de m&aacute;s de 200 pa&iacute;ses</li>
						<li>
							<i class="fa fa-check text-primary"></i> Ofertas especiales</li>
						<li>
							<i class="fa fa-check text-primary"></i> Soporte t&eacute;cnico de excelencia</li>
					</ul>
				</div>
				<div class="col-12 col-sm-6">
					<ul class="list-unstyled list-icons">
						<li>
							<i class="fa fa-check text-primary"></i> Llamadas internacionales sin pin</li>
						<li>
							<i class="fa fa-check text-primary"></i> Recargas inmediatas en 50 pa&iacute;ses</li>
						<li>
							<i class="fa fa-check text-primary"></i> Confirmaci&oacute;n de recargas</li>
						<li>
							<i class="fa fa-check text-primary"></i> M&uacute;ltiples formas de pagos</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
