import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'ngx-localstorage';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../models/user.model';
import { ResultModel } from '../../models/result.model';
import { BalanceModel } from '../../models/balance.model';
import { Router } from '@angular/router';
import { UserFullModel } from '../../models/user-full.model';

@Injectable()
export class AuthUserService {
	baseUrl: string;
	redirectAfterLoginUrl: string;

	constructor(
		private httpClient: HttpClient,
		private localStorageService: LocalStorageService,
		private router: Router
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetAuthUser(): UserModel {
		const data = JSON.parse(this.localStorageService.get('mega_data'));

		if (data && data.user) {
			return (data.user as UserModel);
		}

		return null;
	}

	GetBalance(): Promise<ResultModel<BalanceModel>> {
		const user = this.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);

				return this.httpClient
					.get<ResultModel<BalanceModel>>(`${this.baseUrl}api/account/get_home_details`, { params: params })
					.toPromise();
			});
	}

	LogOut(noSecurityMessage?: boolean): void {
		this.localStorageService.remove('mega_data');
		this.localStorageService.remove('token');
		this.localStorageService.remove('redirectAfterLoginUrl');

		if (noSecurityMessage) {
			this.router.navigate(['/account/login']);
		} else {
			this.router.navigate(['/account/login'], { queryParams: { security: 1 } });
		}
	}

	GetUserData(): Promise<ResultModel<UserFullModel>> {
		const user = this.GetAuthUser();
		let params = new HttpParams();

		return Promise.resolve()
			.then(e => {
				params = params.append('serno', user.serno);

				return this.httpClient
					.get<ResultModel<UserFullModel>>(`${this.baseUrl}api/account/get_user`, { params: params })
					.toPromise();
			});
	}

}
