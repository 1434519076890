export class ProductDeliveryModel {
	id: string;
	code: string;
	name: string;
	price: string;
	type: string;
	accept_provinces: string;
	exclude_provinces: string;
	accept_municipes: string;
	exclude_municipes: string;
	duration: string;
	order: string;

	constructor() {
		this.price = '0';
	}
}
