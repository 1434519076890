import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './information/homepage/homepage.component';


const routes: Routes = [
	{
		path: '',
		component: HomepageComponent,
	},
	{
		path: 'account',
		loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
	}, {
		path: 'login',
		redirectTo: 'account/login'
	}, {
		path: 'register',
		redirectTo: 'account/register'
	}, {
		path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	}, {
		path: 'store',
		loadChildren: () => import('./store/store.module').then(m => m.StoreModule)
		//redirectTo: 'account'
	}	
   
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
