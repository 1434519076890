// tslint:disable:max-line-length
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mega-recargas-nauta',
	templateUrl: './recargas-nauta.component.html',
	styleUrls: ['./recargas-nauta.component.scss']
})
export class RecargasNautaComponent implements OnInit {
	provinces: any[] = [
		{ id: 'PR', text: 'Pinar del Río' },
		{ id: 'AR', text: 'Artemisa' },
		{ id: 'LH', text: 'La Habana' },
		{ id: 'MY', text: 'Mayabeque' },
		{ id: 'MZ', text: 'Matanzas' },
		{ id: 'CF', text: 'Cienfuegos' },
		{ id: 'VC', text: 'Villa Clara' },
		{ id: 'SS', text: 'Sancti Spíritus' },
		{ id: 'CA', text: 'Ciego de Ávila' },
		{ id: 'CM', text: 'Camagüey' },
		{ id: 'LT', text: 'Las Tunas' },
		{ id: 'GR', text: 'Granma' },
		{ id: 'HO', text: 'Holguín' },
		{ id: 'SC', text: 'Santiago de Cuba' },
		{ id: 'GT', text: 'Guantánamo' },
		{ id: 'IJ', text: 'Isla de la Juventud' }
	];
	municipios: any[] = [];

	selectedProvince = 'LH';
	selectedMunicipio = 'Todos';

	constructor() { }

	ngOnInit() {
		this.municipios['PR'] = ['Pinar del Río', 'Consolación del Sur', 'Guane', 'La Palma', 'Los Palacios', 'Mantua', 'Minas de Matahambre', 'San Juan y Martínez', 'San Luis', 'Sandino', 'Viñales'];
		this.municipios['AR'] = ['Artemisa', 'Alquízar', 'Bauta', 'Caimito', 'Guanajay', 'Güira de Melena', 'Mariel', 'San Antonio de los Baños', 'Bahía Honda', 'San Cristóbal', 'Candelaria'];
		this.municipios['LH'] = ['Arroyo Naranjo', 'Boyeros', 'Centro Habana', 'Cerro', 'Cotorro', 'Diez de Octubre', 'Guanabacoa', 'Habana del Este', 'Habana Vieja', 'La Lisa', 'Marianao', 'Playa', 'Plaza', 'Regla', 'San Miguel del Padrón'];
		this.municipios['MY'] = ['Güines', 'Batabanó', 'Bejucal', 'Jaruco', 'Madruga', 'Melena del Sur', 'Nueva Paz', 'Quivicán', 'San José de las Lajas', 'San Nicolás de Bari', 'Santa Cruz del Norte'];
		this.municipios['MZ'] = ['Calimete', 'Cárdenas', 'Ciénaga de Zapata', 'Colón', 'Jagüey Grande', 'Jovellanos', 'Limonar', 'Los Arabos', 'Martí', 'Matanzas', 'Pedro Betancourt', 'Perico', 'Unión de Reyes'];
		this.municipios['CF'] = ['Abreus', 'Aguada de Pasajeros', 'Cienfuegos', 'Cruces', 'Cumanayagua', 'Palmira', 'Rodas', 'Santa Isabel de las Lajas'];
		this.municipios['VC'] = ['Caibarién', 'Camajuaní', 'Cifuentes', 'Corralillo', 'Encrucijada', 'Manicaragua', 'Placetas', 'Quemado de Güines', 'Ranchuelo', 'Remedios', 'Sagua la Grande', 'Santa Clara', 'Santo Domingo'];
		this.municipios['SS'] = ['Cabaigúan', 'Fomento', 'Jatibonico', 'La Sierpe', 'Sancti Spíritus', 'Taguasco', 'Trinidad', 'Yaguajay'];
		this.municipios['CA'] = ['Ciro Redondo', 'Baraguá', 'Bolivia', 'Chambas', 'Ciego de Ávila', 'Florencia', 'Majagua', 'Morón', 'Primero de Enero', 'Venezuela'];
		this.municipios['CM'] = ['Camagüey', 'Carlos Manuel de Céspedes', 'Esmeralda', 'Florida', 'Guaimaro', 'Jimaguayú', 'Minas', 'Najasa', 'Nuevitas', 'Santa Cruz del Sur', 'Sibanicú', 'Sierra de Cubitas', 'Vertientes'];
		this.municipios['LT'] = ['Amancio Rodríguez', 'Colombia', 'Jesús Menéndez', 'Jobabo', 'Las Tunas', 'Majibacoa', 'Manatí', 'Puerto Padre'];
		this.municipios['GR'] = ['Bartolomé Masó', 'Bayamo', 'Buey Arriba', 'Campechuela', 'Cauto Cristo', 'Guisa', 'Jiguaní', 'Manzanillo', 'Media Luna', 'Niquero', 'Pilón', 'Río Cauto', 'Yara'];
		this.municipios['HO'] = ['Antilla', 'Báguanos', 'Banes', 'Cacocum', 'Calixto García', 'Cueto', 'Frank País', 'Gibara', 'Holguín', 'Mayarí', 'Moa', 'Rafael Freyre', 'Sagua de Tánamo', 'Urbano Noris'];
		this.municipios['SC'] = ['Contramaestre', 'Guamá', 'Julio Antonio Mella', 'Palma Soriano', 'San Luis', 'Santiago de Cuba', 'Segundo Frente', 'Songo la Maya', 'Tercer Frente'];
		this.municipios['GT'] = ['Baracoa', 'Caimanera', 'El Salvador', 'Guantánamo', 'Imías', 'Maisí', 'Manuel Tames', 'Niceto Pérez', 'San Antonio del Sur', 'Yateras'];
		this.municipios['IJ'] = ['Isla de la Juventud'];
	}

	getAllMunicipiosByProvince(): any[] {
		return this.municipios[this.selectedProvince];
	}

	onProvinciaChange(): void {
		this.selectedMunicipio = 'Todos';
	}

}
