import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { CreditCardService } from '../../services/credit-card.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CountryService } from '../../services/country.service';
import { CountryRegisterModel } from '../../../models/country-register.model';
import { AuthUserService } from '../../services/auth-user.service';
import { UserFullModel } from '../../../models/user-full.model';
import { find, split, trim, forEach } from 'lodash';
import { NewCreditCardModel } from '../../../models/new-credit-card.model';
import { CreditCardValidator } from '../../library/ngx-credit-cards/validators/CreditCard.validator';
import { CreditCardModel } from '../../../models/credit-card.model';
import { ResultModel } from '../../../models/result.model';
import { UpdateCreditCardModel } from '../../../models/update-credit-card.model';

@Component({
	selector: 'mega-create-credit-card',
	templateUrl: './create-credit-card.component.html',
	styleUrls: ['./create-credit-card.component.scss']
})
export class CreateCreditCardComponent implements OnInit, OnDestroy {
	@Input() openModal: Subject<boolean>;
	@Input() isEdit: boolean;
	@Input() editLast4: string;
	@Input() source: string;

	// tslint:disable-next-line:no-output-on-prefix
	@Output() onSave: EventEmitter<NewCreditCardModel> = new EventEmitter<NewCreditCardModel>();
	@Output() onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

	countries: CountryRegisterModel[] = [];
	selectedCountry: string;
	myForm: FormGroup;

	isVisible: boolean;
	saveCcAccount: boolean;

	userData: UserFullModel;
	isCompletedLoading: boolean = true;
	editModel: CreditCardModel = null;
	errorMessage: string;
	subscription: Subscription;

	constructor(
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
		private formBuilder: FormBuilder,
		private countryService: CountryService,
		private authUserService: AuthUserService,
		private creditCardService: CreditCardService
	) { }

	ngOnDestroy(): void {
		console.log('out');
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		this.saveCcAccount = false;

		this.subscription = this.openModal
			.subscribe(e => {
				this.ng4LoadingSpinnerService.show();
				this.countryService
					.GetCountryListRegister()
					.then(response => {
						if (response.has_error) {
							throw new Error();
						}

						this.countries = response.array;
						

						this.authUserService
						.GetUserData()
						.then(response => {
							if (response.has_error) {
								throw new Error();
							}

							this.userData = response.data;							
							this.selectedCountry = this.userData.COUNTRY;
							this.myForm.get('country').setValue(this.userData.COUNTRY); 
						})
						.catch(error => {							
						});
						
						

						if (this.isEdit) {
							return this.GetCreditCard();
						}

						const r = new ResultModel();
						r.has_error = false;
						r.data = new CreditCardModel();

						return Promise.resolve(r);
					})
					.then((result: ResultModel<CreditCardModel>) => {
						if (result.has_error) {
							throw new Error();
						}

						this.editModel = result.data;
						this.ng4LoadingSpinnerService.hide();
					})
					.catch(error => {
						this.countries = [];
						this.ng4LoadingSpinnerService.hide();
					})
					.then(() => {
						this.SetFormUp();
						this.isVisible = true;
						this.ng4LoadingSpinnerService.hide();
					});
			});

		if (this.isEdit) {
			this.openModal.next(true);
		}

		
						
	}

	GetCreditCard(): Promise<ResultModel<CreditCardModel>> {
		return this.creditCardService
			.GetCreditCardInfo(this.editLast4);
	}

	SetFormUp(): void {
		if (!this.editModel) {
			this.editModel = new CreditCardModel();
		}

		let expiry = '';
		if (this.editModel.EXP_DATE) {
			const first2 = this.editModel.EXP_DATE.substr(0, 2);
			const last2 = this.editModel.EXP_DATE.substr(2);
			expiry = `${first2}/${last2}`;
		}

		if (this.editModel.COUNTRY) {
			this.selectedCountry = this.editModel.COUNTRY;
		}

		this.myForm = this.formBuilder.group(
			{
				'name': [this.editModel.FIRST_NAME, Validators.compose([Validators.required])],
				'lastname': [this.editModel.LAST_NAME, Validators.compose([Validators.required])],
				'address': [this.editModel.ADDRESS1, Validators.compose([Validators.required])],
				'city': [this.editModel.CITY, Validators.compose([Validators.required])],
				'state': [this.editModel.STATE, Validators.compose([Validators.required])],
				'zip': [this.editModel.ZIP_CODE, Validators.compose([Validators.required])],
				'country': [this.editModel.COUNTRY, Validators.compose([Validators.required])],
				'cardNumber': [this.editModel.TITLE, Validators.compose([Validators.required, CreditCardValidator.validateCardNumber])],
				'expCard': [expiry, Validators.compose([Validators.required, CreditCardValidator.validateCardExpiry])],
				'cvc': ['', Validators.compose([Validators.required, CreditCardValidator.validateCardCvc])]
			});

		if (this.isEdit) {
			this.myForm.get('cardNumber').setValidators(null);
			this.myForm.updateValueAndValidity();
		} else {

		}
	}

	saveCardAccount(event): void {
		this.saveCcAccount = event.target.checked;
	}

	loadAccountInfo(event): void {
		if (!event.target.checked) { return; }
		if (this.userData) { return this.SetUserData(this.userData); }

		this.isCompletedLoading = false;
		this.authUserService
			.GetUserData()
			.then(response => {
				if (response.has_error) {
					throw new Error();
				}

				this.userData = response.data;
				this.isCompletedLoading = true;
				this.SetUserData(this.userData);
			})
			.catch(error => {
				// do nothing!
				this.isCompletedLoading = true;
			});
	}

	SubmitCardForm(form: FormGroupDirective): void {
	//	if (this.source === 'checkout') {
			
		if (form.invalid) {
			forEach(this.myForm.controls, (item: AbstractControl) => {
				item.markAsTouched();
				item.markAsDirty();
			});
			return;
		}

		const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

		if (this.selectedCountry.toLowerCase() === 'us' && !zipCodeRegex.test(form.value.zip)) {
			this.myForm.get('zip').setErrors({ 'zipCodeInvalid': true });
			return;
		}

		const parts = split(form.value.expCard, '/');

		if (!this.isEdit) {
			const card = new NewCreditCardModel();
			card.cbxCCProfile = '0';
			card.cbxCountry = this.selectedCountry;
			card.chkSaveCC = this.saveCcAccount;
			card.edtCCCCV = form.value.cvc;
			card.edtCCNumber = form.value.cardNumber;
			card.edtCustAddress = form.value.address;
			card.edtCustCity = form.value.city;
			card.edtCustFirstName = form.value.name;
			card.edtCustLastName = form.value.lastname;
			card.edtCustState = form.value.state;
			card.edtCustZip = form.value.zip;
			card.cbxCCMonth = trim(parts[0]);
			card.cbxCCYear = trim(parts[1]);
			card.cbxCCYear = card.cbxCCYear.length === 2 ? `20${card.cbxCCYear}` : card.cbxCCYear;

			this.myForm.get('name').setValue('');
			this.myForm.get('lastname').setValue('');
			this.myForm.get('address').setValue('');
			this.myForm.get('city').setValue('');
			this.myForm.get('state').setValue('');
			this.myForm.get('zip').setValue('');
			this.myForm.get('country').setValue(this.userData.COUNTRY);
			this.myForm.get('cardNumber').setValue('');
			this.myForm.get('expCard').setValue('');
			this.myForm.get('cvc').setValue('');
			this.myForm.markAsUntouched();
			this.myForm.markAsPristine();

			this.onSave.emit(card);
			this.isVisible = false;
			return;
		}


		// EDIT MODE
		this.errorMessage = '';
		const update = new UpdateCreditCardModel();
		update.ADDRESS = form.value.address;
		update.CITY = form.value.city;
		update.CVC = form.value.cvc;
		update.NUMBER = this.editLast4;
		update.STATE = form.value.state;
		update.ZIP = form.value.zip;
		update.MONTH = trim(parts[0]);
		update.YEAR = trim(parts[1]);

		this.ng4LoadingSpinnerService.show();
		this.creditCardService
			.UpdateCreditCardDetails(update)
			.then(response => {				
				if (response.has_error) {
					throw new Error();
				}

				this.Cancel(true);
				this.ng4LoadingSpinnerService.hide();
			})
			.catch(error => {								
				this.errorMessage = 'No se pudo modificar su tarjeta de crédito debido a un error en los datos. Por favor verifique su información e intente nuevamente.';
				this.ng4LoadingSpinnerService.hide();
			});
	}

	Cancel(refresh: boolean = false): void {
		this.isVisible = false;
		this.onCancel.emit(refresh);
	}

	private SetUserData(user: UserFullModel): void {
		// first and last name and country are only editable when creation
		if (!this.isEdit) {
			this.myForm.get('name').setValue(user.CUSTOMERNAME);
			this.myForm.get('lastname').setValue(user.CUSTOMER_LASTNAME);

			const exist = find(this.countries, o => {
				return o.countcod.toLowerCase() === user.COUNTRY.toLowerCase();
			});

			if (!!exist) {
				this.selectedCountry = user.COUNTRY;
				this.myForm.get('country').setValue(user.COUNTRY); 
			}
		}

		this.myForm.get('address').setValue(user.ADDRESS1);
		this.myForm.get('city').setValue(user.CITY);
		this.myForm.get('state').setValue(user.STATE);
		this.myForm.get('zip').setValue(user.ZIP);
		
	}
}
