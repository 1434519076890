<mega-carousel></mega-carousel>

<div class="mg-hp-cta-1">
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h1>Sencillo, R&aacute;pido y Seguro</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-12 col-md-4 mb-5 mb-sm-5 mb-md-0">
				<div class="mp-cta1-item">
					

					<img class="img-responsive mb-2" src="assets/images/homepage/step1.webp" alt="Paso 1">
					<h2>Paso 1</h2>
					<p>Crea tu cuenta en tan solo 2 sencillos pasos.</p>
				</div>
			</div>
			<div class="col-12 col-sm-12 col-md-4 mb-5 mb-sm-5 mb-md-0">
				<div class="mp-cta1-item">
					<img class="img-responsive mb-2" src="assets/images/homepage/step2.webp" alt="Paso 2">
					<h2>Paso 2</h2>
					<p>Agregue fondos a su cuenta para llamadas y SMS.</p>
				</div>
			</div>
			<div class="col-12 col-sm-12 col-md-4 mb-5 mb-sm-5 mb-md-0">
				<div class="mp-cta1-item">
					<img class="img-responsive mb-2" src="assets/images/homepage/step3.webp" alt="Paso 3">
					<h2>Paso 3</h2>
					<p>Listo! Ya puede comunicarse con sus contactos de todo el mundo.</p>
				</div>
			</div>
		</div>
		<div class="row mg-mt-cta">
			<div class="col-12 text-center">
				<h2>Descargue la aplicaci&oacute;n gratis</h2>
				<p class="mb-4">Llame, Recargue y Env&iacute;e SMS
					<b>gratis</b> a Cuba</p>
				<a href="https://itunes.apple.com/us/app/megaconecta/id1000791688?ls=1&mt=8" target="_blank">
					<img class="img-responsive mr-3" src="assets/images/homepage/app-store.webp" alt="App Store">
				</a>
				<a href="https://play.google.com/store/apps/details?id=com.embarcadero.MegaConecta&hl=es" target="_blank">
					<img class="img-responsive" src="assets/images/homepage/play-store.webp" alt="Google Play">
				</a>
			</div>
		</div>
	</div>
</div>

<div class="mg-hp-cta-2 d-block d-md-none">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="mg-cta2-item mobile">
					<div class="title d-table">
						<h2 class="align-bottom d-table-cell">
							Recargas
							<br>Cubacel
						</h2>
					</div>
					<div class="body">
						<div class="mg-line-btm"></div>
						Ahora recargando cubacel m&aacute;s sencillo, r&aacute;pido y barato... Desde el mundo para Cuba.
					</div>
					<div class="footer">
						<span routerLink="/rates">ver tarifas y precios</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mg-hp-cta-2 d-block d-md-none  mg-color-middle">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="mg-cta2-item mobile">
					<div class="title d-table">
						<h2 class="align-bottom d-table-cell">
							Mega
							<br class="d-none d-sm-none d-md-block d-lg-none">conecta
						</h2>
					</div>
					<div class="body">
						<div class="mg-line-btm"></div>
						No somos los únicos, pero somos los MEJORES.
					</div>
					<div class="footer dark">
						<span routerLink="/account/register">reg&iacute;strese ahora</span>
						<div class="mg-line-btm"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mg-hp-cta-2 d-block d-md-none">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="mg-cta2-item mobile">
					<div class="title d-table">
						<h2 class="align-bottom d-table-cell">
							Recargas
							<br>Nauta
						</h2>
					</div>
					<div class="body">
						<div class="mg-line-btm"></div>
						Ahora recargando cuentas Nauta, la forma mas barata de comunicarse con la familia.
					</div>
					<div class="footer">
						<span routerLink="/services">Ver otros servicios</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mg-hp-cta-2 d-none d-sm-none d-md-block">
	<div class="container">
		<div class="row">
			<div class="col-12 col-sm-12 col-md-4">
				<div class="mg-cta2-item">
					<div class="title d-table">
						<h2 class="align-bottom d-table-cell">
							Recargas
							<br>Cubacel
						</h2>
					</div>
					<div class="body">
						<div class="mg-line-btm"></div>
						Ahora recargando cubacel m&aacute;s sencillo, r&aacute;pido y barato... Desde el mundo para Cuba.
					</div>
					<div class="footer">
						<span routerLink="/rates">ver tarifas y precios</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-12 col-md-4 mg-color-middle">
				<div class="mg-cta2-item">
					<div class="title d-table">
						<h2 class="align-bottom d-table-cell">
							Mega
							<br class="d-none d-sm-none d-md-block d-lg-none">conecta
						</h2>
					</div>
					<div class="body">
						<div class="mg-line-btm"></div>
						No somos los únicos, pero somos los MEJORES.
					</div>
					<div class="footer dark">
						<span routerLink="/account/register">reg&iacute;strese ahora</span>
						<div class="mg-line-btm"></div>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-12 col-md-4">
				<div class="mg-cta2-item">
					<div class="title d-table">
						<h2 class="align-bottom d-table-cell">
							Recargas
							<br>Nauta
						</h2>
					</div>
					<div class="body">
						<div class="mg-line-btm"></div>
						Ahora recargando cuentas Nauta, la forma mas barata de comunicarse con la familia.
					</div>
					<div class="footer">
						<span routerLink="/services">Ver otros servicios</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mg-hp-cta-4">
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h3>Recarga el celular de amigos y
					<br class="d-none d-sm-none d-md-block"> familiares en cualquier parte
				</h3>
				<p>
					R&aacute;pido, sencillo y seguro. Contamos con m&aacute;s de
					<strong>200 operadores</strong> en todo el mundo.
				</p>

				<ngu-carousel [inputs]="carouselOne" [dataSource]="data">
					<ngu-tile *nguCarouselDef="let item; let j = index">
						<img [src]="item" alt="Megaconecta">
					</ngu-tile>
					<button NguCarouselPrev class='leftRs d-none'>&lt;</button>
					<button NguCarouselNext class='rightRs d-none'>&gt;</button>
				</ngu-carousel>
			</div>
		</div>
	</div>
</div>

<mega-social-media></mega-social-media>
