import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResultModel } from '../../models/result.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContactService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	SendEmail(name: string, email: string, message: string): Promise<ResultModel<any>> {
		let params = new HttpParams();
		params = params.append('name', name);
		params = params.append('email', email);
		params = params.append('msg', message);

		return this.httpClient
			.get<ResultModel<any>>(`${this.baseUrl}api/register/send_email_contact`, { params: params })
			.toPromise();
	}
}
