<div class="mg-pager-wrapper">
	<div class="row">
		<div class="col-lg-3 col-md-4 col-sm-12">
			<div class="input-group">
				<div class="input-group-prepend" (click)="FirstPage()">
					<div class="input-group-text left" id="btnGroupAddon1">
						<span class="fa fa-fast-backward"></span>
					</div>
				</div>
				<div class="input-group-prepend" (click)="PreviousPage()">
					<div class="input-group-text left" id="btnGroupAddon1">
						<span class="oi oi-caret-left"></span>
					</div>
				</div>
				<input type="text" class="form-control" [value]="GetDisplayValue()" readonly="readonly">
				<div class="input-group-append" (click)="NextPage()">
					<div class="input-group-text right" id="btnGroupAddon2">
						<span class="oi oi-caret-right"></span>
					</div>
				</div>
				<div class="input-group-append" (click)="LastPage()">
					<div class="input-group-text right" id="btnGroupAddon2">
						<span class="fa fa-fast-forward"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-1 col-md-2 d-sm-none d-none d-md-flex pr-0">
			<ng-select  [(ngModel)]="currentPageSize" [clearable]="false" placeholder="" (change)="ChangePageSize($event)">
				<ng-option *ngFor="let item of pageSizes" [value]="item" >
					{{item}}
				</ng-option>
			</ng-select>	

			


		</div>
		<div class="col-lg-2 col-md-3 d-sm-none d-none d-md-flex pl-0">
			<div class="mg-size-desc pl-2">
				registros por p&aacute;gina
			</div>
		</div>
		<div class="col-md-3 col-sm-12 pl-0 ml-auto text-center d-flex mt-md-0 mt-sm-2 mt-2">
			<div class="mg-size-desc">
				{{GetFinalTextValue()}}
			</div>
		</div>
	</div>

</div>
