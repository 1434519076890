import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ceil } from 'lodash';

@Component({
	selector: 'mega-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit, OnChanges {
	@Input() currentPageSize: number;
	@Input() currentPage: number;
	@Input() totalRecords: number;

	@Output() pageChangeEvent = new EventEmitter<string>();

	totalPages: number;
	pageSizes: any[] = [10, 20, 30, 50, 100];

	constructor() {

	}

	ngOnInit() {
		this.totalPages = ceil(this.totalRecords / this.currentPageSize);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.totalPages = ceil(this.totalRecords / this.currentPageSize);
	}

	GetDisplayValue(): string {
		return `${this.currentPage} / ${this.totalPages}`;
	}

	GetFinalTextValue(): string {
		let ini = Number(Number(this.currentPage) - 1) * Number(this.currentPageSize);
		let end = Number(ini) + Number(this.currentPageSize);

		if (ini === 0) { ini = 1; }
		if (end > this.totalRecords) { end = this.totalRecords; }

		return `${ini} - ${end} de ${this.totalRecords} registros`;
	}

	NextPage(): void {
		if (this.currentPage < this.totalPages) {
			this.currentPage++;
			this.pageChangeEvent.emit(`${this.currentPage}-${this.currentPageSize}`);
		}
	}

	PreviousPage(): void {
		if (this.currentPage > 1) {
			this.currentPage--;
			this.pageChangeEvent.emit(`${this.currentPage}-${this.currentPageSize}`);
		}
	}

	FirstPage(): void {
		if (this.currentPage > 1) {
			this.currentPage = 1;
			this.pageChangeEvent.emit(`${this.currentPage}-${this.currentPageSize}`);
		}
	}

	LastPage(): void {
		if (this.currentPage < this.totalPages) {
			this.currentPage = this.totalPages;
			this.pageChangeEvent.emit(`${this.currentPage}-${this.currentPageSize}`);
		}
	}

	ChangePageSize(pageSize: any): void {
		this.currentPageSize = Number(pageSize);
		this.currentPage = 1;
		this.totalPages = ceil(this.totalRecords / this.currentPageSize);
		this.pageChangeEvent.emit(`${this.currentPage}-${this.currentPageSize}`);
	}

}
