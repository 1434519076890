import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResultModel } from '../../models/result.model';
import { ContentModel } from '../../models/admin/content.model';

@Injectable()
export class DynamicContentService {
	baseUrl: string;

	constructor(
		private httpClient: HttpClient
	) {
		this.baseUrl = environment.apiBaseUrl;
	}

	GetContentByUrl(url: string): Promise<ResultModel<ContentModel>> {
		let params = new HttpParams();
		params = params.append('url', url);

		return this.httpClient
			.get<ResultModel<ContentModel>>(`${this.baseUrl}api/content/geturlcontent`, { params: params })
			.toPromise();
	}

}
