import { NewCreditCardModel } from './new-credit-card.model';

export class FundPaymentModel {
	IsCCSaved: boolean;
	cbxCCProfile: string;
	itempricecc: number;
	coupon: string;

	newCard: NewCreditCardModel;
}
