import { ProductFeatureModel } from './product-feature.model';
import { ProductDeliveryModel } from './product-delivery.model';

export class ProductCartModel {
    productId: string;
    productName: string;
    shoppingCartText: string;
    colorId: string;
    colorName: string;
    productDetails: ProductFeatureModel[];
    cost: number;
    price: number;
    productImage: string;
    in_stock: number;
    quantity: number;
	description: string;
    invalid_product: string;
	deliveries?: ProductDeliveryModel[];


}
