import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationRoutingModule } from './information-routing.module';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { HelpComponent } from './help/help.component';
import { ServicesComponent } from './services/services.component';
import { RatesComponent } from './rates/rates.component';
import { AccessNumberComponent } from './access-number/access-number.component';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RatesService } from './rates/rates.service';
import { SharedModule } from '../shared/shared.module';
import { SuiModule } from 'ng2-semantic-ui';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YoutubePlayerModule } from 'ngx-youtube-player';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactService } from './contact/contact.service';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { DynamicContentService } from './dynamic-content/dynamic-content.service';
import { NguCarouselModule } from '@ngu/carousel';
import { LlamadasCubaComponent } from './llamadas-cuba/llamadas-cuba.component';
import { RecargasNautaComponent } from './recargas-nauta/recargas-nauta.component';
import { RecargasCubacelComponent } from './recargas-cubacel/recargas-cubacel.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
	imports: [
		CommonModule,
		InformationRoutingModule,
		SharedModule,
		NgbModule,
		YoutubePlayerModule,
		Ng4LoadingSpinnerModule,
		FormsModule,
		ReactiveFormsModule,
		SuiModule,
		NguCarouselModule,
		NgSelectModule
	],
	declarations: [
		HomepageComponent,
		AboutUsComponent,
		ContactComponent,
		HelpComponent,
		ServicesComponent,
		RatesComponent,
		AccessNumberComponent,
		TermConditionsComponent,
		PrivacyComponent,
		DynamicContentComponent,
		LlamadasCubaComponent,
		RecargasNautaComponent,
		RecargasCubacelComponent
	],
	providers: [
		RatesService,
		ContactService,
		DynamicContentService
	]
})
export class InformationModule { }
