import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[routeTransform]'
})
export class RouteTransformDirective {
	constructor(
		private el: ElementRef,
		private router: Router
	) { }

	@HostListener('click', ['$event'])
	public onClick(event) {
		if (this.hasAttribute(event.target)) {
			let url = this.getRouterLinkValue(event.target);

			if (!url && !!event.target.parentNode) {
				url = this.getRouterLinkValue(event.target.parentNode);
			}

			this.router.navigate([url]);
			event.preventDefault();
		} else {
			return;
		}
	}

	private getRouterLinkValue(mode: any): string {
		const url = !!mode.getAttribute('routerlink') ?
			mode.getAttribute('routerlink') :
			!!mode.getAttribute('routerLink') ?
				mode.getAttribute('routerLink') :
				'';

		return url;
	}

	private hasAttribute(node: any): boolean {
		let has = node.hasAttribute('routerlink') || node.hasAttribute('routerLink');

		if (!has && !!node.parentNode) {
			has = node.parentNode.hasAttribute('routerlink') || node.parentNode.hasAttribute('routerLink');
		}

		return has;
	}
}
