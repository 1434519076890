import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
	selector: 'mega-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	myForm: FormGroup;

	constructor(
		private footerService: FooterService,
		private formBuilder: FormBuilder,
		private ng4LoadingSpinnerService: Ng4LoadingSpinnerService
	) { }

	ngOnInit() {
		this.myForm = this.formBuilder.group({
			'email': [null, Validators.compose([Validators.required, Validators.email])]
		});
	}

	submitForm(form: FormGroupDirective): void {
		if (form.invalid) { return; }

		const email = form.value.email;
		this.ng4LoadingSpinnerService.show();

		this.footerService
			.AddSubscriptionEmail(email)
			.then(response => {
				if (response.has_error) {
					throw new Error('error');
				}

				this.myForm.markAsPristine();
				this.myForm.markAsUntouched();
				this.myForm.controls.email.setValue('');
				this.ng4LoadingSpinnerService.hide();
			})
			.catch(error => {
				this.ng4LoadingSpinnerService.hide();
			});
	}

}
